import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { SubmitUserConsentMutation, SubmitUserConsentMutationVariables } from '../gqlTypes';
import { USER_CONSENT_INCORRECT_TAX_CALCULATION_QUERY } from '../queries/useUserConsentToIncorrectTaxCalculation';

export const SUBMIT_USER_CONSENT = gql`
  mutation SubmitUserConsent($input: SubmitUserConsentInput!) {
    submitUserConsent(input: $input) {
      message
      success
    }
  }
`;

export const useSubmitUserConsent = (
  options?: MutationHookOptions<SubmitUserConsentMutation, SubmitUserConsentMutationVariables>,
) => {
  return useMutation<SubmitUserConsentMutation, SubmitUserConsentMutationVariables>(
    SUBMIT_USER_CONSENT,
    {
      ...options,
      refetchQueries: [USER_CONSENT_INCORRECT_TAX_CALCULATION_QUERY],
    },
  );
};
