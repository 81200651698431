import { PnlPeriod } from './../gql/gqlTypes';

export enum TimePeriod {
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  THREE_MONTH = 'THREE_MONTH',
  ONE_YEAR = 'ONE_YEAR',
  ALL = 'ALL',
}

export const TimePeriods = Object.keys(TimePeriod).map((tp) => tp as TimePeriod);
// Exporting this as an array for correct order
export const PNLPeriods: Array<PnlPeriod> = [
  PnlPeriod.Day,
  PnlPeriod.Week,
  PnlPeriod.Month,
  PnlPeriod.ThreeMonth,
  PnlPeriod.Year,
  PnlPeriod.All,
];
