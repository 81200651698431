import { CryptoCurrency, TransactionsMiscTypes } from './gql/gqlTypes';

export const MIRAI_KYC_KEY = 'mirai-onboarding-kyc';
export const MIRAI_KYC_CURRENT_INDEX = 'mirai-onboarding-kyc-current-index';
export const RECAPTCHA_SITEKEY = '6LdAhl4qAAAAAP0-tZofLn9dhfnSm41OY9anNzVl';
export const PASSWORD_MIN_LENGTH = 14;
export const PASSWORD_MIN_LENGTH_VARIANT_B = 8;
export const MARKET_ORDER_SLIPPAGE = 0.1; // 10% slippage
export const MIN_MARKET_FIAT_TOTAL = 11;
export const MIN_CARD_DEPOSIT = 100;
export const MIN_CARD_DEPOSIT_BETA = 20;
export const MAX_ADYEN_DEPOSIT = 100000; // 100k NOK (TODO: Add dynamic fetching of the value from adyen-api)
export const TEMP_PAYMENT_FEE = 3.9 / 100; // TODO: Get this from backend
export const FIREBASE_FCM_TOPIC = 'firi_feature_toggles';
export const FIRI_CLIENT_VERSION = '16.0.0'; // Used for GQL-versioning and filtering of data.

export const allMiscTypes = [
  TransactionsMiscTypes.AffiliateBonus,
  TransactionsMiscTypes.AffiliateCommision,
  TransactionsMiscTypes.Bonus,
  TransactionsMiscTypes.ChristmasBonus,
  TransactionsMiscTypes.DepositFee,
  TransactionsMiscTypes.Donation,
  TransactionsMiscTypes.FeebackBonus,
  TransactionsMiscTypes.InternalTransfer,
  TransactionsMiscTypes.Prize,
  TransactionsMiscTypes.Refund,
  TransactionsMiscTypes.Reversed,
  TransactionsMiscTypes.Transfer,
  TransactionsMiscTypes.Voucher,
  TransactionsMiscTypes.WelcomeBonus,
  TransactionsMiscTypes.StakingReward,
  TransactionsMiscTypes.Stake,
  TransactionsMiscTypes.Unstake,
];

export const allMiscBonusTypes = [
  TransactionsMiscTypes.AffiliateBonus,
  TransactionsMiscTypes.AffiliateCommision,
  TransactionsMiscTypes.Bonus,
  TransactionsMiscTypes.ChristmasBonus,
  TransactionsMiscTypes.FeebackBonus,
  TransactionsMiscTypes.WelcomeBonus,
];

export const clientCountries = ['NO', 'DK', 'SE'] as const;
export type ClientCountry = typeof clientCountries[number];

export const SupportedLanguageList = ['no', 'en', 'da', 'sv'] as const;
export type SupportedLanguage = typeof SupportedLanguageList[number];

export const CryptoCurrencies = Object.values(CryptoCurrency) as CryptoCurrency[];

export const ACCEPTED_FILE_TYPES = [
  '.jpg',
  '.jpeg',
  '.png',
  '.csv',
  '.doc',
  '.docx',
  '.pdf',
  '.odt',
  '.ods',
  '.xls',
  '.xlsx',
];

export const ACCEPTED_UPLOAD_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const MAX_UPLOAD_FILESIZE = 30; // MB

export const DEV_GRAPHQL_API = 'http://localhost:3000/graphql';
export const LOCAL_GRAPHQL_API = 'http://localhost:3000/graphql';
export const TEST_GRAPHQL_API = 'https://graph.testfiri.com/graphql';
export const SANDBOX_GRAPHQL_API = 'https://graph.sandboxfiri.com/graphql';
export const PROD_GRAPHQL_API = 'https://graph.firi.com/graphql';
export const KYC_BASE_URL = 'https://kyc.firi.com/v2';
export const KYC_BASE_URL_SANDBOX = 'https://kyc.sandboxfiri.com/v2';
export const KYC_BASE_URL_TEST = 'https://kyc.testfiri.com/v2';
export const EXPORT_BASE_URL = 'https://export.firi.com';
export const SANDBOX_EXPORT_BASE_URL = 'https://export.sandboxfiri.com';
export const TEST_EXPORT_BASE_URL = 'https://export.testfiri.com';
export const TAX_REPORT_BASE_URL = 'https://taxreport.firi.com';
export const SANDBOX_TAX_REPORT_BASE_URL = 'https://taxreport.sandboxfiri.com';
export const TEST_TAX_REPORT_BASE_URL = 'https://taxreport.testfiri.com';
export const TEST_NOTABENE_API_URL = 'https://api.notabene.dev';
export const PROD_NOTABENE_API_URL = 'https://api.notabene.id';
/**
 * TODO get from backend
 */
export const SAVING_AGREEMENT_MIN_AMOUNT = 100;
export const SAVING_AGREEMENT_DETAIL_MIN_AMOUNT = 10;

export const SUPPORT_EMAIL = 'support@firi.com';
export const LANDING_URL = 'https://firi.com';
export const PLATFORM_URL = 'https://platform.firi.com';
export const APP_LINK_URL = 'https://app-link.firi.com';
export const DEVELOPER_URL = 'https://developers.firi.com/';

/**
 * @deprecated use soMeUrls instead
 * */
export const SOME_URLS = {
  Facebook: 'https://www.facebook.com/firiapp/',
  Linkedin: 'https://www.linkedin.com/company/firias',
  Twitter: 'https://twitter.com/firiapp',
  Instagram: 'https://www.instagram.com/firiapp/',
};

export const soMeUrls: Record<
  'no' | 'dk' | 'en' | 'sv',
  { Facebook: string; Linkedin: string; Twitter: string; Instagram: string }
> = {
  no: {
    Facebook: 'https://www.facebook.com/firiapp/',
    Linkedin: 'https://www.linkedin.com/company/firias',
    Twitter: 'https://twitter.com/firiapp',
    Instagram: 'https://www.instagram.com/firiapp/',
  },
  dk: {
    Facebook: 'https://www.facebook.com/FiriDanmark ',
    Linkedin: 'https://www.linkedin.com/company/firias',
    Twitter: 'https://twitter.com/firi_dk',
    Instagram: 'https://www.instagram.com/firi.dk',
  },
  sv: {
    // TODO update with correct links for swedish some
    Facebook: 'https://www.facebook.com/firiapp/',
    Linkedin: 'https://www.linkedin.com/company/firias',
    Twitter: 'https://twitter.com/firiapp',
    Instagram: 'https://www.instagram.com/firiapp/',
  },
  en: {
    Facebook: 'https://www.facebook.com/firiapp/',
    Linkedin: 'https://www.linkedin.com/company/firias',
    Twitter: 'https://twitter.com/firiapp',
    Instagram: 'https://www.instagram.com/firiapp/',
  },
};

export const appDownloadLink = 'https://firi.com/app-link';
export const AFFILIATE_BASE_URL = 'https://firi.com/affiliate?referral=';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

// NEW MARKET CONSTANTS
export const newCryptocurrencySymbol = CryptoCurrency.Uni;
export const newCryptoCardId = 'firi_new_token_' + newCryptocurrencySymbol;
export const newMainAccountNewMarketId = 'firi_main_account_introduce_' + newCryptocurrencySymbol;

export const SUPPORTED_PRIVATE_WALLET_NAME_LIST = [
  'MetaMask',
  'Trust Wallet',
  'Exodus Wallet',
  'Atomic Wallet',
  'Coinomi',
  'Guarda Wallet',
  'SafePal Wallet',
  'MyEtherWallet (MEW)',
  'Edge Wallet',
  'Ledger Nano S',
  'Ledger Nano X',
  'Trezor Model T',
  'Trezor Model One',
  'Electrum Wallet',
  'Rainbow Wallet',
  'Argent Wallet',
  'Phantom Wallet',
  'Solflare Wallet',
  'Keplr Wallet',
  'Zengo Wallet',
  'Polkadot.js Wallet',
  'Binance Chain Wallet',
  'Yoroi Wallet',
  'Daedalus Wallet',
  'Tangem Wallet',
  'Terra Station Wallet',
];
