export { default as ChristmasSanta } from './ChristmasSanta';
export { default as Clock } from './Clock';
export { default as CoinFlip } from './CoinFlip';
export { default as CoinsFiri } from './CoinsFiri';
export { default as Coins } from './Coins';
export { default as Crypto101 } from './Crypto101';
export { default as DrawProfile } from './DrawProfile';
export { default as Flask } from './Flask';
export { default as Hammer } from './Hammer';
export { default as HandKey } from './HandKey';
export { default as Heart } from './Heart';
export { default as HelloFiri } from './HelloFiri';
export { default as Hello } from './Hello';
export { default as MagnifierGlass } from './MagnifierGlass';
export { default as Mastercard } from './Mastercard';
export { default as MitId } from './MitId';
export { default as MoreRewards } from './MoreRewards';
export { default as Newspaper } from './Newspaper';
export { default as ParachuteBtc } from './ParachuteBtc';
export { default as ParachuteClouds } from './ParachuteClouds';
export { default as Parachute } from './Parachute';
export { default as Pnl } from './Pnl';
export { default as RibbonLightskin } from './RibbonLightskin';
export { default as Ribbon } from './Ribbon';
export { default as ScreenThumbsDown } from './ScreenThumbsDown';
export { default as ScreenThumbsUp } from './ScreenThumbsUp';
export { default as ThumbsUpCoins } from './ThumbsUpCoins';
export { default as Tree } from './Tree';
export { default as Tumbleweed } from './Tumbleweed';
export { default as VippsLogo } from './VippsLogo';
export { default as VisaLogo } from './VisaLogo';
export type IllustrationName =
  | 'christmas-santa'
  | 'clock'
  | 'coin-flip'
  | 'coins-firi'
  | 'coins'
  | 'crypto101'
  | 'draw-profile'
  | 'flask'
  | 'hammer'
  | 'hand-key'
  | 'heart'
  | 'hello-firi'
  | 'hello'
  | 'magnifier-glass'
  | 'mastercard'
  | 'mit-id'
  | 'more-rewards'
  | 'newspaper'
  | 'parachute-btc'
  | 'parachute-clouds'
  | 'parachute'
  | 'pnl'
  | 'ribbon-lightskin'
  | 'ribbon'
  | 'screen-thumbs-down'
  | 'screen-thumbs-up'
  | 'thumbs-up-coins'
  | 'tree'
  | 'tumbleweed'
  | 'vipps-logo'
  | 'visa-logo';
import {
  ChristmasSanta,
  Clock,
  CoinFlip,
  Coins,
  CoinsFiri,
  Crypto101,
  DrawProfile,
  Flask,
  Hammer,
  HandKey,
  Heart,
  Hello,
  HelloFiri,
  MagnifierGlass,
  Mastercard,
  MitId,
  MoreRewards,
  Newspaper,
  Parachute,
  ParachuteBtc,
  ParachuteClouds,
  Pnl,
  Ribbon,
  RibbonLightskin,
  ScreenThumbsDown,
  ScreenThumbsUp,
  ThumbsUpCoins,
  Tree,
  Tumbleweed,
  VippsLogo,
  VisaLogo,
} from './';

export type IllustrationNames = IllustrationName;
export const illustrationsMap: Record<IllustrationNames, any> = {
  ['christmas-santa']: ChristmasSanta,
  ['clock']: Clock,
  ['coin-flip']: CoinFlip,
  ['coins-firi']: CoinsFiri,
  ['coins']: Coins,
  ['crypto101']: Crypto101,
  ['draw-profile']: DrawProfile,
  ['flask']: Flask,
  ['hammer']: Hammer,
  ['hand-key']: HandKey,
  ['heart']: Heart,
  ['hello-firi']: HelloFiri,
  ['hello']: Hello,
  ['magnifier-glass']: MagnifierGlass,
  ['mastercard']: Mastercard,
  ['mit-id']: MitId,
  ['more-rewards']: MoreRewards,
  ['newspaper']: Newspaper,
  ['parachute-btc']: ParachuteBtc,
  ['parachute-clouds']: ParachuteClouds,
  ['parachute']: Parachute,
  ['pnl']: Pnl,
  ['ribbon-lightskin']: RibbonLightskin,
  ['ribbon']: Ribbon,
  ['screen-thumbs-down']: ScreenThumbsDown,
  ['screen-thumbs-up']: ScreenThumbsUp,
  ['thumbs-up-coins']: ThumbsUpCoins,
  ['tree']: Tree,
  ['tumbleweed']: Tumbleweed,
  ['vipps-logo']: VippsLogo,
  ['visa-logo']: VisaLogo,
};
