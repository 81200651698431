import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { SubmitInAppRatingMutationVariables } from '../gqlTypes';
import { SubmitInAppRatingMutation } from '../gqlTypes';

const IN_APP_RATING_MUTATION = gql`
  mutation SubmitInAppRating($input: SubmitInAppRatingInput!) {
    submitInAppRating(input: $input) {
      success
      message
    }
  }
`;

export const useSubmitInAppRating = (
  options?: MutationHookOptions<SubmitInAppRatingMutation, SubmitInAppRatingMutationVariables>,
) =>
  useMutation<SubmitInAppRatingMutation, SubmitInAppRatingMutationVariables>(
    IN_APP_RATING_MUTATION,
    options,
  );
