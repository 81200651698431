/**
 * This file is generated by the index-template.js script. Do not edit manually.
 */
import type { IconName, CurrenciesIconName, BanksIconName, IconNames } from '../types/icons';
import {
  Svg2Fa,
  Svg2FaDisabled,
  Activity,
  AdvancedTrade,
  AdvancedTradeFilled,
  Adyen,
  Affiliate,
  AffiliateCommission,
  Age18,
  Age50,
  Alarm,
  AlertFilled,
  Alphabetical,
  ApplePay,
  ArrowDown,
  ArrowUp,
  ArrowRight,
  BackArrow,
  Bank,
  BankId,
  BankIdDisabled,
  Bell,
  BellFilled,
  BellNotification,
  Blog,
  BlogFilled,
  Bonus,
  BonusFilled,
  Boost,
  BoostFilled,
  Building,
  Buy,
  Csv,
  Calendar,
  CallAwait,
  Camera,
  Card,
  Chainalysis,
  Chat,
  CheckCircle,
  CheckCircleFilled,
  CheckMark,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Clock,
  Close,
  CloseCircleFilled,
  Cloud,
  ComputerCheck,
  Copy,
  Corporate,
  Crown,
  CustomerAlarm,
  Dkk,
  DarkMode,
  Dashboard,
  Deposit1,
  Deposit2,
  Document,
  Donations,
  DoubleCheck,
  Down,
  EasterEgg,
  Edit,
  Elliptic,
  Email,
  EmailAwait,
  EmailReceived,
  EmailSent,
  Emoji,
  ExternalLink,
  Eye,
  EyeSlash,
  Female,
  Filter,
  FiriLogo,
  FormReceived,
  FormSent,
  Fraud,
  GenericCoin,
  Globe,
  GooglePay,
  Graph,
  GraphFilledRound,
  HalfStar,
  HamburgerMenu,
  Happy,
  Help,
  Info,
  InfoFilled,
  Interest,
  InternalTransfer,
  Investigation,
  LabelTag,
  Language,
  Loading,
  Lock,
  LockFilled,
  Locked,
  LogOut,
  Male,
  ManaSand,
  Market,
  MarketFilled,
  MitId,
  MobilePay,
  Moon,
  More,
  MoreFilled,
  MoreVertical,
  MultiIdentity,
  Nok,
  NoCredit,
  NoCreditFilled,
  NoTrading,
  NoTradingFilled,
  NoWithdraw,
  NoWithdrawFilled,
  Observation,
  Overview,
  OverviewFilled,
  Pdf,
  Pending,
  PendingSuspension,
  Phone,
  PhoneCall,
  PieChart,
  PieChartFilled,
  Present,
  PresentIcon,
  Profile,
  Progress,
  Qr,
  QuestionMarkCircle,
  ReinforcedKyc,
  Rejected,
  Reset,
  RightArrow,
  Sek,
  Savings,
  SavingsVault,
  SeBankId,
  Search,
  Sell,
  Settings,
  SettingsFilled,
  ShareIcon,
  Shield,
  SmileyHappy,
  SmileyHappyFilled,
  SmileyNeutral,
  SmileyNeutralFilled,
  SmileySad,
  SmileySadFilled,
  Sort,
  SortAvg,
  SortHighToLow,
  SortLowToHigh,
  SortMax,
  SortMin,
  Star,
  Steps,
  StepsFilled,
  Suspended,
  SuspendedFilled,
  Swap,
  Task,
  Tax,
  ThumbsUp,
  ThumbsUpFilled,
  Ticket,
  Tips,
  Trade,
  TradeFilled,
  Transactions,
  Trophy,
  TrophyFilled,
  Usd,
  UnderInvestigation,
  Unlock,
  Up,
  Update,
  User,
  UserId,
  Vipps,
  VippsDisabled,
  VippsLabel,
  VippsWhite,
  Wallet,
  WalletFilled,
  Warning,
  Withdraw,
  XmasTreeFilled,
  XmasTree,
  FacebookRound,
  InstagramRound,
  LinkedinRound,
  SnapchatRound,
  TiktokRound,
  Tiktok,
  TwitterRound,
  YoutubeRound,
} from './';
import {
  Danskebank,
  Dnb,
  Handelsbanken,
  NordeaDark,
  Nordea,
  Santander,
  SbankenDark,
  Sbanken,
  Sparebank1Dark,
  Sparebank1NordnorgeDark,
  Sparebank1Nordnorge,
  Sparebank1SrbankenDark,
  Sparebank1Srbanken,
  Sparebank1,
} from './banks';
import {
  Aave,
  Ada,
  Avax,
  Bnb,
  Btc,
  Dai,
  Doge,
  Dot,
  Eth,
  Link,
  Ltc,
  Mana,
  MaticDark,
  Matic,
  PolDark,
  Pol,
  Sand,
  SolDark,
  Sol,
  Uni,
  Usdc,
  Usdt,
  XlmDark,
  Xlm,
  XrpDark,
  Xrp,
} from './currencies';
export type { IconName, CurrenciesIconName, BanksIconName, IconNames };
export const iconMap: Record<IconNames, any> = {
  ['danskebank']: Danskebank,
  ['dnb']: Dnb,
  ['handelsbanken']: Handelsbanken,
  ['nordea-dark']: NordeaDark,
  ['nordea']: Nordea,
  ['santander']: Santander,
  ['sbanken-dark']: SbankenDark,
  ['sbanken']: Sbanken,
  ['sparebank1dark']: Sparebank1Dark,
  ['sparebank1nordnorge-dark']: Sparebank1NordnorgeDark,
  ['sparebank1nordnorge']: Sparebank1Nordnorge,
  ['sparebank1srbanken-dark']: Sparebank1SrbankenDark,
  ['sparebank1srbanken']: Sparebank1Srbanken,
  ['sparebank1']: Sparebank1,
  ['aave']: Aave,
  ['ada']: Ada,
  ['avax']: Avax,
  ['bnb']: Bnb,
  ['btc']: Btc,
  ['dai']: Dai,
  ['doge']: Doge,
  ['dot']: Dot,
  ['eth']: Eth,
  ['link']: Link,
  ['ltc']: Ltc,
  ['mana']: Mana,
  ['matic-dark']: MaticDark,
  ['matic']: Matic,
  ['pol-dark']: PolDark,
  ['pol']: Pol,
  ['sand']: Sand,
  ['sol-dark']: SolDark,
  ['sol']: Sol,
  ['uni']: Uni,
  ['usdc']: Usdc,
  ['usdt']: Usdt,
  ['xlm-dark']: XlmDark,
  ['xlm']: Xlm,
  ['xrp-dark']: XrpDark,
  ['xrp']: Xrp,
  ['2fa']: Svg2Fa,
  ['2fa-disabled']: Svg2FaDisabled,
  ['activity']: Activity,
  ['advanced-trade']: AdvancedTrade,
  ['advanced-trade-filled']: AdvancedTradeFilled,
  ['adyen']: Adyen,
  ['affiliate']: Affiliate,
  ['affiliate-commission']: AffiliateCommission,
  ['age18']: Age18,
  ['age50']: Age50,
  ['alarm']: Alarm,
  ['alert-filled']: AlertFilled,
  ['alphabetical']: Alphabetical,
  ['apple-pay']: ApplePay,
  ['arrow-down']: ArrowDown,
  ['arrow-up']: ArrowUp,
  ['arrow-right']: ArrowRight,
  ['back-arrow']: BackArrow,
  ['bank']: Bank,
  ['bank-id']: BankId,
  ['bank-id-disabled']: BankIdDisabled,
  ['bell']: Bell,
  ['bell-filled']: BellFilled,
  ['bell-notification']: BellNotification,
  ['blog']: Blog,
  ['blog-filled']: BlogFilled,
  ['bonus']: Bonus,
  ['bonus-filled']: BonusFilled,
  ['boost']: Boost,
  ['boost-filled']: BoostFilled,
  ['building']: Building,
  ['buy']: Buy,
  ['csv']: Csv,
  ['calendar']: Calendar,
  ['call-await']: CallAwait,
  ['camera']: Camera,
  ['card']: Card,
  ['chainalysis']: Chainalysis,
  ['chat']: Chat,
  ['check-circle']: CheckCircle,
  ['check-circle-filled']: CheckCircleFilled,
  ['check-mark']: CheckMark,
  ['chevron-down']: ChevronDown,
  ['chevron-left']: ChevronLeft,
  ['chevron-right']: ChevronRight,
  ['chevron-up']: ChevronUp,
  ['clock']: Clock,
  ['close']: Close,
  ['close-circle-filled']: CloseCircleFilled,
  ['cloud']: Cloud,
  ['computer-check']: ComputerCheck,
  ['copy']: Copy,
  ['corporate']: Corporate,
  ['crown']: Crown,
  ['customer-alarm']: CustomerAlarm,
  ['dkk']: Dkk,
  ['dark-mode']: DarkMode,
  ['dashboard']: Dashboard,
  ['deposit1']: Deposit1,
  ['deposit2']: Deposit2,
  ['document']: Document,
  ['donations']: Donations,
  ['double-check']: DoubleCheck,
  ['down']: Down,
  ['easter-egg']: EasterEgg,
  ['edit']: Edit,
  ['elliptic']: Elliptic,
  ['email']: Email,
  ['email-await']: EmailAwait,
  ['email-received']: EmailReceived,
  ['email-sent']: EmailSent,
  ['emoji']: Emoji,
  ['external-link']: ExternalLink,
  ['eye']: Eye,
  ['eye-slash']: EyeSlash,
  ['female']: Female,
  ['filter']: Filter,
  ['firi-logo']: FiriLogo,
  ['form-received']: FormReceived,
  ['form-sent']: FormSent,
  ['fraud']: Fraud,
  ['generic-coin']: GenericCoin,
  ['globe']: Globe,
  ['google-pay']: GooglePay,
  ['graph']: Graph,
  ['graph-filled-round']: GraphFilledRound,
  ['half-star']: HalfStar,
  ['hamburger-menu']: HamburgerMenu,
  ['happy']: Happy,
  ['help']: Help,
  ['info']: Info,
  ['info-filled']: InfoFilled,
  ['interest']: Interest,
  ['internal-transfer']: InternalTransfer,
  ['investigation']: Investigation,
  ['label-tag']: LabelTag,
  ['language']: Language,
  ['loading']: Loading,
  ['lock']: Lock,
  ['lock-filled']: LockFilled,
  ['locked']: Locked,
  ['log-out']: LogOut,
  ['male']: Male,
  ['mana-sand']: ManaSand,
  ['market']: Market,
  ['market-filled']: MarketFilled,
  ['mit-id']: MitId,
  ['mobile-pay']: MobilePay,
  ['moon']: Moon,
  ['more']: More,
  ['more-filled']: MoreFilled,
  ['more-vertical']: MoreVertical,
  ['multi-identity']: MultiIdentity,
  ['nok']: Nok,
  ['no-credit']: NoCredit,
  ['no-credit-filled']: NoCreditFilled,
  ['no-trading']: NoTrading,
  ['no-trading-filled']: NoTradingFilled,
  ['no-withdraw']: NoWithdraw,
  ['no-withdraw-filled']: NoWithdrawFilled,
  ['observation']: Observation,
  ['overview']: Overview,
  ['overview-filled']: OverviewFilled,
  ['pdf']: Pdf,
  ['pending']: Pending,
  ['pending-suspension']: PendingSuspension,
  ['phone']: Phone,
  ['phone-call']: PhoneCall,
  ['pie-chart']: PieChart,
  ['pie-chart-filled']: PieChartFilled,
  ['present']: Present,
  ['present-icon']: PresentIcon,
  ['profile']: Profile,
  ['progress']: Progress,
  ['qr']: Qr,
  ['question-mark-circle']: QuestionMarkCircle,
  ['reinforced-kyc']: ReinforcedKyc,
  ['rejected']: Rejected,
  ['reset']: Reset,
  ['right-arrow']: RightArrow,
  ['sek']: Sek,
  ['savings']: Savings,
  ['savings-vault']: SavingsVault,
  ['se-bank-id']: SeBankId,
  ['search']: Search,
  ['sell']: Sell,
  ['settings']: Settings,
  ['settings-filled']: SettingsFilled,
  ['share-icon']: ShareIcon,
  ['shield']: Shield,
  ['smiley-happy']: SmileyHappy,
  ['smiley-happy-filled']: SmileyHappyFilled,
  ['smiley-neutral']: SmileyNeutral,
  ['smiley-neutral-filled']: SmileyNeutralFilled,
  ['smiley-sad']: SmileySad,
  ['smiley-sad-filled']: SmileySadFilled,
  ['sort']: Sort,
  ['sort-avg']: SortAvg,
  ['sort-high-to-low']: SortHighToLow,
  ['sort-low-to-high']: SortLowToHigh,
  ['sort-max']: SortMax,
  ['sort-min']: SortMin,
  ['star']: Star,
  ['steps']: Steps,
  ['steps-filled']: StepsFilled,
  ['suspended']: Suspended,
  ['suspended-filled']: SuspendedFilled,
  ['swap']: Swap,
  ['task']: Task,
  ['tax']: Tax,
  ['thumbs-up']: ThumbsUp,
  ['thumbs-up-filled']: ThumbsUpFilled,
  ['ticket']: Ticket,
  ['tips']: Tips,
  ['trade']: Trade,
  ['trade-filled']: TradeFilled,
  ['transactions']: Transactions,
  ['trophy']: Trophy,
  ['trophy-filled']: TrophyFilled,
  ['usd']: Usd,
  ['under-investigation']: UnderInvestigation,
  ['unlock']: Unlock,
  ['up']: Up,
  ['update']: Update,
  ['user']: User,
  ['user-id']: UserId,
  ['vipps']: Vipps,
  ['vipps-disabled']: VippsDisabled,
  ['vipps-label']: VippsLabel,
  ['vipps-white']: VippsWhite,
  ['wallet']: Wallet,
  ['wallet-filled']: WalletFilled,
  ['warning']: Warning,
  ['withdraw']: Withdraw,
  ['xmas-tree-filled']: XmasTreeFilled,
  ['xmas-tree']: XmasTree,
  ['facebook-round']: FacebookRound,
  ['instagram-round']: InstagramRound,
  ['linkedin-round']: LinkedinRound,
  ['snapchat-round']: SnapchatRound,
  ['tiktok-round']: TiktokRound,
  ['tiktok']: Tiktok,
  ['twitter-round']: TwitterRound,
  ['youtube-round']: YoutubeRound,
};
export { default as Svg2Fa } from './2Fa';
export { default as Svg2FaDisabled } from './2FaDisabled';
export { default as Activity } from './Activity';
export { default as AdvancedTrade } from './AdvancedTrade';
export { default as AdvancedTradeFilled } from './AdvancedTradeFilled';
export { default as Adyen } from './Adyen';
export { default as Affiliate } from './Affiliate';
export { default as AffiliateCommission } from './AffiliateCommission';
export { default as Age18 } from './Age18';
export { default as Age50 } from './Age50';
export { default as Alarm } from './Alarm';
export { default as AlertFilled } from './AlertFilled';
export { default as Alphabetical } from './Alphabetical';
export { default as ApplePay } from './ApplePay';
export { default as ArrowDown } from './ArrowDown';
export { default as ArrowUp } from './ArrowUp';
export { default as ArrowRight } from './ArrowRight';
export { default as BackArrow } from './BackArrow';
export { default as Bank } from './Bank';
export { default as BankId } from './BankId';
export { default as BankIdDisabled } from './BankIdDisabled';
export { default as Bell } from './Bell';
export { default as BellFilled } from './BellFilled';
export { default as BellNotification } from './BellNotification';
export { default as Blog } from './Blog';
export { default as BlogFilled } from './BlogFilled';
export { default as Bonus } from './Bonus';
export { default as BonusFilled } from './BonusFilled';
export { default as Boost } from './Boost';
export { default as BoostFilled } from './BoostFilled';
export { default as Building } from './Building';
export { default as Buy } from './Buy';
export { default as Csv } from './Csv';
export { default as Calendar } from './Calendar';
export { default as CallAwait } from './CallAwait';
export { default as Camera } from './Camera';
export { default as Card } from './Card';
export { default as Chainalysis } from './Chainalysis';
export { default as Chat } from './Chat';
export { default as CheckCircle } from './CheckCircle';
export { default as CheckCircleFilled } from './CheckCircleFilled';
export { default as CheckMark } from './CheckMark';
export { default as ChevronDown } from './ChevronDown';
export { default as ChevronLeft } from './ChevronLeft';
export { default as ChevronRight } from './ChevronRight';
export { default as ChevronUp } from './ChevronUp';
export { default as Clock } from './Clock';
export { default as Close } from './Close';
export { default as CloseCircleFilled } from './CloseCircleFilled';
export { default as Cloud } from './Cloud';
export { default as ComputerCheck } from './ComputerCheck';
export { default as Copy } from './Copy';
export { default as Corporate } from './Corporate';
export { default as Crown } from './Crown';
export { default as CustomerAlarm } from './CustomerAlarm';
export { default as Dkk } from './Dkk';
export { default as DarkMode } from './DarkMode';
export { default as Dashboard } from './Dashboard';
export { default as Deposit1 } from './Deposit1';
export { default as Deposit2 } from './Deposit2';
export { default as Document } from './Document';
export { default as Donations } from './Donations';
export { default as DoubleCheck } from './DoubleCheck';
export { default as Down } from './Down';
export { default as EasterEgg } from './EasterEgg';
export { default as Edit } from './Edit';
export { default as Elliptic } from './Elliptic';
export { default as Email } from './Email';
export { default as EmailAwait } from './EmailAwait';
export { default as EmailReceived } from './EmailReceived';
export { default as EmailSent } from './EmailSent';
export { default as Emoji } from './Emoji';
export { default as ExternalLink } from './ExternalLink';
export { default as Eye } from './Eye';
export { default as EyeSlash } from './EyeSlash';
export { default as Female } from './Female';
export { default as Filter } from './Filter';
export { default as FiriLogo } from './FiriLogo';
export { default as FormReceived } from './FormReceived';
export { default as FormSent } from './FormSent';
export { default as Fraud } from './Fraud';
export { default as GenericCoin } from './GenericCoin';
export { default as Globe } from './Globe';
export { default as GooglePay } from './GooglePay';
export { default as Graph } from './Graph';
export { default as GraphFilledRound } from './GraphFilledRound';
export { default as HalfStar } from './HalfStar';
export { default as HamburgerMenu } from './HamburgerMenu';
export { default as Happy } from './Happy';
export { default as Help } from './Help';
export { default as Info } from './Info';
export { default as InfoFilled } from './InfoFilled';
export { default as Interest } from './Interest';
export { default as InternalTransfer } from './InternalTransfer';
export { default as Investigation } from './Investigation';
export { default as LabelTag } from './LabelTag';
export { default as Language } from './Language';
export { default as Loading } from './Loading';
export { default as Lock } from './Lock';
export { default as LockFilled } from './LockFilled';
export { default as Locked } from './Locked';
export { default as LogOut } from './LogOut';
export { default as Male } from './Male';
export { default as ManaSand } from './ManaSand';
export { default as Market } from './Market';
export { default as MarketFilled } from './MarketFilled';
export { default as MitId } from './MitId';
export { default as MobilePay } from './MobilePay';
export { default as Moon } from './Moon';
export { default as More } from './More';
export { default as MoreFilled } from './MoreFilled';
export { default as MoreVertical } from './MoreVertical';
export { default as MultiIdentity } from './MultiIdentity';
export { default as Nok } from './Nok';
export { default as NoCredit } from './NoCredit';
export { default as NoCreditFilled } from './NoCreditFilled';
export { default as NoTrading } from './NoTrading';
export { default as NoTradingFilled } from './NoTradingFilled';
export { default as NoWithdraw } from './NoWithdraw';
export { default as NoWithdrawFilled } from './NoWithdrawFilled';
export { default as Observation } from './Observation';
export { default as Overview } from './Overview';
export { default as OverviewFilled } from './OverviewFilled';
export { default as Pdf } from './Pdf';
export { default as Pending } from './Pending';
export { default as PendingSuspension } from './PendingSuspension';
export { default as Phone } from './Phone';
export { default as PhoneCall } from './PhoneCall';
export { default as PieChart } from './PieChart';
export { default as PieChartFilled } from './PieChartFilled';
export { default as Present } from './Present';
export { default as PresentIcon } from './PresentIcon';
export { default as Profile } from './Profile';
export { default as Progress } from './Progress';
export { default as Qr } from './Qr';
export { default as QuestionMarkCircle } from './QuestionMarkCircle';
export { default as ReinforcedKyc } from './ReinforcedKyc';
export { default as Rejected } from './Rejected';
export { default as Reset } from './Reset';
export { default as RightArrow } from './RightArrow';
export { default as Sek } from './Sek';
export { default as Savings } from './Savings';
export { default as SavingsVault } from './SavingsVault';
export { default as SeBankId } from './SeBankId';
export { default as Search } from './Search';
export { default as Sell } from './Sell';
export { default as Settings } from './Settings';
export { default as SettingsFilled } from './SettingsFilled';
export { default as ShareIcon } from './ShareIcon';
export { default as Shield } from './Shield';
export { default as SmileyHappy } from './SmileyHappy';
export { default as SmileyHappyFilled } from './SmileyHappyFilled';
export { default as SmileyNeutral } from './SmileyNeutral';
export { default as SmileyNeutralFilled } from './SmileyNeutralFilled';
export { default as SmileySad } from './SmileySad';
export { default as SmileySadFilled } from './SmileySadFilled';
export { default as Sort } from './Sort';
export { default as SortAvg } from './SortAvg';
export { default as SortHighToLow } from './SortHighToLow';
export { default as SortLowToHigh } from './SortLowToHigh';
export { default as SortMax } from './SortMax';
export { default as SortMin } from './SortMin';
export { default as Star } from './Star';
export { default as Steps } from './Steps';
export { default as StepsFilled } from './StepsFilled';
export { default as Suspended } from './Suspended';
export { default as SuspendedFilled } from './SuspendedFilled';
export { default as Swap } from './Swap';
export { default as Task } from './Task';
export { default as Tax } from './Tax';
export { default as ThumbsUp } from './ThumbsUp';
export { default as ThumbsUpFilled } from './ThumbsUpFilled';
export { default as Ticket } from './Ticket';
export { default as Tips } from './Tips';
export { default as Trade } from './Trade';
export { default as TradeFilled } from './TradeFilled';
export { default as Transactions } from './Transactions';
export { default as Trophy } from './Trophy';
export { default as TrophyFilled } from './TrophyFilled';
export { default as Usd } from './Usd';
export { default as UnderInvestigation } from './UnderInvestigation';
export { default as Unlock } from './Unlock';
export { default as Up } from './Up';
export { default as Update } from './Update';
export { default as User } from './User';
export { default as UserId } from './UserId';
export { default as Vipps } from './Vipps';
export { default as VippsDisabled } from './VippsDisabled';
export { default as VippsLabel } from './VippsLabel';
export { default as VippsWhite } from './VippsWhite';
export { default as Wallet } from './Wallet';
export { default as WalletFilled } from './WalletFilled';
export { default as Warning } from './Warning';
export { default as Withdraw } from './Withdraw';
export { default as XmasTreeFilled } from './XmasTreeFilled';
export { default as XmasTree } from './XmasTree';
export { default as FacebookRound } from './FacebookRound';
export { default as InstagramRound } from './InstagramRound';
export { default as LinkedinRound } from './LinkedinRound';
export { default as SnapchatRound } from './SnapchatRound';
export { default as TiktokRound } from './TiktokRound';
export { default as Tiktok } from './Tiktok';
export { default as TwitterRound } from './TwitterRound';
export { default as YoutubeRound } from './YoutubeRound';
