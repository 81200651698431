import { gql, useQuery } from '@apollo/client';

import { GroupedMissingTravelRuleDepositsQuery } from '../gqlTypes';

export const GROUPED_MISSING_TRAVEL_RULE_DEPOSITS_QUERY = gql`
  query GroupedMissingTravelRuleDeposits {
    deposit {
      listGroupedMissingTravelRuleDeposits {
        date
        deposits {
          address
          addressTag
          createdAt
          cryptoAmount {
            amount
            asset
            displayName
          }
          id
        }
      }
    }
  }
`;

export const useGroupedMissingTravelRuleDeposits = () => {
  return useQuery<GroupedMissingTravelRuleDepositsQuery>(
    GROUPED_MISSING_TRAVEL_RULE_DEPOSITS_QUERY,
  );
};
