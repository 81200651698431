import * as React from 'react';
import { SVGProps } from 'react';
const SvgSmileyNeutralFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2C10.6868 2 9.38642 2.25866 8.17316 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17316 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17316C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM7 9.5C7 9.10217 7.15803 8.72064 7.43934 8.43934C7.72064 8.15803 8.10217 8 8.5 8C8.89782 8 9.27935 8.15803 9.56066 8.43934C9.84196 8.72064 10 9.10217 10 9.5C10 9.89782 9.84196 10.2794 9.56066 10.5607C9.27935 10.842 8.89782 11 8.5 11C8.10217 11 7.72064 10.842 7.43934 10.5607C7.15803 10.2794 7 9.89782 7 9.5ZM16 16H8V14H16V16ZM15.5 11C15.1022 11 14.7206 10.842 14.4393 10.5607C14.158 10.2794 14 9.89782 14 9.5C14 9.10217 14.158 8.72064 14.4393 8.43934C14.7206 8.15803 15.1022 8 15.5 8C15.8978 8 16.2794 8.15803 16.5607 8.43934C16.842 8.72064 17 9.10217 17 9.5C17 9.89782 16.842 10.2794 16.5607 10.5607C16.2794 10.842 15.8978 11 15.5 11Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSmileyNeutralFilled;
