const white = '#FFFFFF';

const red = {
  DEFAULT: '#DA2D43',
  900: '#BF1F33',
  600: '#E8667E',
  200: '#FDEBF2',
  100: '#FFFBFC',
};
const green = {
  DEFAULT: '#1E856C',
  900: '#1E856C',
  600: '#34AD87',
  200: '#D8FFEC',
  100: '#F7FFFB',
};
const yellow = {
  DEFAULT: '#FBC23E',
  900: '#FBC23E',
  600: '#FDDA8B',
  200: '#FEF3D8',
};

const pinkle = { DEFAULT: '#CF47FF', 600: '#E291FF', 200: '#F5DAFF' };

const black = {
  DEFAULT: '#12123B',
  900: '#12123B',
  800: '#2E2E53',
  700: '#4A4A6A',
  600: '#666681',
  500: '#828299',
  400: '#9E9FB0',
  300: '#BABBC8',
  200: '#D4D4DD',
  150: '#E5E5EB',
  100: '#F1F2F6',
};

const blue = {
  900: '#3538D4',
  800: '#4043D6',
  DEFAULT: '#474AEE',
  700: '#474AEE',
  600: '#5E61F1',
  500: '#7578F3',
  400: '#8E91F5',
  300: '#BDC0F9',
  200: '#D5D8FB',
  100: '#DFE1F8',
  50: '#EFF0FF',
  10: '#F7F7FE',
};

const currency = {
  light: {
    BTC: '#F7931A',
    LTC: '#BEBEBE',
    ANC: '#427878',
    ETH: '#627EEA',
    XRP: '#23292F',
    DAI: '#FDC134',
    ADA: '#3468D1',
    GST: '#DE7367',
    DOT: '#E6007A',
    SOL: '#14F195',
    USDC: '#2775CA',
    MATIC: '#8247E5',
    AVAX: '#E84142',
    LINK: '#3363D2',
    UNI: '#FF006F',
    USDT: '#26A17B',
    XLM: black[900],
    DOGE: '#C2A633',
    AAVE: '#44A8BF',
    MANA: '#FF755A',
    SAND: '#00ADEF',
    POL: '#6C00F6',
    NOK: black[900],
    DKK: black[900],
    SEK: black[900],
  },
  dark: {
    BTC: '#F7931A',
    LTC: '#BEBEBE',
    ANC: '#427878',
    ETH: '#627EEA',
    XRP: '#E9E9E9',
    DAI: '#FDC134',
    ADA: '#3468D1',
    GST: '#DE7367',
    DOT: '#E6007A',
    SOL: '#14F195',
    USDC: '#2775CA',
    AVAX: '#E84142',
    MATIC: '#FFFFFF',
    LINK: '#3363D2',
    UNI: '#FF006F',
    USDT: '#26A17B',
    XLM: white,
    DOGE: '#C2A633',
    AAVE: '#44A8BF',
    MANA: '#FF755A',
    SAND: '#00ADEF',
    POL: '#6C00F6',
    NOK: black[900],
    DKK: black[900],
    SEK: black[900],
  },
};

const firi = { blue: blue[700], black: black[900], 'light-grey': black[100] };

const lightText = {
  1: black[900],
  2: black[700],
  3: black[500],
  4: black[300],
  5: black[800],
  blue: blue[700],
  white: white,
  green: green[900],
  red: red[900],
  alert: red['DEFAULT'],
  success: green['DEFAULT'],
  black: '#0D0E11',
  pinkle: pinkle.DEFAULT,
  alert: red[600],
};

const darkText = {
  1: white,
  2: '#B2B8C7',
  3: '#A2A8BC',
  4: '#444854',
  5: white,
  white: white,
  blue: blue[300],
  green: '#34AD87',
  red: '#E8667E',
  alert: red['DEFAULT'],
  success: green['DEFAULT'],
  black: '#0D0E11',
  pinkle: pinkle.DEFAULT,
  alert: red[600],
};

const lightBackground = {
  1: white,
  2: black[100],
  3: blue[700],
};

const darkBackground = {
  1: darkText.black,
  2: '#181A1F',
  3: darkText.black,
  4: darkText[2],
  5: darkText[4],
  6: darkText['blue'],
};

export const text = {
  light: { ...lightText, currency: currency.light },
  dark: { ...darkText, currency: currency.dark },
};
export const card = {
  dark: {
    1: '#181A1F',
    2: '#2D3039',
    3: '#333641',
    4: '#444854',
    success: green[200],
    warning: yellow[200],
    alert: red[200],
  },
  light: {
    1: white,
    2: black[100],
    3: blue[50],
    4: blue[200],
    success: green[200],
    warning: yellow[200],
    alert: red[200],
  },
};
export const background = {
  light: { ...lightBackground, currency: currency.light },
  dark: { ...darkBackground, currency: currency.dark },
};
export const colors = {
  red,
  green,
  yellow,
  white,
  black,
  blue,
  pinkle,
  firi,
  currency,
  vipps: '#FF6633',
};
