import { useEffect, useRef, useState } from 'react';

/**
 * Hook for input field
 * @param initialValue
 * @returns
 */
export function useInputField<C = any>(initialValue: string) {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [focused, setFocused] = useState(false);
  const ref = useRef<C>(null);

  useEffect(() => {
    if (value !== initialValue) {
      setTouched(true);
    }
  }, [value]);

  const resetField = () => {
    setValue(initialValue);
    setTouched(false);
    setFocused(false);
    setErrorText('');
  };

  const onChangeText = (text: string) => {
    setValue(text);
    if (errorText) {
      setErrorText('');
    }
  };

  return {
    value,
    setValue,
    focused,
    setFocused,
    touched,
    setTouched,
    errorText,
    setErrorText,
    resetField,
    onChangeText,
    ref,
  };
}
