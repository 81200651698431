import * as React from 'react';
import { SVGProps } from 'react';
const SvgAlertFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.00153 21.75H20.9985C21.5333 21.75 22.0155 21.4755 22.2885 21.0158C22.5615 20.556 22.5713 20.001 22.3155 19.5315L13.317 3.0315C13.0538 2.54925 12.549 2.25 12 2.25C11.451 2.25 10.9463 2.54925 10.683 3.0315L1.68453 19.5315C1.42878 20.001 1.43853 20.556 1.71153 21.0158C1.98453 21.4755 2.46678 21.75 3.00153 21.75ZM11.25 14.25C11.25 14.664 11.586 15 12 15C12.414 15 12.75 14.664 12.75 14.25V9.75C12.75 9.336 12.414 9 12 9C11.586 9 11.25 9.336 11.25 9.75V14.25ZM12.75 17.25C12.75 17.6642 12.4142 18 12 18C11.5858 18 11.25 17.6642 11.25 17.25C11.25 16.8358 11.5858 16.5 12 16.5C12.4142 16.5 12.75 16.8358 12.75 17.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgAlertFilled;
