/*eslint-disable*/ export const messages = {
  '1 day': '1 dag',
  '1 month': '1 måned',
  '1 mth': '1 mdr',
  '1 week': '1 uge',
  '1 y': '1 år',
  '1 year': '1 år',
  '1-2 banking days': '1-2 arbejdsdage',
  '1D': '1D',
  '1M': '1M',
  '1W': '1W',
  '1Y': '1Y',
  '24H': '24H',
  '24h change': '24h ændring',
  '3 months': '3 måneder',
  '3 mth': '3 mdr',
  '3M': '3M',
  '404 - Page not found': '404 - Siden blev ikke fundet',
  '6 digit code': '6-cifret kode',
  '7 days': '7 dage',
  '7D': '7D',
  '919 932 465': '451 87 861',
  'A "Destination tag" identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.':
    'Et "Destination tag" identificerer modtageren af ​​en transaktion. Det er som udgangspunkt valgfrit at indtaste Destination-tagget, men nogle modtagere kræver det.',
  'A "Destination tag" is a unique number that identifies the recipient of a transaction. It is optional to enter the Destination tag, but some recipients require it.':
    'Et "Destination tag" er et unikt nummer, der identificerer modtageren af ​​en transaktion. Det er som udgangspunkt valgfrit at indtaste Destination-tagget, men nogle modtagere kræver det.',
  'A bank account was verified': 'En bankkonto blev bekræftet',
  'A description of you the source of your assets, e.g bank statement or sales statement':
    'En beskrivelse af dig kilden til dine aktiver, f.eks. kontoudtog eller salgsopgørelse',
  'A description of your investment and which bank account has the assets been deducted from':
    'En beskrivelse af din investering og hvilken bankkonto aktiverne er trukket fra',
  'A fee of 3.9% is added to the amount when you make a deposit with a card. You will receive the money immediately.':
    'Et gebyr på 3,9% lægges til beløbet når du sætter penge ind med kort. Du modtager pengene med det samme.',
  'A fee of 3.9% is added to the amount when you use Apple Pay to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% lægges til beløbet når du bruger Apple Pay for at sætte penge ind. Du modtager pengene med det samme.',
  'A fee of 3.9% is added to the amount when you use Google Pay to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% lægges til beløbet når du bruger Google Pay for at sætte penge ind. Du modtager pengene med det samme.',
  'A fee of 3.9% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% lægges til beløbet når du bruger MobilePay for at sætte penge ind. Du modtager pengene med det samme.',
  'A fee of 3.9% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.':
    'Et gebyr på 3,9% tillægges beløbet, når du bruger Vipps til at foretage en indbetaling. Du modtager pengene med det samme.',
  'A fee of {0}% is added to the amount when you make a deposit with a card. You will receive the money immediately.':
    [
      'Et gebyr på ',
      ['0'],
      '% lægges til beløbet når du sætter penge ind med kort. Du modtager pengene med det samme.',
    ],
  'A fee of {0}% is added to the amount when you use MobilePay to make a deposit. You will receive the money immediately.':
    [
      'Et gebyr på ',
      ['0'],
      '% lægges til beløbet når du bruger MobilePay for at sætte penge ind. Du modtager pengene med det samme.',
    ],
  'A fee of {0}% is added to the amount when you use Vipps to make a deposit. You will receive the money immediately.':
    [
      'Et gebyr på ',
      ['0'],
      '% tillægges beløbet, når du bruger Vipps til at foretage en indbetaling. Du modtager pengene med det samme.',
    ],
  'A fixed fee of {0} will be deducted from the withdrawal amount to cover our costs.': [
    'Et fast gebyr på ',
    ['0'],
    ' vil blive trukket fra tilbagetrækningsbeløbet for at dække vores omkostninger.',
  ],
  'A key reason why cryptocurrency has become so popular is the pioneering blockchain technology. Blockchain technology allows digital interactions to be recorded, tracked and made publicly visible. It opens up a whole new way of creating secure, distributed services that are accessible to all and governed by the community.':
    'En vigtig årsag til, at kryptovalutaer er blevet så populære, er den banebrydende blockchain-teknologi. Blockchain-teknologien gør det muligt at registrere, spore og synliggøre digitale interaktioner. Det åbner op for en helt ny måde at skabe sikre, distribuerede tjenester på, som er tilgængelige for alle og styret af fællesskabet.',
  'A lot is happening here! These crypto have had the biggest change in price the last 24 hours':
    'Der sker meget her! Disse kryptovalutaer har haft den største prisændring inden for de sidste 24 timer',
  'A lot is happening with': 'Der sker meget med',
  'A market order is executed immediately and at the best available price on the Firi market when you confirm your order.':
    'En markedsordre bliver gennemført med det samme og til Firi-markedets bedste pris når du bekræfter din ordre.',
  'A new hatch is opened every day at 8 am': 'Ny låge åbner hver dag kl. 08.00',
  "A premium user experience is part of Firi's DNA, so with this update, you will experience a cleaner, tidier, and faster app 🥇 Swipe around a bit to get to know everything we have to offer!":
    "Førsteklasses brugeroplevelse er en del af DNA'et hos Firi, så med denne opdatering vil du opleve en renere, mere ryddelig og hurtigere app 🥇 Swipe dig lidt rundt for at blive bekendt med alt, hvad vi har at tilbyde!",
  API: 'API',
  'API Key': 'API nøgle',
  'API Key credentials': 'API-nøglelegitimationsoplysninger',
  'API documentation': 'API dokumentation',
  'API key permission': 'API-nøgletilladelse',
  'Aave is a decentralized financial platform built on the Ethereum blockchain. It enables users to borrow, lend and earn interest on various cryptocurrencies, using smart contracts.':
    'Aave er en decentraliseret finansiel platform bygget på Ethereum blockchain. Den giver brugerne mulighed for at låne, udlåne og tjene renter på forskellige kryptovalutaer ved hjælp af smart contracts.',
  "Aave's associated cryptocurrency is called AAVE and is used for governance and security within the smart contracts on the Aave DeFi platform.":
    'Aaves tilknyttede kryptovaluta hedder AAVE og bruges til styring og sikkerhed i de smarte kontrakter på Aave DeFi-platformen.',
  'About "Travel Rule"': 'Om "Travel Rule"',
  'About Aave': 'About Aave',
  'About Avalanche': 'Om Avalanche',
  'About BNB': 'Om BNB',
  'About Bitcoin': 'Om Bitcoin',
  'About Cardano': 'Om Cardano',
  'About Chainlink': 'Om Chainlink',
  'About DAI': 'Om DAI',
  'About Decentraland (MANA)': 'Om Decentraland (MANA)',
  'About Dogecoin': 'Om Dogecoin',
  'About Ethereum': 'Om Ethereum',
  'About Firi': 'Om Firi',
  'About Litecoin': 'Om Litecoin',
  'About Matic': 'Om Matic',
  'About Polkadot': 'Om Polkadot',
  'About Polygon': 'About Polygon',
  'About Solana': 'Om Solana',
  'About Stellar': 'Om Stellar',
  'About Tether USDt': 'Om Tether USDt',
  'About The Sandbox (SAND)': 'Om SAND',
  'About USD Coin': 'Om USD Coin',
  'About Uniswap': 'Om Uniswap',
  'About XRP': 'Om XRP',
  'About me': 'Om mig',
  'About savings account': 'Om opsparingskonto',
  'About us': 'Om os',
  Accept: 'Godta',
  'Accept and continue': 'Accepter og fortsæt',
  'Accept everything': 'Tillad alt',
  'Access to your account is temporarily blocked.': 'Adgang til din konto er midlertidigt blokeret',
  Account: 'Konto',
  'Account activities': 'Kontoaktiviteter',
  'Account level 4 reached': 'Kontoniveau 4 nået',
  'Account number': 'Kontonummer',
  'Account number belongs to a bank that is not supported by Firi':
    'Kontonummer tilhører en bank, der ikke understøttes af Firi',
  'Account number belongs to a bank that is not supported by Firi. Please change or delete this account number.':
    'Kontonummer tilhører en bank, der ikke understøttes af Firi. Ændre eller slet dette kontonummer.',
  Accounts: 'Konti',
  'Action required for new deposit': 'Bekræft dit indskud',
  'Activate 2FA': 'Aktiver 2FA',
  'Activate staking and earn rewards': 'Aktiver staking og belønninger',
  'Activate the saving agreement in your bank': 'Aktiver opsparingsaftalen i din bank',
  'Activate two-factor authentication': 'Aktiver to-faktor-godkendelse',
  'Activate two-factor authentication (2FA)': 'Aktiver to-faktor-godkendelse (2FA)',
  'Activated!': 'Aktiveret!',
  'Activity log': 'Aktivitetslog',
  Add: 'Tilføj',
  'Add additional information': 'Tilføj yderligere oplysninger',
  'Add as favorite': 'Tilføj som favorit',
  'Add bank account': 'Tilføj bankkonto',
  'Add configuration key': 'Tilføj konfigurationsnøgle',
  'Add funds': 'Tilføj penge',
  'Add funds to trade crypto': 'Tilføj penge til handel med krypto',
  'Add manually': 'Tilføj manuelt',
  'Add more': 'Tilføj flere',
  'Add more information': 'Tilføj flere oplysninger',
  'Add new key': 'Tilføj ny nøgle',
  'Add or remove cryptos from your favorites':
    'Tilføj eller fjern kryptovaluta fra dine favoritter',
  'Add purchase price': 'Tilføj købspris',
  'Add receiver': 'Gem modtageren',
  'Add your friends referralcode when signing up':
    'Tilføj dine venners invitationskode, når du tilmelder dig',
  'Added as favorite! You\'ll find "My favorites" in the overview.':
    'Tilføjet som favorit! Du finder "Mine favoritter" på oversigten.',
  Address: 'Adresse',
  'Advanced trade': 'Avanceret handel',
  'Advent calendar': 'Julekalender',
  Affiliate: 'Affiliate',
  'Affiliate bonus': 'Affiliate bonus',
  'Affiliate commision': 'Affiliate bonus',
  'Affiliate program': 'Affiliate program',
  'Affiliate program guidelines': 'Retningslinjer for hvervning',
  All: 'Alle',
  'All accounts': 'Alle konti',
  'All articles': 'Alle artikler',
  'All crypto': 'Alle krypto',
  'All crypto on Firi': 'Alle krypto hos Firi',
  'All currencies': 'Alle valutaer',
  'All payouts': 'Alle udbetalinger',
  'All trades with cryptocurrency will always be done from your main account. This is where your assets for your buying and selling exist.':
    'Alle handler med kryptovaluta vil altid blive foretaget fra din hovedkonto. Det er her dine aktiver til dit køb og salg findes.',
  'All types': 'Alle typer',
  Alphabetical: 'Alfabetisk',
  'Already have a user?': 'Har du allerede en bruger?',
  'Always double check that the copied wallet address is correct before making a deposit':
    'Dobbelttjek altid, at den kopierede wallet-adresse er korrekt, før du foretager en indbetaling',
  'Amnesty International Norge 2021': 'Amnesty International Norge 2021',
  'Among other things, we consider mining and staking as income. Remember to list the value when you acquired this crypto':
    'Vi betragter bl.a minedrift og staking som indtægt. Husk at angive værdien, da du erhvervede denne krypto',
  Amount: 'Beløb',
  'Amount ({0})': ['Beløb (', ['0'], ')'],
  'Amount exceeds available funds': 'Beløbet overstiger de disponible midler',
  'Amount exceeds the deposit limit. Try a smaller amount.':
    'Beløbet overstiger indbetalingsgrænsen. Prøv en mindre mængde.',
  'Amount is too large': 'Beløbet er for højt',
  'Amount is too low': 'Beløbet er for lavt',
  'Amount must be more than 0': 'Beløbet skal være mere end 0',
  'Amount to high. Exceeds available funds': 'Beløbet for højt. Overstiger de disponible midler',
  'Amount to high. Exceeds withdraw limit': 'Beløbet for højt. Overskrider udbetalingsgrænse',
  'Amount to high. Maximum {0}': ['Beløbet er for højt. Maksimumsbeløb er ', ['0']],
  'Amount to low. Minimum {0}': ['Beløb for lavt. Minimum ', ['0']],
  'Amount too high': 'Beløb for højt',
  'Amount too high. Maximum withdraw is {0} {1}': [
    'Beløb for højt. Maksimal udbetaling er ',
    ['0'],
    ' ',
    ['1'],
  ],
  'Amount too high. Maximum withdraw is {0} {currency}': [
    'Beløb for højt. Maksimal udbetaling er ',
    ['0'],
    ' ',
    ['currency'],
  ],
  'Amount too low': 'Beløb for lavt',
  'Amount too low. Minimum withdrawal is {0} {currency} ≈ {1} {2}': [
    'Beløb for lavt. Minimumsudbetaling er ',
    ['0'],
    ' ',
    ['currency'],
    ' ≈ ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Amount too low. Minimum withdrawal is {minLimit} {0}': [
    'Beløb for lavt. Minimum udbetaling er ',
    ['minLimit'],
    ' ',
    ['0'],
  ],
  'Amount too low. Minimum {0}': ['Beløb for lavt. Minimum ', ['0']],
  'An app you can trust': 'En app, du kan stole på',
  "An error has occurred. We're looking into it right away. Your funds are completely safe with us. Please try again in a little while.":
    'Der er opstået en fejl. Vi ser på det med det samme. Dine midler er helt sikre hos os. Prøv venligst igen om lidt.',
  'An error occured. Please try again!': 'En fejl opstod. Prøv igen!',
  'An error occurred while changing your password.':
    'Der opstod en fejl under ændring af din adgangskode.',
  'Annual return is an estimate and may vary over time.':
    'Årligt afkast er et skøn og kan variere over tid.',
  'Annual yield is an estimate and will vary over time.':
    'Årligt afkast er et skøn og vil variere over tid.',
  'Answer has been sent': 'Svar er sendt',
  Appearance: 'Udseende',
  'Apple Pay': 'Apple Pay',
  'Apple Pay fee': 'Apple Pay gebyr',
  'Apps built on the blockchain need external data to function. Chainlink was founded to serve as a bridge between the blockchain world and the real world by connecting so-called smart contracts, such as those on Ethereum, with reliable and verified data sources outside the blockchain. Chainlink achieves this through a network of so-called "oracles".':
    'Apps, som der er bygget på blockchainen, har brug for eksterne data for at fungere. Chainlink blev grundlagt for at bygge bro mellem blockchain-verdenen og den virkelige verden ved at tilkoble såkaldte smarte kontrakter, fx på Ethereum, med pålidelige og verificerede datakilder uden for blockchainen. Det løser Chainlink ved hjælp af et netværk af såkaldte "orakler".',
  'Are you experiencing problems?': 'Oplever du problemer?',
  'Are you not receiving the email?': 'Modtager du ikke mailen?',
  'Are you ready to invest in your future, {0}?': [
    'Er du klar til at investere i din fremtid, ',
    ['0'],
    '?',
  ],
  'Are you sure all deposits come from the same sender?':
    'Er du sikker på, at alle indskud kommer fra den samme afsender?',
  'Are you sure that you want to cancel this transaction':
    'Er du sikker på, at du vil annullere denne transaktion',
  'Are you sure the account number is correct?': 'Er du sikker på, at kontonummeret er korrekt?',
  'Are you sure you want to continue?': 'Er du sikker på, at du vil fortsætte?',
  'Are you sure you want to delete the file?': 'Er du sikker på, at du vil slette filen?',
  'Are you sure you want to enter a lower rate than the best available price in our market? We do not have a stop-loss which means that the order is executed instantly at our best price.':
    'Er du sikker på, at du vil indtaste en lavere pris end den bedst tilgængelige pris i vores marked? Vi har ikke et stop-loss, hvilket betyder, at ordren udføres øjeblikkeligt til vores bedste pris.',
  'Are you sure you want to remove the bank account?':
    'Er du sikker på, at du vil fjerne bankkontoen?',
  'Are you sure?': 'Er du sikker?',
  'Are you transferring to an account you own?': 'Sender du til en wallet, du ejer?',
  Article: 'Artikel',
  Articles: 'Artikler',
  'As of April 9th it will not be possible to redeem gift cards.':
    'Fra den 9. april vil det ikke være muligt at indløse gavekort.',
  'As of March 15, 2022, we will no longer offer commission for new referrals. You will of course retain commission on the trading fees of those you have already referred and will continue to earn from this.':
    'Fra den 15. marts 2022 vil vi ikke længere tilbyde provision for nye henvisninger. Du vil selvfølgelig beholde provisionen på handelsgebyrerne for dem, du allerede har henvist, og vil fortsat drage fordel af dette.',
  'At the same time, you help Firi make the crypto world even brighter and safer for everyone.':
    'Samtidig hjælper du Firi med at gøre kryptoverdenen endnu lysere og mere sikker for alle.',
  'Author: {0}. Read articles about crypto. Buy and sell bitcoin and other cryptocurrencies for {1} at Firi.':
    [
      'Forfatter: ',
      ['0'],
      '. Les artikler om krypto. Køb og sælg bitcoin og andre kryptovalutaer for ',
      ['1'],
      ' hos Firi.',
    ],
  'Automatic purchase with crypto-save': 'Automatiske køb med kryptospar',
  'Automatic tax calculation - <0>we keep track of your numbers!</0>':
    'Automatisk skatteberegning - <0>vi holder styr på tallene!</0>',
  Available: 'Tilgængelig',
  'Available balance': 'Tilgængelig saldo',
  'Available for staking': 'Tilgængeligt for staking',
  'Available for withdrawal': 'Til rådighed for udbetaling',
  'Avalanche Contract Chain (C-Chain)': 'Avalanche Contract Chain (C-Chain)',
  "Avalanche is a blockchain platform that focuses on high transaction speed, low fees, and environmental friendliness. Avalanche's associated cryptocurrency is called AVAX.":
    "Avalanche er en blockchain-platform, der fokuserer på høj transaktionshastighed, lave gebyrer og miljøvenlighed. Avalanche's tilhørende kryptovaluta hedder AVAX.",
  "Avalanche is a relatively new blockchain and cryptocurrency. It was launched in 2020, and AVAX has quickly become one of the world's largest cryptocurrencies based on market capitalization.":
    'Avalanche er en relativt ny blockchain og kryptovaluta. Den blev lanceret i 2020, hvortil AVAX på kort tid er blevet en af verdens største kryptovalutaer målt på markedsværdi.',
  'Average price': 'Gennemsnitspris',
  'Avoid extra work – keep your crypto with Firi and get:':
    'Undgå ekstra arbejde - Hold kryptoen på Firi og få:',
  'Awesome that you like our crypto news!': 'Fedt, at du kan lide kryptonyheder!',
  'BNB (BNB) is the cryptocurrency of Binance and is currently the fourth largest cryptocurrency in the world.':
    'BNB (BNB) er Binances kryptovaluta der i dag er den fjerde største kryptovaluta i verden.',
  Back: 'Tilbage',
  'Back to logged in': 'Tilbage til logget ind',
  'Back to login': 'Tilbage til login',
  Balance: 'Balance',
  'Bank ID': 'Bank-ID',
  'Bank account added!': 'Bankkonto tilføjet!',
  'Bank accounts': 'Bankkonti',
  'Bank deposit': 'Bank indskud',
  'Bank transfers': 'Bankoverførsler',
  BankID: 'BankID',
  'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as a bank statement, sales statement, inheritance or gift certificate that documents the origin of the funds.':
    'Baseret på de transaktioner, der udføres og risikoen forbundet med kryptovaluta, har vi nogle gange brug for information om midlernes oprindelse. Vi accepterer forskellige former for dokumentation såsom kontoudtog, salgsopgørelse, arv eller gavekort, der dokumenterer midlernes oprindelse.',
  'Based on the transactions that are carried out and the risk associated with cryptocurrency, we sometimes need information about the origin of the funds. We accept various forms of documentation such as closing note, settlement statement, inheritance settlement, loan documents or gift certificates documenting the origin of the funds.':
    'Baseret på de transaktioner, der udføres og risikoen forbundet med kryptovaluta, har vi nogle gange brug for information om midlernes oprindelse. Vi accepterer forskellige former for dokumentation såsom slutseddel, afregningsopgørelse, arveafregning, lånedokumenter eller gavebeviser, der dokumenterer midlernes oprindelse.',
  'Based on the type of documentation, there may be several rounds of questions, answers and the need for additional documentation. Therefore, we can also request the latest available Tax Report which gives us a secure and comprehensive overview of your funds and financial situation.':
    'Ud fra typen af ​​dokumentation kan der være flere runder af spørgsmål, svar og behov for yderligere dokumentation. Derfor kan vi også rekvirere den senest tilgængelige Skatterapport, som giver os et sikkert og samlet overblik over dine midler og økonomiske situation.',
  'Be aware of fraud!': 'Pas på svindel!',
  'Be aware that investing in and staking crypto involves risk.':
    'Være opmærksom på at investering og staking af krypto indebærer risiko',
  'Because of its fast transactions and low fees, Doge is often used for online donations and small payments.':
    'På grund af dens hurtige transaktioner og lave gebyrer, bruges Dogecoin ofte til online donationer og mindre betalinger.',
  'Because of this, meme coins are highly volatile and prices can change dramatically in a short period of time. It is important to be aware of the risks when choosing to invest in meme coins.':
    'På grund af dette er meme-coins meget volatile, da priserne kan ændre sig enormt på kort tid. Det er vigtigt at være opmærksom på risikoen, når man vælger at engagere sig i meme-coins.',
  "Become a Firi user in minutes with Vipps. Your profile is filled out automatically, completely free. Sign with BankID – and you're ready to go 🙌":
    'Bliv Firi-bruger på få minutter med Vipps. Din profil udfyldes automatisk, helt gratis. Signer med BankID – og du er klar til at gå 🙌',
  'Become a customer of Firi': 'Bliv kunde hos Firi',
  'Beginner crypto investor': 'Begynder krypto investor',
  'Being paid out on': 'Bliver udbetalt på',
  'Belongs to the categories': 'Tilhører kategorierne',
  'Belongs to the category': 'Tilhører kategorien',
  'Best regards': 'Med venlig hilsen',
  'Binance Smart Chain (BEP20)': 'Binance Smart Chain (BEP20)',
  'Bitcoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.':
    'Bitcoin er en digital valuta, som du kan gemme og overføre direkte til hvem som helst, hvor som helst i verden, uden at gå gennem tredjeparter såsom banker eller andre centrale institutioner.',
  'Bitcoin is, in addition to being a cryptocurrency, also a digital payment network. The Bitcoin network is decentralized, and is based on blockchain technology. Being decentralized means you can transfer bitcoin directly to others without third-party interference.':
    'Bitcoin er, udover at være en kryptovaluta, også et digitalt betalingsnetværk. Bitcoin-netværket er decentraliseret og er baseret på blockchain-teknologi. At være decentral betyder, at du kan overføre bitcoin direkte til andre uden indblanding fra tredjepart.',
  'Bitcoin network': 'Bitcoin-netværket',
  Bonus: 'Bonus',
  Bought: 'Købt',
  'Bought {0}/3 cryptocurrencies for {1} {2}': [
    'Købt ',
    ['0'],
    '/3 kryptovalutaer for ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Built on the Ethereum network, DAI makes it easy to gain exposure to a more stable cryptocurrency that is also based on blockchain technology.':
    'Bygget på Ethereum-netværket gør DAI det nemt at få eksponering imod en mere stabil kryptovaluta, der også er baseret på blockchain-teknologi.',
  "Bummer! Unfortunately, Firi does not support this crypto. Feel free to let us know if it's on your wish list.":
    'Så kedeligt!Firi støtter desværre ikke denne kryptovaluta. Du er velkommen til at give os besked, hvis den står på din ønskeliste.',
  Buy: 'Køb',
  'Buy & Sell': 'Køb & Sælg',
  'Buy and sell bitcoin and other cryptocurrencies for NOK, safely and easily. We are registered with Finanstilsynet, have a Vipps login, use BankID and a Norwegian bank for deposits and withdrawals.':
    'Køb og sælg bitcoin og andre kryptovalutaer for DKK, sikkert og nemt. Vi er registreret hos Finanstilsynet. Vi bruger MitID og en international bank til ind- og udbetalinger.',
  'Buy and sell bitcoin and other cryptocurrencies for NOK. We are registered as an exchange and custodian for cryptocurrency in Norway.':
    'Køb og sælg bitcoin og andre kryptovalutaer for DKK. Vi er registreret som udbyder af tjenester med virtuel valuta (VASP) i Danmark',
  'Buy and simply stake SOL!': 'SOL(en) titter frem - stake Solana i dag!',
  'Buy bitcoin | Firi - Norwegian cryptocurrency exchange':
    'Køb bitcoin | Firi - Norsk kryptovaluta børs',
  'Buy bitcoin | Firi - The largest cryptocurrency exchange in the Nordics':
    'Køb bitcoin | Firi - Nordens største kryptobørs',
  'Buy completed': 'Køb gennemført',
  'Buy completed and staking activated!': 'Køb gennemført og staking aktiveret!',
  'Buy crypto': 'Køb krypto',
  'Buy crypto in seconds with Vipps or card. This is suitable for those who want to make quick trades.':
    'Køb krypto på få sekunder med kort eller ApplePay. Dette passer til dig, der ønsker at foretage hurtige handler.',
  'Buy crypto in seconds with card. This is suitable for those who want to make quick trades.':
    'Køb krypto på få sekunder med kort. Dette passer til dig, der ønsker at foretage hurtige handler.',
  'Buy crypto now': 'Køb krypto nu',
  'Buy crypto to give away': 'Køb krypto for at give væk',
  'Buy crypto to stake': 'Køb krypto for at stake  ',
  'Buy for total amount': 'Køb for et samlet beløb',
  'Buy more crypto': 'Køb mere krypto',
  'Buy not completed': 'Køb ikke gennemført',
  'Buy {0} to stake': ['Køb ', ['0'], ' til staking'],
  'Buy {currency} and activate staking': ['Køb ', ['currency'], ' og aktiver staking'],
  'Buy {name}': ['Køb ', ['name']],
  'Buying and selling is done from your main account': 'Køb og salg sker fra din hovedkonto',
  'By clicking "continue" I give Firi consent to store my information in accordance with the <0>privacy policy.</0>':
    'Ved at klikke på "Fortsæt" giver jeg Firi samtykke til at gemme mine oplysninger i overensstemmelse med <0>privatlivspolitikken.</0>',
  'By clicking on "Confirm", I agree to Firi AS terms of use':
    'Ved at klikke på "Bekræft", accepterer jeg Firi AS betingelser for brug',
  'By completing the transfer, you agree to securely share your information (name, wallet address, and Firi-ID) with the recipient.':
    'Ved at gennemføre overførslen accepterer du at dele dine oplysninger (navn, wallet-adresse og Firi-ID) sikkert med modtageren.',
  "By continuing, you agree to Firi's user terms for":
    'Ved at fortsætte accepterer du Firis brugervilkår for',
  'By entering the Christmas calendar, you agree that we will store your answers and contact you by email if you win.':
    'Ved at deltage i julekalenderen accepterer du, at vi gemmer dine svar og kontakter dig pr. e-mail, hvis du vinder.',
  'By keeping all your information up to date, you will get the very best user experience Firi has to offer. This includes shorter processing time at our customer service.':
    'Ved at holde alle dine oplysninger opdateret, får du den allerbedste brugeroplevelse, Firi har at tilbyde. Dette inkluderer kortere behandlingstid hos vores kundeservice.',
  'By pressing "Confirm", I agree to Firi AS\'s terms of use':
    'Ved at trykke på "Bekræft" accepterer jeg Firi AS\'s brugsbetingelser',
  'By pressing "I agree" I agree to Firi AS\'s terms of use for staking.':
    'Ved at trykke på "Jeg godkender" accepterer jeg Firi AS\'s vilkår for brug af staking.',
  'By sharing anonymous statistics about how you use our app, we can improve our functionalities and your customer experience.':
    'Ved at dele anonym statistik om, hvordan du bruger vores app, kan vi forbedre vores funktionaliteter og din kundeoplevelse.',
  'By staking, you receive a reward because you contribute to securing and maintaining the blockchain that the specific cryptocurrency belongs to.':
    'Ved staking modtager du en belønning fordi du bidrager til at sikre og drive den blockchain, som den specifikke kryptovaluta tilhører.',
  'CPR must be 10 digits': 'CPR skal være 10 cifre',
  'CPR-number': 'CPR-nummer',
  "CPR-number doesn't match MitID. Try again.": 'CPR-nummeret matcher ikke MitID. Prøv igen.',
  'CSV - Transaction history': 'CSV - Transaktionshistorik',
  'Calculating tax...': 'Beregner skat...',
  'Camera permission required': 'Kameratilladelse påkrævet',
  'Campaign! Only from {0} to {1}': ['Kampagne! Kun fra ', ['0'], ' til ', ['1']],
  'Can be staked later this year': 'Kan stakes senere i år',
  'Can be staked this fall': 'Kan stakes til efteråret',
  "Can't find the crypto you are looking for?":
    'Kan du ikke finde den kryptovaluta, du leder efter?',
  "Can't find your bank?": 'Kan du ikke finde din bank?',
  Cancel: 'Afbryd',
  'Cancel transaction': 'Annuller transaktionen',
  Cancelled: 'Annulleret',
  'Cannot redeem own gift card': 'Kan ikke indløse eget gavekort',
  'Cannot redeem your own giftcard': 'Du kan ikke indløse dit eget gavekort',
  'Capital value': 'Kapitalværdi',
  Card: 'Kort',
  'Card fee': 'Kortgebyr',
  'Cardano is also a flexible blockchain that makes it possible to create decentralized applications.':
    'Cardano er også en fleksibel blockchain, der gør det muligt at skabe decentrale applikationer.',
  'Cardano is one of the largest cryptocurrencies in the world based on market capitalization. The associated cryptocurrency of Cardano is called ADA.':
    'Cardano er en af de største kryptovalutaer i verden baseret på markedsværdi. Cardanos tilhørende kryptovaluta kaldes ADA.',
  'Cardano network': 'Cardano-netværket',
  Categories: 'Kategorier',
  Category: 'Kategori',
  "Chainlink is a leading cryptocurrency and a decentralized network. Chainlink's cryptocurrency is called LINK and is among the largest cryptocurrencies based on market value.":
    'Chainlink er en førende kryptovaluta og et decentraliseret netværk. Chainlinks kryptovaluta, LINK, er blandt de største kryptovalutaer baseret på markedsværdi.',
  Change: 'Ændre',
  'Change <0><1/></0>': 'Ændre <0><1/></0>',
  'Change bank': 'Skift bank',
  'Change bank account': 'Ændre bankkonto',
  'Change in prices': 'Ændring i priser',
  'Change password': 'Skift kodeord',
  'Change payment method': 'Skift betalingsmetode',
  'Change purchase price': 'Ændre købspris',
  'Change savings agreement': 'Ændre opsparingsaftale',
  'Changes regarding purchase price for deposits from 2025':
    'Ændringer i købspris for indskud fra 2025',
  'Check for update': 'Tjek for opdatering',
  'Check it out now!': 'Tjek ud nu!',
  'Check out': 'Tjek ud',
  'Check out staking': 'Tjek staking ud',
  'Check out taxes': 'Tjek skat ud',
  'Check that you entered the code correctly or resend code':
    'Tjek at du har indtastet koden korrekt eller gensend kode',
  'Choose Vipps or cards for lightning-fast purchases, or use Norwegian kroner you already have in your Firi account.':
    'Vælg kort eller ApplePay for lynhurtige køb, eller brug danske kroner, du allerede har på din Firi-konto.',
  'Choose account you want to transfer to': 'Vælg den konto, du vil overføre til',
  'Choose amount you want to stake': 'Vælg beløb du vil stake',
  'Choose amount you want to withdraw': 'Vælg det beløb, du vil hæve',
  'Choose cards for lightning-fast purchases, or use Danish kroner you already have in your Firi account.':
    'Vælg kort eller ApplePay for lynhurtige køb, eller brug danske kroner, du allerede har på din Firi-konto.',
  'Choose currency and amount': 'Vælg valuta og beløb',
  'Choose from library': 'Vælg fra biblioteket',
  'Choose how many percent of your savings amount should be placed in the different currencies.':
    'Vælg hvor mange procent af dit opsparingsbeløb, der skal placeres i de forskellige valutaer.',
  'Choose max {maxAllowedAnswers} alternativer': [
    'Vælg maks ',
    ['maxAllowedAnswers'],
    ' alternativer',
  ],
  'Choose one option': 'Vælg én mulighed',
  'Choose one or more cryptocurrencies': 'Vælg en eller flere kryptovalutaer',
  'Choose period': 'Vælg periode',
  'Choose the correct answer to continue': 'Vælg den rigtige påstand for at fortsætte',
  'Choose the options that apply most, maximum three options.':
    'Vælg de muligheder, der matcher mest, maksimalt tre muligheder.',
  'Choose the options that apply most, possible with all':
    'Vælg de muligheder, der matcher mest, muligt med dem alle.',
  'Choose the options that apply most, possible with all.':
    'Vælg de muligheder, der matcher mest, muligt med dem alle.',
  'Choose where you want to upload file from': 'Vælg, hvor du vil uploade filen fra',
  'Choose your bank': 'Vælg din bank',
  'Choose {crypto} price for buy?': ['Vælg ', ['crypto'], ' pris for køb'],
  'Choose {crypto} price for sell?': ['Vælg ', ['crypto'], ' pris for salg'],
  'Christmas Bonus': 'Julebonus',
  'Christmas giftcard': 'julegavekort',
  City: 'By',
  Claim: 'Påstand',
  'Claim reward': 'Gør krav på belønning',
  'Click here to export account statement': 'Klik her for at eksportere kontoudtog',
  'Click here to export annual statement': 'Klik her for at eksportere årsopgørelse',
  'Click here to see how you do it': 'Klik her for at se hvordan du gør det',
  'Click on virtual currency/cryptocurrency': 'Klik på virtuel valuta/kryptovaluta',
  'Client ID': 'Klient id  ',
  Close: 'Luk',
  'Close my account': 'Luk min konto',
  'Close section': 'Luk afsnit',
  'Closing slip': 'Sluttseddel',
  'Code from giftcard': 'Kode fra gavekort',
  'Code has expired': 'Linket er udløbet',
  'Code is not valid': 'Koden er ikke gyldig',
  'Code not found or expired': 'Linket er ikke gyldigt eller udløbet',
  'Code sent': 'Kode sendt',
  Coins: 'Coins',
  'Collection of customer behavior': 'Indsamling af kundeadfærd',
  'Come back on December 1 to find out what Firi Santa has hidden behind the first hatch.':
    'Kom tilbage den 1. december og se, hvad Firi-nissen har gemt bag den første låge.',
  'Come back tomorrow': 'Kom tilbage i morgen',
  'Coming soon': 'Kommer snart',
  Commission: 'Kommissions',
  'Commission on old referrals': 'Kommission for gamle rekrutter',
  'Company name': 'Firmanavn',
  Completed: 'Færdiggjort',
  Confirm: 'Bekræft',
  'Confirm and pay': 'Bekræft og betal',
  'Confirm deposit': 'Bekræft indskud',
  'Confirm gift card': 'Bekræft gavekort',
  'Confirm order': 'Bekræft ordre',
  'Confirm sell': 'Bekræft salg',
  'Confirm your personal code': 'Bekræft din personlige kode',
  'Confirmation code': 'Bekræftelseskode',
  Confirmations: 'Bekræftelser',
  'Congratulations with savings account': 'Tillykke med opsparingskonto',
  'Congratulations!': 'Tillykke!',
  'Congratulations! Which crypto currency will you invest in first?':
    'Tillykke! Hvilken kryptovaluta vil du først investere i?',
  'Congratulations! You found an easter egg!': 'Tillykke! Du har fundet et påskeæg!',
  'Congratulations! You have received <0/> so that you can test out Firi. Which cryptocurrency do you want to invest in first?':
    'Tillykke! Du har modtaget <0/>, så du kan teste Firi. Hvilken kryptovaluta vil du først investere i?',
  'Congratulations! You have received {0} so that you can test out Firi. Which cryptocurrency will you invest in first?':
    [
      'Tillykke! Du har modtaget ',
      ['0'],
      ', så du kan teste Firi. Hvilken kryptovaluta vil du først investere i?',
    ],
  'Congratulations! You now have a staking account.': 'Tillykke! Du har nu en staking-konto.',
  'Congratulations, you have collected {amountTickets} tickets for the big prize! Each lottery ticket gives you an extra chance to win the top prize on Christmas Eve. The more lottery tickets, the greater the chance.':
    [
      'Tillykke, du har samlet ',
      ['amountTickets'],
      ' lod til den store hovedpræmie! Hvert lod giver dig en ekstra chance for at vinde den store hovedpræmie på Juleaften. Jo flere lodder, jo større chance.',
    ],
  'Connect to': 'Link til',
  'Connect your account to Vipps for easier login': 'Link din konto til Vipps for lettere login ',
  Consent: 'Samtykke',
  Contact: 'Kontakt',
  'Contact our support': 'Kontakt vores support',
  'Contact our support to change your contact information':
    'Kontakt vores support for at ændre kontaktoplysninger',
  'Contact support': 'Kontakt support',
  'Contact us': 'Kontakt os',
  Content: 'Indhold',
  Continue: 'Fortsæt',
  'Continue with bank deposit': 'Fortsæt med bankindskud',
  'Control company name': 'Kontrollér virksomhedens navn',
  'Cookies give you a personalized experience': 'Cookies giver dig en personlig oplevelse',
  Copied: 'Kopieret',
  Copy: 'Kopier',
  'Copy address': 'Kopiér adresse',
  'Copy code': 'Kopier kode',
  'Copy link': 'Kopier link',
  'Copy referral link': 'Kopiér invitationslinket',
  'Copy the configuration key and add it to your 2FA app':
    'Kopiér konfigurationsnøglen og føj den til din 2FA-app',
  'Corporate account': 'Virksomhedskonto',
  'Correct network: <0>{0}</0>': ['Korrekt netværk: <0>', ['0'], '</0>'],
  'Correct wallet address': 'Korrekt wallet-adresse',
  'Could not create contact': 'Kontakten kunne ikke oprettes',
  'Could not find any currencies': 'Fandt ingen kryptovaluta',
  'Could not open application for your payment.': 'Kunne ikke åbne app for din betaling.',
  'Could this be the crypto Christmas present of all time?':
    'Kunne dette være tidens kryptojulegave?',
  Country: 'Land',
  'Country manager Denmark': 'Landechef Danmark',
  'Country of residence': 'Bopælsland',
  Create: 'Opret',
  'Create a fixed transfer/payment to the account information below:':
    'Opret en fast overførsel/betaling til nedenstående kontooplysninger:',
  'Create a personal six digit code to unlock the Firi app':
    'Opret en personlig sekscifret kode for at låse Firi-appen op',
  'Create a recurring transfer/payment to the account information below:':
    'Opret en fast overførsel/betaling til nedenstående kontooplysninger:',
  'Create a savings account in the Firi-app': 'Åbn en opsparingskonto i Firi-appen',
  'Create a savings account with us today.': 'Opret en opsparingskonto hos os i dag.',
  'Create a savings agreement with automatic purchases of crypto currency.':
    'Opret en opsparingsaftale med automatisk køb af kryptovaluta.',
  'Create a transfer/payment to the account information below:':
    'Opret en overførsel/betaling til nedenstående kontooplysninger:',
  'Create account with Vipps': 'Opret konto hos Vipps',
  'Create account with your email': 'Opret konto med din e-mail',
  'Create code': 'Oprette kode',
  'Create gift card': 'Opret gavekort',
  'Create new gift card': 'Opret nyt gavekort',
  'Create savings account': 'Opret en opsparingskonto',
  'Create savings agreement': 'Opret opsparingsaftale',
  'Creating an API private key provides access to markets and real-time trading services on Firi via a third-party site or application.':
    'Oprettelse af en privat API-nøgle giver adgang til markeder og realtids-handelstjenester på Firi via en tredjepartsside eller -applikation.',
  'Credited from a deposit': 'Krediteres fra et indskud',
  'Crypto I earn rewards on': 'Krypto jeg tjener belønninger på',
  'Crypto Talk of the Week': 'Ugens Kryptosnak',
  'Crypto currency tax': 'Kryptoskat',
  'Crypto for fun': 'Krypto for sjov',
  'Crypto for newbies': 'Kryptovalutaer for nybegyndere',
  'Crypto games allow you to earn cryptocurrency, own digital assets, and participate in decision-making within the games.':
    'Krypto-spil giver dig mulighed for at tjene kryptovaluta, eje digitale aktiver og deltage i beslutningstagning inden for spillene.',
  'Crypto investor novice': 'Nybegynder i kryptoinvestering',
  'Crypto news': 'Krypto nyheder',
  'Crypto saving': 'Krypto opsparing',
  'Crypto savings with automatic purchases of crypto. This is suitable for those who want to invest in cryptocurrency for the long-term.':
    'Krypto opsparing med automatisk køb af krypto. Dette passer til dig, der ønsker at investere langsigtet i kryptovaluta.',
  'Crypto tax': 'Skat på krypto',
  'Crypto tax has never been easier': 'Skat på krypto har aldrig været nemmere',
  'Crypto that are used in DeFi projects': 'Kryptovalutaer, som bruges i DeFi-projekter',
  'Crypto that can be mined': 'Kryptovalutaer, som kan mines',
  'Crypto that earns rewards': 'Krypto, der giver belønninger',
  'Crypto that follows the dollar value': 'Kryptovalutaer, som følger værdien af dollar',
  'Crypto that newbies choose': 'Kryptovalutaer som nybegyndere vælger',
  'Crypto trading is a significant source of income for me':
    'Handel med kryptovaluta er en væsentlig indtægtskilde for mig',
  'Crypto withdrawal is unavailable until we have received information and processed the case. This is standard practice.':
    'Udtræk af krypto er ikke tilgængelig, før vi har modtaget oplysninger og behandlet sagen. Dette er standard praksis.',
  "Crypto withdrawal is unavailable until we have received your information and processed the case. This is standard practice, and we will do our utmost to make sure that you can use all of Firi's services as soon as possible.":
    'Udtag af kryptovaluta vil ikke være tilgængelig før vi har modtaget information fra dig og har færdigbehandlet sagen. Dette er normal praksis og vi vil gøre vores bedste for at du, så hurtigt som muligt, kan bruge alle funktioner hos Firi igen.',
  'Crypto you can earn more on': 'Kryptovalutaer, hvor du kan tjene mere',
  Cryptocurrency: 'Kryptovaluta',
  'Cryptocurrency and prices': 'Kryptovaluta og priser',
  'Cryptocurrency is a digital currency built on blockchain technology.':
    'Kryptovaluta er en digital valuta, der er bygget på blockchain-teknologi.',
  'Cryptocurrency:': 'Kryptovaluta',
  Currencies: 'Valutaer',
  Currency: 'Valuta',
  'Current deposit method': 'Nuværende indskudsmetode',
  'Current fortune': 'Nuværende formue',
  'Current income': 'Nuværende indkomst',
  'Current loss': 'Nuværende tab',
  'Current payment method': 'Nuværende betalingsmetode',
  'Current profit': 'Nuværende overskud',
  'Current tax calculation': 'Foreløbig skatteberegning',
  'Current tax calculation for {0}': ['Foreløbig skatteberegning for ', ['0']],
  'Current tax report is not available':
    'Foreløbig skatteberegning er ikke tilgængelig i øjeblikket',
  'Current {0}': ['Nuværende ', ['0']],
  'Current {0} price': ['Nuværende ', ['0'], ' pris'],
  Customize: 'Tilpas',
  'Customize cookies': 'Tilpas cookies',
  'Customize preferences': 'Tilpas præferencer',
  'Customize settings': 'Ændre indstillinger',
  'DAI can be used in a number of decentralized applications, and is mainly used to borrow and lend cryptocurrency without the need for an intermediary.':
    'DAI kan bruges i en række decentrale applikationer, og bruges hovedsageligt til at låne og udlåne kryptovaluta uden behov for en mellemmand.',
  'DAI is a so-called stable cryptocurrency that has the purpose of keeping the same 1:1 value of a US dollar.':
    'DAI er en såkaldt stabil kryptovaluta, der har til formål at holde den samme 1:1 værdi af en amerikansk dollar.',
  Daily: 'Daglig',
  Danish: 'Dansk',
  Dark: 'Mørk',
  'Dark mode': 'Mørk tilstand',
  Date: 'Dato',
  Days: 'Dage',
  DeFi: 'DeFi',
  'Deactivate staking': 'Deaktiver staking',
  'Deactivate staking on': 'Deaktiver staking på',
  'Deactivation registered': 'Deaktivering registreret',
  'Dear {0}! {1} has given you a gift card from Firi 💙 {2}Redeem the gift card by following the link below. {3}We at Firi hope this gift in cryptocurrency never stops giving! {4}{landingUrl}/giftcard/?code={5}':
    [
      'Kære ',
      ['0'],
      '! ',
      ['1'],
      ' har givet dig et gavekort fra Firi 💙 ',
      ['2'],
      'Indløs gavekortet ved at følge linket nedenfor. ',
      ['3'],
      'Vi hos Firi håber, at denne gave i kryptovaluta aldrig holder op med at give! ',
      ['4'],
      ['landingUrl'],
      '/giftcard/?code=',
      ['5'],
    ],
  'Decentralized finance': 'Decentraliseret finans',
  'Decline everything': 'Afvis alle',
  'Dedicated crypto investor': 'Dedikeret kryptoinvestor',
  'Deductible loss': 'Fradragsberettiget tab',
  'Delete account': 'Slet konto',
  'Delete file': 'Slet fil',
  Denmark: 'Danmark',
  Deposit: 'Indskud',
  'Deposit completed!': 'Indbetaling gennemført!',
  'Deposit confirmed': 'Indskud bekræftet',
  'Deposit crypto': 'Indbetal krypto',
  'Deposit from bank': 'Indskud fra bank',
  'Deposit money': 'Indsæt penge',
  'Deposit of {0} not available': ['Indskud af ', ['0'], ' utilgængelig'],
  'Deposit of {currency} not available': ['Indskud af ', ['currency'], ' utilgængelig'],
  'Deposit on': 'Indskud på',
  'Deposit over {0} {currency} into your Firi account.': [
    'Indbetal over ',
    ['0'],
    ' ',
    ['currency'],
    ' på din Firi-konto.',
  ],
  'Deposit registered': 'Indbetaling registreret',
  'Deposit with Apple Pay': 'Indbetaling med Apple Pay',
  'Deposit with Google Pay': 'Indbetaling med Google Pay',
  'Deposit with MobilePay': 'Indbetaling med MobilePay',
  'Deposit with Vipps': 'Indbetaling med Vipps',
  'Deposit with card': 'Indbetaling med kort',
  'Deposit {0}': ['Indsæt ', ['0']],
  Deposits: 'Indskud',
  'Deposits from online banking or Open Banking are free. It usually takes 1-2 banking days before you receive the money.':
    'Indskud fra netbanken eller Open Banking er gratis. Det tager normalt 1-2 arbejdsdage før du modtager pengene.',
  'Deposits to your savings account are automatically converted to cryptocurrency through Firi':
    'Indskud på din opsparingskonto konverteres automatisk til kryptovaluta gennem Firi',
  Destination: 'Destination',
  'Destination Tag': 'Destination Tag',
  'Destination tag': 'Destination tag',
  'Detail on your purchases': 'Detaljer om dine køb',
  Details: 'Detaljer',
  'Details about your order': 'Detaljer om din ordre',
  Development: 'Udvikling',
  'Did not receive the email? Feel free to check the spam as well, sometimes it hides there.':
    'Modtog du ikke e-mailen? Tjek også spam, nogle gange gemmer den sig der.',
  'Did you know that the word staking comes from what are called proof-of-stake blockchain? Ethereum and Cardano are examples of such blockchains.':
    'Vidste du, at ordet staking kommer fra det, der kaldes proof-of-stake-blockchains? Ethereum og Cardano er eksempler på sådanne blockchains.',
  'Did you know that you can earn rewards when you shop and learn more about crypto?':
    'Vidste du, at du kan optjene belønninger, når du handler og lærer mere om krypto?',
  'Did you start staking this week? You will then receive the reward next week. Do you want us to notify you?':
    'Har du startet din staking i denne uge? Så får du din belønning i næste uge. Ønsker du vi sender dig besked?',
  'Did you start staking this week? You will then receive the reward next week. We will let you know!':
    'Har du startet din staking i denne uge?  Så får du din belønning i næste uge. Vi giver dig besked!',
  'Direct deposit': 'Direkte indbetaling',
  'Direct deposit fee is required to cover our costs.':
    'Direkte indskud er påkrævet for at dække vores omkostninger.',
  'Direct purchase': 'Direkte køb',
  Disable: 'Deaktiver',
  'Disable two-factor': 'Deaktiver to-faktor',
  Distribution: 'Fordeling',
  'Distribution is too low': 'Fordelingen er for lav',
  'Do you have an affiliate code?': 'Har du en invitationskode?',
  'Do you trade crypto on other exchanges/platforms besides Firi?':
    'Handler du med krypto på andre børser/platforme end Firi?',
  'Do you want to earn more on your crypto?': 'Vil du tjene mere på din krypto?',
  'Do you want to include {0} when we show the value of your holdings?': [
    'Vil du inkludere ',
    ['0'],
    ', når vi viser værdien af dine beholdninger?',
  ],
  'Do you want to log out of Firi?': 'Vil du logge ud af Firi?',
  'Do you want to make this easier?': 'Vil du gøre det lettere?',
  'Do you want to receive notifications about your account activities, new products and the latest crypto news?':
    'Ønsker du at modtage notifikation om dine kontoaktiviteter, nye produkter og de seneste kryptonyheder?',
  'Do you want to start earn passive rewards? Buy Solana (SOL) and stake directly in the app - everything in one place!':
    'Ventetiden er forbi – endelig kan du stake dine Solana (SOL) hos Firi!',
  'Do you want us to let you know when the hatch opens?':
    'Skal vi give dig besked, når lågen åbnes?',
  'Do you want us to notify you when the transfer is completed?':
    'Vil du have, at vi giver dig besked, når transaktionen er gennemført?',
  Documentation: 'Dokumentation',
  'Does Firi have any shortcomings today, or is there something we can do better?':
    'Har Firi nogle mangler i dag, eller er der noget, vi kan gøre bedre?',
  'Dogecoin (DOGE) was launched in 2013 and started as a humorous internet phenomenon. It is the leading cryptocurrency in the category of "meme coins" and is known for its logo featuring the Shiba Inu dog from the "Doge" meme.':
    'Dogecoin (DOGE) blev lanceret i 2013 og startede som en humoristisk internet-hype. Det er den førende kryptovaluta indenfor kategorien “meme-coins” og er kendt for sit logo af Shiba Inu hunden fra meme-en "Doge".  ',
  'Dogecoin network': 'Dogecoin-netværket',
  "Don't have a user?": 'Har du ikke en bruger?',
  "Don't have an account": 'Har du ikke en konto',
  Donate: 'Donér',
  Donation: 'Donation',
  Done: 'Færdig',
  'Done!': 'Færdig!',
  'Don’t miss our new features': 'Opdater for seneste version',
  'Double bonus when you invite a friend ✨': 'Dobbelt bonus når du inviterer en ven ✨',
  'Dowload app in App Store or Google Play': 'Download appen i App Store eller Google Play',
  Download: 'Download',
  'Download 2FA-app': 'Download 2FA-app',
  'Download Google Authenticator': 'Download Google Authenticator',
  'Download and save “Skattemelding 2023.pdf”': 'Download og gem “Skattemelding 2023.pdf”',
  'Download failed': 'Download fejlede',
  'Download here': 'Download her',
  'Download our app': 'Download vores app',
  'Download our free app and start your crypto journey, safe and easy.':
    'Download vores gratis app og start din kryptorejse, trygt og enkelt.',
  'Download tax attachment': 'Hent skattebilag',
  'Download tax documentation': 'Hent dokumentation til SKAT',
  'Download the Firi-app on mobile': 'Download Firi-appen på mobilen',
  'Download the Firi-app to get started and get {referralAmount} {0} to buy your first cryptocurrency with Firi and start your investments.':
    [
      'Download Firi-appen for at komme i gang og få ',
      ['referralAmount'],
      ' ',
      ['0'],
      ' for at købe din første kryptovaluta med Firi og starte dine investeringer.',
    ],
  'Download the Firi-app!': 'Hent Firi-appen nu!',
  'Download the app and register easily with Vipps.':
    'Download appen og tilmeld dig nemt hos MobilePay.',
  'Download the app now': 'Download appen nu',
  'Download the necessary attachments from the tax calculator. You will find all the documents you need as pdf files.':
    'Download de nødvendige vedhæftede filer fra skatteberegneren. Du finder alle de dokumenter, du skal bruge, som pdf-filer.',
  'Download the new version of the app': 'Download den nye version af appen',
  'Downloadable content': 'Indhold, der kan downloades',
  'Downloads are also available on web': 'Downloads er også tilgængelige på nettet',
  'Drag & drop or': 'Træk og slip eller',
  'Earn crypto rewards by being active, such as depositing money or making your first purchase.':
    'Tjen kryptobelønninger ved at være aktiv, for eksempel ved at indsætte penge eller gennemføre dit første køb.',
  'Earn even more on your crypto': 'Tjen endnu mere på din krypto',
  'Earn more on your crypto': 'Tjen mere på din krypto',
  'Earn more on your crypto with staking': 'Tjen mere på din krypto med staking',
  'Earn rewards': 'Optjen belønning',
  'Earn rewards by staking your crypto': 'Optjen belønninger ved at stake din krypto',
  'Earn rewards now': 'Optjen belønninger nu',
  'Earn rewards on': 'Optjen belønninger på',
  'Earn rewards on {0}': ['Optjen belønning på ', ['0']],
  'Earn rewards with Firi Fordel': 'Optjen belønninger med Firi Fordel',
  'Earn tickets': 'Saml lodder',
  'Earn up to <0>{0}%</0> interest on your SOL.': [
    'Få op til <0>',
    ['0'],
    '%</0> afkast på dine SOL',
  ],
  'Earned rewards': 'Optjente belønninger',
  'Easter egg hunt 2022': 'Påskeægsjagt 2022',
  'Easy overview of your entire portfolio in one place.':
    'Let oversigt over din samlede portefølje på et sted.',
  Edit: 'Rediger',
  'Edit permissions': 'Rediger tilladelser',
  'Edit purchase price': 'Rediger købspris',
  Email: 'E-mail',
  'Email address': 'Email adresse',
  'Email sent': 'E-mail sendt',
  'Enable notifications': 'Aktivér notifikationer',
  'End staking whenever you want - without hassle.': 'Afslut stakingen når som helst!',
  English: 'Engelsk',
  'Enter gift card code:': 'Indtast gavekortkode:',
  'Enter or scan the address of the external wallet to which you want to send {currencyName} ({currency}).':
    [
      'Indtast eller scan adressen på den eksterne wallet, som du vil sende ',
      ['currencyName'],
      ' (',
      ['currency'],
      ') til.',
    ],
  'Enter the code generated by your authenticator application':
    'Indtast den kode, der er genereret af din autentificeringsapplikation',
  'Enter the price for 1 {0} at the time of the purchase': [
    'Indtast prisen for 1 ',
    ['0'],
    ' på købstidspunktet',
  ],
  'Enter the price for {0} {1} at the time of the purchase': [
    'Indtast prisen for ',
    ['0'],
    ' ',
    ['1'],
    ' på købstidspunktet',
  ],
  'Enter the value <0/> in box {0}': ['Indtast værdien <0/> i rubrik ', ['0']],
  'Enter the value <0>{0} kr</0> in box 20.': [
    'Indtast værdien <0>',
    ['0'],
    ' kr</0> i rubrik 20.',
  ],
  'Enter the value <0>{0} kr</0> in box 58.': ['Indtast værdien <0>', ['0'], ' kr</0> i rubrik 58'],
  'Enter you social security number': 'Oppgi dit CPR nr',
  'Enter your CPR number': 'Oppgi dit CPR nr',
  'Enter your email address below and we will help you reset your password.':
    'Indtast din e-mailadresse nedenfor, så hjælper vi dig med at nulstille din adgangskode.',
  'Enter your total gains and income.': 'Skriv dine samlede gevinster og indkomst',
  'Enter your total losses.': 'Skriv dine samlede tab.',
  'Error message:': 'Fejl besked',
  'Essential information': 'Vigtig information',
  'Essentially, cryptocurrency is decentralized and digital money or assets that can be used by anyone and sent freely and directly between individuals.':
    'Essentielt er kryptovalutaer decentraliserede og digitale penge eller aktiver, der kan bruges af alle og sendes frit og direkte mellem personer.',
  'Estimated staking value': 'Estimeret stakingværdi',
  'Estimated time for withdraw': 'Estimeret tid for udbetaling',
  'Estimated time for withdraw: {time}': ['Estimeret tid for udbetaling: ', ['time']],
  'Estimated value': 'Anslået værdi',
  'Ethereum is a protocol built on blockchain technology which is also best known for its ten-digit cryptocurrency called ETH, Ether or Ethereum.':
    'Ethereum er en protokol bygget på blockchain-teknologi, som også er bedst kendt for sin ti-cifrede kryptovaluta kaldet ETH, Ether eller Ethereum.',
  'Ethereum is also a so-called smart-contract platform with its own programming language that enables people to create decentralized applications on the Ethereum blockchain.':
    'Ethereum er også en såkaldt smart-kontrakt platform med sit eget programmeringssprog, der gør folk i stand til at skabe decentrale applikationer på Ethereum blockchain.',
  'Ethereum network (ERC20)': 'Ethereum-netværket (ERC20)',
  'Even more rewards': 'Endnu flere fordele',
  'Every day': 'Hver dag',
  'Every day from December 1st to 24th, you can win between 500 and 1000 kroner in crypto, or exclusive Firi merch. 🎁 For each correct answer, you get a chance to win the grand prize on Christmas Eve. ✨':
    'Hver dag fra 1. til 24. december kan du vinde mellem 500 og 1000 kroner i krypto eller eksklusiv Firi-merch. 🎁 For hvert rigtigt svar får du en chance for at vinde den store præmie juleaften. ✨',
  'Every friday': 'Hver fredag',
  'Every month': 'Hver måned',
  'Every other week': 'Hver anden uge',
  'Every two weeks': 'Hver 2. uge',
  'Every week': 'Hver uge',
  'Every {0}. day': ['Hver ', ['0'], '. dag'],
  Everything: 'Alt',
  'Example from Google Authenticator': 'Eksempel fra Google Authenticator',
  'Exchange rate': 'Valutakurs',
  'Expected annual yield': 'Forventet årligt afkast',
  'Expected annual yield is an estimate and will vary over time.':
    'Forventet årligt afkast er et skøn og vil variere over tid.',
  'Expected annual yield on staked crypto is an estimate and may vary over time.':
    'Forventet årligt afkast på staked krypto er et skøn og kan variere over tid.',
  'Expected annual yield refers to the increase in value your staked investment is expected to give over time.':
    'Forventet årligt afkast refererer til den værdistigning, din stakede investering forventes at give over tid.',
  'Expected yield per year': 'Forventet afkast pr år',
  Expenses: 'Udgifter',
  'Expenses in your tax return': 'Udgifter i din skattemelding',
  'Expenses/fees': 'Udgifter/gebyrer',
  Expired: 'Udløbet',
  Expires: 'Udløber',
  Explore: 'Udforsk',
  'Export CSV': 'Eksporter CSV',
  'Export account statement': 'Eksporter kontoudtog',
  'Export annual statement': 'Eksporter årsopgørelse',
  'Export your transactions e.g for tax decleration':
    'Eksporter dine transaktioner, fx til skatteangivelse',
  'Extra transfer': 'Ekstra overførsel',
  FAQ: 'FAQ',
  'Facial recognition': 'Ansigtsgenkendelse',
  'Failed to create user': 'Kunne ikke oprette bruger',
  Fastest: 'Hurtigste',
  Favorites: 'Favoritter',
  Fee: 'Gebyr',
  'Fee for direct purchase is 3.9%.': 'Gebyr for direkte køb er 3,9%.',
  'Fee for direct purchase is {0} percent.': ['Gebyr for direkte køb er ', ['0'], ' procent.'],
  'Fee will be added': 'Gebyr tillægges',
  'Feeback bonus': 'Feeback bonus',
  Fees: 'Gebyrer',
  'Fees on trading': 'Gebyr for handel',
  Field: 'Felts',
  Files: 'Filer',
  'Fill in some details to process your latest deposit.':
    'Vi har brug for nogle få oplysninger for at bekræfte, hvor kryptoen kommer fra. Det tager kun et øjeblik!',
  'Fill in the confirmation code': 'Udfyld bekræftelseskoden',
  'Fill in the information in the tax return and attach the documentation that you have received from the tax calculator':
    'Udfyld oplysningerne i selvangivelsen og vedlæg den dokumentation, som du har modtaget fra skatteberegneren',
  'Fill in the tax return correctly': 'Udfyld skattemeldingen korrekt',
  'Fill out your profile': 'Udfyld din profil',
  Filter: 'Filter',
  'Finally...': 'Til sidst...',
  Finance: 'Finans',
  Firi: 'Firi',
  'Firi + Staking = 💙': 'Firi + Staking = 💙',
  'Firi - Nordic largest crypto exchange': 'Firi - Nordens største kryptobørs',
  'Firi AS': 'Firi AS',
  'Firi Affiliate': 'Firi Affiliate',
  'Firi Blue': 'Firi blå',
  'Firi Christmas': 'Firi Jul',
  'Firi Fordel': 'Firi Fordel',
  'Firi Grey': 'Firi Grå',
  'Firi Reward': 'Firi Fordel',
  'Firi Reward gives you the opportunity to earn crypto rewards by completing various tasks, such as:':
    'Firi Fordel giver dig muligheden for at tjene kryptobelønninger ved at fuldføre forskellige opgaver, såsom:',
  'Firi White': 'Firi hvid',
  'Firi current posts': 'Firi aktuelle indlæg',
  'Firi guest author': 'Firi gæsteforfatter',
  "Firi is Norway's largest service for buying and selling cryptocurrencies. With us you will find, among other things, bitcoin, ethereum and cardano. We make the cryptic easy by discovering new and better ways to save, invest and learn about digital currency.":
    'Firi er Nordens største service til køb og salg af kryptovalutaer. Hos os finder du blandt andet bitcoin, ethereum og cardano. Vi gør det kryptiske nemt ved at opdage nye og bedre måder at spare, investere og lære om digital valuta.',
  'Firi is a Norwegian cryptocurrency exchange, registered by Finanstilsynet':
    'Firi er en norsk kryptovalutabørs, registreret hos Finanstilsynet i både Norge og Danmark',
  'Firi is registered as a service provider for exchanging and storing virtual currency in Norway.':
    'Firi er registreret som tjenesteudbyder af virtuel valuta (VASP) i Danmark.',
  'Firi is registered as an exchange with Finanstilsynet, the Financial Supervisory Authority (FSA). We are required to gather information about our users, the same way that banks, real estate agents and the rest of the financial industry must do.':
    'Firi er registreret hos Finanstilsynet i Danmark som udbyder af tjenester med virtuel valuta (VASP).',
  'Firi is registered with Financial Supervisory Authority (FSA) for exchanging and storing virtual currency. Just like banks and other financial institutions, we are required to ask some questions of new customers.':
    'Firi er registreret hos Finanstilsynet og godkendt til at handle med og opbevare virtuel valuta. Ligesom banker og andre finansielle institutioner er vi forpligtet til at stille visse spørgsmål til nye kunder.',
  'Firi is registered with Finanstilsynet': 'Firi er registreret hos Finanstilsynet',
  'Firi is required by law to obtain information about you as a customer and carry out ongoing follow-up of customer relationships. This is stated, among other things, in our <0>terms of use</0> and the <1>privacy policy</1>':
    'Firi er ved lov forpligtet til at indhente oplysninger om dig som kunde og foretage løbende opfølgning på kundeforhold. Dette fremgår blandt andet af vores <0>brugsbetingelser</0> og <1>privatlivspolitikken</1>',
  'Firi takes no responsibility for any loss of money.':
    'Firi tager intet ansvar for tab af penge.',
  'Firi’s website, firi.com, may include links to third-party websites, plug-ins, and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you.':
    'Firis websted, firi.com, kan indeholde links til tredjeparts websteder, plug-ins og applikationer. Hvis du klikker på disse links eller aktiverer disse forbindelser, kan tredjeparter indsamle eller dele data om dig.',
  'First deposit': 'Første indbetaling',
  'First name': 'Fornavn',
  'First saving': 'Første opsparing',
  'First trade': 'Første handel',
  'First, we just need to confirm that you are you with {0}.': [
    'Først skal vi bare bekræfte, at du er dig med ',
    ['0'],
    '.',
  ],
  Flexibility: 'Fleksibelt',
  'Follow system settings': 'Følg systemindstillingerne',
  'For a faster review, double-check that your information is correct.':
    'For en hurtigere gennemgang skal du dobbeltjekke, at dine oplysninger er korrekte.',
  'For security and regulatory reasons, we must confirm the source of the deposit. This helps ensure a safe and legal transfer.':
    'For sikkerhed og overholdelse af reglerne skal vi bekræfte, hvor dit indskud kommer fra. Dette hjælper os med at sikre lovlige og trygge transaktioner.',
  'For security and regulatory reasons, we must confirm where your money is going. This helps ensure a safe and legal transfer.':
    'Af sikkerheds- og reguleringsårsager skal vi bekræfte, hvor dine midler sendes til. Dette hjælper med at sikre en sikker og lovlig overførsel.',
  'Forgot password?': 'Glemt kodeord?',
  Fortune: 'Formue',
  'Free crypto? Yes.': 'Gratis krypto? Ja.',
  From: 'Fra',
  'From (year)': 'Fra (year)',
  'From 2025, you will no longer be able to enter your own purchase price – the tax calculation will instead be based on the market price at the time of deposit.':
    'Fra 2025 vil du ikke længere kunne indtaste din egen købspris – afgiftsberegningen vil i stedet tage udgangspunkt i markedsprisen på indbetalingstidspunktet.',
  'From account': 'Fra konto',
  'From start': 'Fra start',
  'From the next app update we only support iOS 15+. Update your iOS to get the latest updates and the best possible experience.':
    'Fra næste app-opdatering understøtter vi kun iOS 15+. Opdater din iOS for at få de nyeste opdateringer og den bedst mulige oplevelse.',
  'From {0} to {1}': ['Fra ', ['0'], ' til ', ['1']],
  'Funds processing': 'Behandling af midler',
  'Funds under processing': 'Midler under behandling',
  'Gambling without permission': 'Spill uden tilladelse',
  'Gaming & Web3': 'Gaming & Web3',
  'Gaming/Web3': 'Gaming/Web3',
  'Geographic address': 'Geografisk adresse',
  'Get full access to our services and start trading cryptocurrencies today':
    'Få fuld adgang til vores tjenester og begynd at handle med kryptovalutaer i dag',
  'Get notifications on crypto news': 'Få notifikationer om nye kryptonyheder',
  'Get ready for crypto summer!': 'Forbered dig til krypto-sommer!',
  'Get started': 'Kom igang',
  'Get started with MitID on our web platform': 'Kom igang med MitID på web plattformen.',
  'Get started with Vipps on our web platform': 'Kom igang med Vipps på web plattformen.',
  "Get started with Vipps. With Vipps, your profile is automatically filled out. Sign with BankID and you're up and running, safe and easy!":
    'Kom godt i gang med Vipps. Med Vipps udfyldes din profil automatisk. Så skriv under med BankID og du er i gang, sikkert og nemt!',
  'Get started with crypto savings!': 'Kom godt i gang med kryptobesparelser!',
  'Get started with our guide': 'Kom godt i gang med vores guide',
  'Get started with staking': 'Kom i gang med staking',
  'Get up to {0}% expected annual return on crypto stored in this account.': [
    'Få op til ',
    ['0'],
    '% I forventet årligt afkast på krypto, der opbevares på denne konto.',
  ],
  'Get welcome bonus': 'Få velkomstbonus',
  'Get your bonus in app': 'Få din bonus i appen',
  'Get {0} {1} in {payoutCurrencyName} and give your friend {2} {3} in welcome bonus': [
    'Få ',
    ['0'],
    ' ',
    ['1'],
    ' i ',
    ['payoutCurrencyName'],
    ' og giv din ven ',
    ['2'],
    ' ',
    ['3'],
    ' i velkomstbonus.',
  ],
  'Gift card': 'Gavekort',
  'Gift card code': 'Gavekort kode',
  'Gift card created': 'Gavekort oprettet',
  'Gift card is redeemed': 'Gavekortet er indløst',
  'Gift from your main account': 'Giv fra din hovedkonto',
  'Giftcard is redeemed': 'Gavekortet er indløst',
  'Give access to the camera': 'Giv adgang til kamera',
  'Give away crypto you already have. Remember that you must have enough crypto in your main account.':
    'Giv krypto væk, du allerede har. Husk at du skal have nok krypto på hovedkontoen.',
  'Go back': 'Gå tilbage',
  'Go to box 20 (Other personal income). If you have not completed the box before, you will find it under Other income and then Deductions.':
    'Gå til rubrik 20 (Anden personlig indkomst). Hvis du ikke har udfyldt rubrikken før, finder du den under Anden indkomst og dernæst Fradrag.',
  'Go to box 58 (Other expenses). If you do not have box 58 on your annual tax assessment you should contact SKAT and request that it be opened.':
    'Gå til rubrik 58 (Øvrige lønmodtagerudgifter). Hvis du ikke har rubrik 58 på din årsopgørelse, skal du kontakte SKAT og anmode om at få den åbnet.',
  'Go to login': 'Gå til login',
  'Go to my bank accounts': 'Gå til mine bankkonti',
  'Go to your inbox in Altinn': 'Gå til din indbakke i Altinn',
  'Google Pay': 'Google Pay',
  'Google Pay fee': 'Google Pay gebyr',
  'Google Play Store': 'Google Play Store',
  'Google reCAPTCHA is not verified or expired': 'Google reCAPTCHA er ikke bekræftet eller udløbet',
  'Got it!': 'Forstået!',
  'Green crypto': 'Grønne kryptovalutaer',
  'Guest author': 'Gæsteforfatter',
  'Guidance for filling in the tax return': 'Vejledning til udfyldelse af selvangivelsen',
  'Guide to filling out the annual tax assessment': 'Vejledning til udfyldning af årsopgørelsen',
  'Has Firi been a source for cryptocurrency education and information?':
    'Har Firi været en kilde til din oplæring og information om krypto?',
  'Have an account?': 'Har du en konto?',
  'Have you been referred?': 'Er du blevet henvist?',
  'Have you ever wondered what the average price of your investments is? Or if you hit the market at a low price? Now you get this gathered clearly under each coin you own, so you can make better evaluations around your investments.':
    'Har du nogensinde undret dig over, hvad gennemsnitsprisen for dine investeringer er? Eller om du ramte markedet til en lav pris? Dette får du nu samlet overblik over under hver enkelt coin du ejer, så du kan træffe bedre vurderinger omkring dine investeringer',
  'Have you got ideas or thoughts on how we can improve? Take the chance to share your thoughts in our survey. It takes only 5–7 minutes!':
    'Har du idéer eller forslag til, hvordan vi kan forbedre os? Del dine tanker med os – din feedback hjælper os med at skabe en endnu bedre tjeneste til dig. Det tager kun 5-7 minutter!',
  'Have you lost crypto, but are told to enter 0 in losses?':
    'Har du tabt krypto, men bliver bedt om at indtaste 0 i tab?',
  'Have you received a gift card? How nice! Enter the code here:':
    'Har du modtaget et gavekort? Hvor fint! Indtast koden her:',
  'Have you recently done a deposit': 'Har du for nylig foretaget en indbetaling',
  'Hei, {0}!': ['Hej, ', ['0'], '!'],
  'Help med download 2FA-app': 'Hjælp med at downloade 2FA-appen',
  'Help us to give you the best Firi-experience':
    'Hjælp os med at give dig den bedste Firi-oplevelse',
  'Here are some other pages you could try instead:':
    'Her er nogle andre sider, du kan prøve i stedet:',
  "Here we have gathered insightful articles and news, carefully curated. Whether you're an experienced investor or new to crypto, Utforsk provides you with the information you need to stay updated on the latest developments.":
    'Her har vi samlet indsigtfulde artikler og nyheder, som er nøje kurateret. Uanset om du er en erfaren investor eller ny til krypto, giver Udforsk dig informationen, du har brug for, for at holde dig opdateret på alt det seneste',
  'Here you can download documentation for your tax returns':
    'Her kan du downloade dokumentation til din selvangivelse',
  'Here you can download documentation to your tax report':
    'Her kan du downloade dokumentation til din skatteopgørelse',
  'Here you can upload relevant documentation. Your documentation is always safely stored with us.':
    'Her kan du uploade relevant dokumentation. Din dokumentation opbevares altid sikkert hos os.',
  "Here you'll find crypto address you store when sending crypto":
    'Her finder du kryptoadresser, som du gemmer, når du sender krypto',
  "Here's an <0>example</0> of what the 6 digit code might look like in Google Authenticator. Such a code must be copied and pasted into the Firi app or entered manually. Important! The code is updated periodically, so check that you enter the correct code.":
    'Her er et <0>eksempel</0> på, hvordan den 6-cifrede kode kan se ud i Google Authenticator. En sådan kode skal kopieres og indsættes i Firi-appen eller indtastes manuelt. Vigtigt! Koden opdateres med jævne mellemrum, så tjek at du indtaster den korrekte kode.',
  "Hi! I would like to tell you about Firi. I myself use Norway's largest cryptocurrency exchange to invest in the currency of the future. Now you get up to 265 {0} in bitcoin, ethereum and other cryptocurrencies to test out Firi through my invitation. What are you waiting for?":
    [
      'Hej! Jeg vil gerne fortælle dig om Firi. Jeg bruger selv Nordens største kryptovalutabørs til at investere i fremtidens valuta. Nu får du op til 265 ',
      ['0'],
      ' i bitcoin, ethereum og andre kryptovalutaer for at teste Firi gennem min invitation. Hvad venter du på?',
    ],
  'Hide other pairs': 'Skjul andre par',
  'High demand': 'Høj efterspørgsel',
  'High level of knowledge': 'I høj grad',
  'Highest (24h)': 'Højest (24h)',
  'Highest price': 'Højeste pris',
  'Highest value': 'Højeste værdi',
  'Historic yield': 'Historisk udbytte',
  'Hm, no question found. Try to start over': 'Hm, ingen spørgsmål fundet. Prøv at starte forfra',
  'Ho ho, you got it right!': 'Hoho, du var god!',
  'Ho, nooo!': 'Ho, nooo!',
  'Ho-ho-hodl be ready for this Christmas biggest crypto happening!':
    'Ho-ho-hodl dig klar til julens store krypto-glæde!',
  Holding: 'Beholdning',
  Holdings: 'Midler',
  Home: 'Hjem',
  'Hope you like our new update!': 'Vi håber, at I kan lide opdateringen!',
  'House number': 'Husnummer',
  'How are you doing this year?': 'Hvordan går det i år?',
  'How do I get stared?': 'Hvordan kommer jeg i gang?',
  'How do I stake?': 'Hvordan staker jeg?',
  'How do I stay current on crypto?': 'Hvordan holder jeg mig opdateret om krypto?',
  'How do I trade crypto with Firi?': 'Hvordan handler jeg krypto hos Firi?',
  'How do i stake {currency}?': ['Hvordan staker jeg ', ['currency'], '?'],
  'How do you want to buy cryptocurrency?': 'Hvordan vil du købe kryptovaluta?',
  'How do you want to deposit money?': 'Hvordan vil du indbetale penge?',
  'How do you want to get started?': 'Hvordan vil du gerne starte?',
  'How do you want to gift crypto?': 'Hvordan vil du give krypto?',
  'How do you want to stake {currency}?': ['Hvordan vil du gerne stake ', ['currency'], '?'],
  'How do you want to stake?': 'Hvordan vil du gerne stake?',
  'How does it work': 'Hvordan virker det',
  'How is the exchange rate determined?': 'Hvordan fastsættes vekslingskursen?',
  'How much do you want to transfer?': 'Hvor meget vil du overføre?',
  'How nice!': 'Hvor fint!',
  'How nice! You have received a gift in crypto': 'Hvor fint! Du har modtaget en gave i krypto',
  'How often do you want to save?': 'Hvor ofte vil du spare?',
  'How the amount is deduced': 'Hvordan beløbet trækkes',
  'How to deactivate staking': 'Hvordan deaktivere staking',
  'How to enter gains and income in box 20:': 'Sådan skriver du gevinster og indkomst i rubrik 20:',
  'How to enter losses in box 58:': 'Sådan skriver du tab i rubrik 58:',
  'How to invite friends?': 'Hvordan hverver du?',
  'How to report the amount': 'Hvordan indrapporterer du beløbet',
  'How to set up the savings agreement in your bank':
    'Sådan opretter du opsparingsaftalen i din bank',
  'How to understand rewards and development': 'Hvordan forstås belønninger og udvikling',
  'How we process your personal information': 'Hvordan vi behandler dine personoplysninger',
  'I accept': 'Jeg godkender',
  'I already have a 2FA-app': 'Jeg har allerede en 2FA-app',
  'I am buying': 'Jeg køber',
  'I am buying for': 'Jeg køber for',
  'I am selling': 'Jeg sælger',
  'I am selling for': 'Jeg sælger for',
  'I am sending': 'Jeg sender',
  'I am uncertain about the future of crypto': 'Jeg er usikker på fremtiden for krypto',
  'I confirm that all information about the sender is correct.':
    'Jeg bekræfter, at alle oplysninger om afsenderen er korrekte.',
  "I didn't quite understand, can I find more?": 'Jeg forstod det ikke helt, kan jeg finde mere?',
  "I don't have the capital available to invest more":
    'Jeg har ikke kapital til rådighed til at investere mere',
  "I don't want to upload documentation": 'Jeg ønsker ikke at uploade dokumentation',
  'I have an interest in blockchain technology': 'Jeg har en interesse i blockchain-teknologi',
  'I have created a recurring transfer from my bank':
    'Jeg har oprettet en tilbagevendende overførsel fra min bank',
  'I have downloaded a 2FA-app': 'Jeg har downloadet en 2FA-app',
  'I have earned': 'Jeg har tjent',
  "I have read and accept Firi's <0>Terms of Services</0>.":
    'Jeg har læst og accepterer Firis <0>Servicevilkår</0>.',
  'I have read and agree to the': 'Jeg har læst og accepterer',
  'I have read the <0>Privacy Policy</0> and give Firi my consent to process my personal information.':
    'Jeg har læst <0>Privatlivspolitikken</0> og giver Firi mit samtykke til at behandle mine personoplysninger.',
  'I have read the <0>privacy policy</0> and give my consent to store the information specified in the policy':
    'Jeg har læst <0>privatlivspolitikken</0> og giver mit samtykke til at gemme de oplysninger, der er angivet i politikken',
  'I have referred': 'Jeg har inviteret',
  'I have sufficient exposure to crypto now':
    'Jeg tror, ​​jeg har passende eksponering i krypto på nuværende tidspunkt',
  'I lack sufficient knowledge to choose / it is overwhelming':
    'Jeg mangler tilstrækkelig viden til at vælge / det er overvældende',
  'I perceive crypto as a risky investment': 'Jeg ser krypto som en risikabel investering',
  'I understand': 'Ok',
  "I understand that Firi can't calculate tax on crypto that I send away.":
    'Jeg forstår, at Firi ikke kan beregne den korrekte skat på krypto, som jeg sender ud.',
  'I understand that investing in meme coins involves high risk and that I am responsible for my own investments.':
    'Jeg forstår, at investering i meme-coins indebærer høj risiko, og at jeg selv er ansvarlig for mine investeringer.',
  'I want to gain short-term profits': 'Jeg vil have hurtig profit',
  'I want to join!': 'Jeg vil være med!',
  'I want to know more': 'Jeg vil gerne vide mere',
  'I want to learn about crypto': 'Jeg vil gerne lære om krypto',
  'I want to save in crypto (alongside savings account/funds/stocks)':
    'Jeg vil opspare i krypto (sideløbende med opsparingskonto/fonde/aktier)',
  'If the error is still occurs, please contact our support team':
    'Hvis fejlen stadig opstår, bedes du kontakte vores supportteam',
  "If we don't have your bank": 'Hvis din bank ikke er på listen',
  'If you are already a user, continue login in your market':
    'Hvis du allerede er bruger, skal du fortsætte med at logge ind på dit marked',
  'If you believe that our processing of personal information does not match what we have described here or in other ways violate privacy laws, you may appeal to The Norwegian Data Protection Authority.':
    'Hvis du mener, at vores behandling af personoplysninger ikke stemmer overens med det vi har beskrevet her, eller på anden måde overtræder privatlivsloven, kan du klage til Datatilsynet.',
  'If you choose to buy for more then the size of the cheapest order, you will automatically buy from the next order in the queue. That is why a trade can consist of several orders.':
    'Vælger du at købe for mere end størrelsen på den billigste ordre, køber du automatisk fra næste ordre i køen. Derfor kan en handel bestå af flere ordrer.',
  'If you choose to sell for more then the cheapest order, you will automatically sell from the next order in the queue. That is why a trade can consist of several orders.':
    'Vælger du at sælge for mere end den billigste ordre, sælger du automatisk fra næste ordre i køen. Derfor kan en handel bestå af flere ordrer.',
  'If you do not want to receive notifications about the calendar, you can easily turn this off under settings':
    'Du kan nemt slå kalender-notifikationer fra under indstillinger i din profil.',
  'If you do not want to use 2FA, we recommend that you register with Vipps instead.':
    'Hvis du ikke ønsker at bruge 2FA, anbefaler vi, at du i stedet registrerer dig hos Vipps.',
  'If you have a referral code you can enter it here':
    'Hvis du har en invitationskode kan du indtaste den her',
  'If you have recently made a deposit, we might still be in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit.':
    'Hvis du for nylig har foretaget en indbetaling, er vi muligvis stadig i gang med at modtage de seneste oplysninger fra din bank. Vi modtager normalt disse oplysninger en hverdag efter indbetalingen.',
  'If you want documentation of your tax calculation, you can download it by clicking "Download" in the upper right corner of our tax calculation page.':
    'Hvis du ønsker dokumentation på din skatteberegning, så kan du downloade denne hvis du klikker på “download” i højre hjørne på skatteberegningssiden vores.',
  'If you want to download documentation for the tax return from Firi, you can do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.':
    'Ønsker du at downloade dokumentation til selvangivelsen fra Firi, kan du gøre dette ved at klikke på knappen "Download" øverst til højre på skatteberegningssiden. Skattemyndighederne kræver, at du indsender dette sammen med selvangivelsen.',
  'If you want to download documentation for the tax return from Firi, you do this by clicking on the "Download" button at the top right of the tax calculation page. The tax authorities require you to submit this together with the tax return.':
    'Ønsker du at downloade dokumentation til selvangivelsen fra Firi, gør du dette ved at klikke på knappen "Download" øverst til højre på skatteberegningssiden. Skattemyndighederne kræver, at du indsender dette sammen med selvangivelsen.',
  'If you want to save and print the gift card, follow the link that you have received by e-mail.':
    'Vil du gemme og printe gavekortet? Følg det link, du har modtaget på e-mail.',
  'If you want to use Firis crypto tax, you need to enter the price you purchased this crypto for. The purchase price can be found at the service you used.':
    'Hvis du vil bruge Firis kryptoskat, skal du indtaste den pris, du købte denne krypto for. Købsprisen kan findes på den tjeneste, du brugte.',
  'If you withdraw your consent to store your BankID information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?':
    'Hvis du trækker dit samtykke til at opbevare dine MitID-oplysninger tilbage, bliver din konto\nnedgraderet til en ubekræftet konto, og du kan ikke længere handle eller foretage transaktioner.\nDe lagrede oplysninger vil blive slettet uden unødig forsinkelse i overensstemmelse med vores lovgivning\nforpligtelser. Er du sikker?',
  'If you withdraw your consent to store your KYC-form information your account will be\ndowngraded to an unverified account and you can no longer trade or make transactions.\nThe stored information will be deleted without undue delay in accordance with our legal\nobligations. Are you sure?':
    'Hvis du trækker dit samtykke til at gemme dine KYC-formularoplysninger tilbage, bliver din konto\nnedgraderet til en ubekræftet konto, og du kan ikke længere handle eller foretage transaktioner.\nDe lagrede oplysninger vil blive slettet uden unødig forsinkelse i overensstemmelse med vores lovgivning\nforpligtelser. Er du sikker?',
  'If you withdraw your consent to store your profile information your account will be\nsuspended as we require this information to provide you services. The stored information\nwill be deleted without undue delay in accordance with our legal obligations. Are you\nsure?':
    'Hvis du trækker dit samtykke til at gemme dine profiloplysninger tilbage, bliver din konto\nsuspenderet, da vi kræver disse oplysninger for at kunne levere tjenester til dig. De lagrede oplysninger\nvil blive slettet uden unødig forsinkelse i overensstemmelse med vores juridiske forpligtelser. Er du\njo da?',
  Images: 'Billeder',
  Immediately: 'Øjeblikkelig',
  'Important information': 'Vigtig information',
  'Important information!': 'Vigtig information!',
  'Important regarding tax before you send crypto': 'Vigtigt om skat, inden du sender krypto',
  'In order to meet the requirements of the authorities, we must also verify your identity with facial recognition. Thank you for helping us secure your account!':
    'For at opfylde myndighedernes krav skal vi også verificere din identitet med ansigtsgenkendelse. Tak for at du hjælper os med at sikre din konto!',
  'In some cases a withdrawal may require longer processing time and it will be labeled "requested" in your overview until it has been sent.':
    'I nogle tilfælde kan en fortrydelse kræve længere behandlingstid, og den vil blive mærket "anmodet om" i din oversigt, indtil den er afsendt.',
  'In the tax return field': 'I selvangivelsesfeltet',
  'In the tax return field<0> Capital value </0> enter the value <1>{0}kr</1>.': [
    'Indtast værdien <1>',
    ['0'],
    'kr</1> i selvangivelsesfeltet<0> Kapitalværdi </0>.',
  ],
  'In the tax return field<0> Deductible loss </0> enter the value <1>{0} kr</1>.': [
    'Indtast værdien <1>',
    ['0'],
    ' kr</1> i selvangivelsesfeltet<0> Fradragsberettiget tab </0>.',
  ],
  'In the tax return field<0> Other income from virtual assets / crypto currency </0> enter the value <1>{0} kr</1>.':
    [
      'Indtast værdien <1>',
      ['0'],
      ' kr</1> i selvangivelsesfeltet <0> Andre indtægter fra virtuelle ejendele/kryptovaluta </0>.',
    ],
  'In the tax return field<0> Taxable gain </0> enter the value <1>{0} kr</1>.': [
    'Indtast værdien <1>',
    ['0'],
    ' kr</1> i selvangivelsesfeltet<0> Skattepligtig gevinst </0>.',
  ],
  'In your tax return, find the topic "Finance" and the item "Management costs". In the "Securities management" field, enter the amount of {0} {1}':
    [
      'På din selvangivelse finder du emnet "Økonomi" og punktet "Administrationsomkostninger". Indtast beløbet på ',
      ['0'],
      ' ',
      ['1'],
      ' i feltet Værdipapiradministration',
    ],
  'Include {0} in balance': ['Inkluder ', ['0'], ' i saldo'],
  Income: 'Indkomst',
  Incoming: 'Indkommende',
  'Info required': 'Info påkrævet',
  Insight: 'Indsigt',
  'Insufficient funds': 'Ikke nok penge',
  'Insured with Firi': 'Forsikret hos Firi',
  'Internal transfer': 'Intern overførsel',
  'Introducing the gaming/web3 category!': 'Introducerer kategorien gaming/web3!',
  'Invalid account number': 'Ugyldigt kontonummer',
  'Invalid address': 'Ugyldig adresse',
  'Invalid code': 'Ugyldigt kode',
  'Invalid distribution': 'Ugyldig fordeling',
  'Invalid email': 'Ugyldig email',
  'Invalid referral code': 'Ugyldig invitationskode',
  'Invalid session': 'Ugyldig session',
  'Invalid tag': 'Ugyldig tag',
  'Invalid verification code': 'Ugyldig bekræftelseskode',
  'Invalid {0} address': ['Ugyldig ', ['0'], ' adresse'],
  'Invalid {currencyName} address': ['Ugyldig ', ['currencyName'], ' adresse'],
  Invest: 'Invester',
  "Invest a fixed amount in cryptocurrency at a fixed time with a direct debit from your bank. We'll take care of the rest!":
    'Invester et fast beløb i kryptovaluta på et fast tidspunkt med direkte debitering fra din bank. Vi tager os af resten!',
  'Invest in crypto': 'Invester i krypto',
  'Invest in crypto now': 'Invester i krypto nu',
  'Invest in crypto whenever you want': 'Invester i krypto, når du vil',
  'Invest in {currency}': ['Invester i ', ['currency']],
  'Invite friends': 'Inviter venner',
  'Invite friends and receive {0} {referralCurrency} in {payoutCurrencyName} for each friend who verifies their account. Whoever you invite gets a welcome bonus of {1} {referralCurrency}.':
    [
      'Hverv venner og modtag ',
      ['0'],
      ' ',
      ['referralCurrency'],
      ' i ',
      ['payoutCurrencyName'],
      ' for hver ven, der verificerer sin konto. Den, du hverver, får ',
      ['1'],
      ' ',
      ['referralCurrency'],
      ' i velkomstbonus.',
    ],
  'Invite friends to Firi': 'Inviter venner til Firi',
  'Invite friends to Firi and earn bitcoin ⭐': 'Inviter venner til Firi og tjen bitcoin ⭐️',
  'Invite with link': 'Inviter med link',
  'Is it you who is depositing the crypto?': 'Er det dig, der indsætter kryptoen?',
  'Is my crypto safe with Firi?': 'Er min krypto sikker hos Firi?',
  'Is there a high risk of significant loss when investing in meme coins?':
    'Er der en høj risiko for betydelige tab, når du investerer i meme-coins?',
  'Is there something that does not seem right here? Contact our support on chat!':
    'Er der noget, der ikke virker rigtigt her? Kontakt vores support på chat!',
  'It can take up to 1-2 days from the amount being deducted from your bank until the cryptocurrency is visible in your savings account.':
    'Der kan gå op til 1-2 dage fra beløbet er trukket i din bank, til kryptovalutaen er synlig på din opsparingskonto.',
  'It is currently high demand and few that wishes to sell {currency}. To avoid risking you buy for an expensive price, we want to inform you that the price differentiate approx. <0>{absolutePercent}%</0> in comparison to the international price index.':
    [
      'Det er i øjeblikket stor efterspørgsel og meget få som ønsker at sælge ',
      ['currency'],
      '. For at undgå, at du køber for en dyr pris, vil vi oplyse dig om, at prisen differentierer ca. <0>',
      ['absolutePercent'],
      '%</0> sammenlignet med det internationale prisindeks.',
    ],
  'It is currently low demand and few that wishes to buy {currency}. To avoid risking you selling for a cheap price, we want to inform you that the price differentiate approx. <0>{absolutePercent}%</0> in comparison to the international price index.':
    [
      'Det er i øjeblikket lav efterspørgsel og få, der ønsker at købe ',
      ['currency'],
      '. For at undgå, at du sælger til en for lav pris, vil vi oplyse dig om, at prisen adskiller ca. <0>',
      ['absolutePercent'],
      '%</0> sammenlignet med det internationale prisindeks.',
    ],
  'It is free to withdraw funds from your staking account. Remember that the earning of rewards will stop if you deactivate staking.':
    'Det er gratis at foretage en udbetaling fra staking-kontoen. Husk, at optjeningen af belønning stopper, hvis du deaktiverer staking',
  'It is important to us that your funds are safely stored. In addition to using the best and latest technology for storing cryptocurrencies, your funds are also insured with us.':
    'Det er vigtigt for os, at dine penge opbevares sikkert. Udover at vi bruger den bedste og nyeste teknologi til opbevaring af kryptovalutaer, er dine penge også forsikret hos os.',
  'It is not possible to change or recover passwords if you\nregistered with Vipps. This is because a password for your\nuser was never registered.':
    'Det er ikke muligt at ændre eller gendanne adgangskoder, hvis du registrerede dig hos Vipps. Dette skyldes, at en adgangskode til din ruger aldrig blev registreret.',
  'It is now easier to invite friends and acquaintances to Firi! We have removed the "Firi ambassador" concept and simplified the affiliate program. Earn {0} {referralCurrency} for each friend you invite. No limit on the number of invites as long as you follow the guidelines.':
    [
      'Nu er det nemmere at hverve venner og bekendte til Firi! Vi har fjernet "Firi ambassadør"-konceptet og forenklet hverveprogrammet. Tjen ',
      ['0'],
      ' ',
      ['referralCurrency'],
      ' for hver ven, du hverver. Ingen grænse på antallet af hvervninger, så længe du følger retningslinjerne.',
    ],
  'It only takes a moment - we really appreciate your help!':
    'Det tager kun et øjeblik – vi sætter stor pris på din hjælp!',
  'It usually takes 1-2 banking days until the money arrives in your account, depending on your bank.':
    'Der går normalt 1-2 hverdage før pengene kommer ind på din konto, afhængigt af din bank.',
  'It usually takes 1-2 working days until the money arrives in your account.':
    'Der går normalt 1-2 hverdage, før pengene kommer ind på din konto.',
  "It's time to update!": 'Det er tid til at opdatere!',
  'Join our crypto advent calendar and win crypto every day until christmas eve':
    'Deltag i vores julekalender og udpak en chance for at vinde krypto eller eksklusiv Firi-merch hver dag indtil juleaften!',
  "Join this year's Christmas calender!": 'Deltag i årets julekalender!',
  'Just remembered?': 'lige husket?',
  'Keep in mind that the most relevant documentation make it easier and quicker for us to process your case.':
    'Husk, at den mest relevante dokumentation gør det nemmere og hurtigere for os at behandle din sag.',
  'Kom i gang allerede i dag!': 'Kom i gang allerede i dag!',
  Language: 'Sprog',
  'Last available tax report': 'Sidst tilgængelige skatteopgørelse',
  'Last day': 'Sidste dag',
  'Last deposit from': 'Sidste indbetaling fra',
  'Last month': 'Sidste måned',
  'Last name': 'Efternavn',
  'Last price': 'Sidste pris',
  'Last transactions': 'Sidste transaktioner',
  'Latest articles': 'Seneste artikler',
  'Latest news from the crypto market': 'Sidste nyt fra kryptomarkedet',
  "Launched in 2020, Polkadot is one of the world's largest cryptocurrencies based on market value. Polkadot is built as part of a larger vision of a decentralized internet, and wants to revolutionize the way we can use blockchain technology.":
    'Polkadot blev lanceret i 2020 og er en af verdens største kryptovalutaer baseret på markedsværdi. Polkadot er bygget som en del af en større vision om et decentraliseret internet, og i ønsket om at revolutionere måden, vi kan bruge blockchain-teknologi på.',
  'Lean back. We will let you know when you get a reward🤩':
    'Læn dig tilbage. Vi giver dig besked, når du får en belønning 🤩',
  'Learn about crypto': 'Lær om krypto',
  'Learn more': 'Lær mere',
  'Learn more about crypto': 'Lær mere om krypto',
  'Learn more about how this affects your tax': 'Læs mere om, hvordan dette påvirker din skat',
  'Learn more about staking': 'Lær mere om staking',
  Learning: 'Læring',
  'Let your crypto do the work for you and earn rewards while you sleep.':
    'Lad din krypto gøre arbejdet for dig og tjen belønninger, mens du sover.',
  Light: 'Lys',
  Limits: 'Grænser',
  'Listen in as we talk about the biggest news in the crypto world over the past week':
    'Lyt med når vi taler om de største nyheder i krypto-verden i den seneste uge',
  'Litecoin is a digital currency that you can store and transfer directly to anyone, anywhere in the world, without going through third parties such as banks or other central institutions.':
    'Litecoin er en digital valuta, som du kan gemme og overføre direkte til hvem som helst, hvor som helst i verden, uden at gå gennem tredjeparter såsom banker eller andre centrale institutioner.',
  'Litecoin is based on the source code of Bitcoin, and is considered to be one of the first alternative cryptocurrencies to Bitcoin.':
    'Litecoin er baseret på Bitcoin sin kildekode, og er anset for at være en af de første alternative kryptovalutaer til Bitcoin.',
  'Litecoin network': 'Litecoin-netværket',
  'Load more': 'Indlæs mere',
  Loading: 'Indlæser',
  Locked: 'Låst',
  'Locked in order': 'Låst i ordre',
  'Locked in unstaking': 'Låst i unstaking',
  'Log in': 'Log på',
  'Log in or register now': 'Log ind eller tilmeld dig nu',
  'Log in to <0>Skatteetaten.no</0> and open the tax return.':
    'Log ind på <0>Skatteetaten.no</0> og åbn selvangivelsen.',
  'Log in to <0>https://www.altinn.no/</0>': 'Log ind på <0>https://www.altinn.no/</0>',
  'Log in to TastSelv Borger': 'Log på TastSelv Borger',
  'Log in to Tastselv at SKAT and follow this guide':
    'Log ind på Tastselv på SKAT og følg denne vejledning:',
  'Log in to your Firi account.': 'Log ind på din Firi konto.',
  'Log in to your online bank and go for a fixed transfer/payment.':
    'Gå ind på din netbank og vælg opret fast overførsel/betaling.',
  'Log in to your online bank and go to transfer/payment.':
    'Gå ind på din netbank og vælg overførsel/betaling.',
  'Log in with Vipps or email. If you have registered with Vipps, you can <0>only</0> log in with Vipps.':
    'Log ind med Vipps eller e-mail. Hvis du har registreret dig hos Vipps, kan du <0>kun</0> logge ind med Vipps.',
  'Log into': 'Log ind på',
  'Log out': 'Log ud',
  Login: 'Log på',
  "Looks like we're not available in your region":
    'Det ser ud til at vi ikke er tilgængelige i din region',
  Loss: 'Tab',
  'Low demand': 'Lav efterspørgsel',
  'Lowest (24h)': 'Laveste (24h)',
  'Lowest price': 'Laveste pris',
  'Lowest value': 'Laveste værdi',
  "MANA is linked to the crypto game Decentraland and serves as a currency within the game. Decentraland is a virtual world that players can freely explore and build their own things in. Players can buy and sell properties, services, and assets within the game, and MANA functions as the medium of payment. Therefore, the success of MANA is closely tied to Decentraland's ability to attract players.":
    'MANA er knyttet til kryptospillet Decentraland og fungerer som en valuta inden for spillet. Decentraland er en virtuel verden, som spillere frit kan udforske og bygge deres egne ting i. Spillere kan købe og sælge ejendomme, tjenester og aktiver ind',
  'MATIC is becoming POL as part of a major upgrade to strengthen the Polygon network. Due to this, trading, deposits, and withdrawals of MATIC are temporarily unavailable.':
    'MATIC bliver til POL som en del af en større opgradering for at styrke Polygon-netværket. På grund af dette er handel, indskud og udbetalinger af MATIC midlertidigt utilgængelige.',
  'Main account': 'Hovedkonto',
  'Maintenance is in progress from 07:00 to 08:00. Estimated time is one hour, but it can be faster. Thanks for your patience!':
    'Vedligeholdelse er i gang fra 07:00 til 08:00. Estimeret tid er en time, men det kan være hurtigere. Tak for din tålmodighed!',
  'Maintenance ongoing...': 'Vedligeholdelse i gang',
  'Make a crypto withdrawal': 'Foretag en krypto-hævning',
  'Make a deposit': 'Lav et indskud',
  'Make a deposit to your savings account': 'Lav et indskud til din opsparingskonto',
  'Make a deposit to your savings account, minimum {0} {currency}': [
    'Foretag et indskud på din opsparingskonto, minimum ',
    ['0'],
    ' ',
    ['currency'],
  ],
  'Make a withdrawal to your bank account': 'Foretag en hævning til din bankkonto',
  'Make more changes': 'Foretag flere ændringer',
  'Make withdrawal': 'Foretag hævninger',
  'Make you first investment now': 'Foretag din første investering nu',
  'Make your first crypto deposit': 'Foretag dit første kryptoindskud',
  'Make your first investment': 'Foretag din første investering',
  'Management fees': 'Administrationsomkostninger',
  'Manual transfer': 'Bankoverførsel',
  "Many choose crypto because it's an exciting alternative to stocks and funds. Crypto as part of a diversified portfolio can be a smart choice for the future, but it's important to remember that all investments involve risk.":
    'Mange vælger krypto, fordi det er et spændende alternativ til aktier og fonde. Krypto som en del af en diversificeret portefølje kan være et smart valg for fremtiden, men det er vigtigt at huske, at alle investeringer indebærer risiko.',
  'Mark as read': 'Marker som læst',
  Market: 'Marked',
  'Market and prices': 'Marked og priser',
  'Market is empty': 'Markedet er tomt',
  Marketing: 'Markedsføring',
  Max: 'Max',
  'Max 18 characters': 'Max 18 tegn',
  'Max filesize': 'Max filstørrelse',
  'Maximum withdraw is {0} {currency}': ['Maksimal udbetaling er ', ['0'], ' ', ['currency']],
  'Maybe later': 'Måske senere',
  'Maybe not chocolate cookies, but still important for a good experience when you visiting us. We use cookies to improve your user experience, secure our website and for marketing.':
    'Måske ikke chokolade cookies, men stadig vigtige for en god oplevelse, når du besøger os. Vi bruger cookies til at forbedre din brugeroplevelse, sikre vores hjemmeside og til markedsføring.',
  'Meme coins': 'Meme-coins',
  'Meme coins are a type of cryptocurrency based on humor and internet culture. They exist primarily for amusement and, unlike traditional cryptocurrencies like Bitcoin and Ethereum, have no underlying utility value.':
    'Meme-coins er en form for kryptovaluta baseret på humor og internetkultur. De eksisterer stort set kun for sjov og har i modsætning til traditionelle kryptovalutaer som Bitcoin og Ethereum ingen underliggende værdi.',
  'Meme coins are evenly distributed across many wallets. It takes many individual stakeholders to move the price':
    'Meme-coins er jævnt fordelt på mange wallets. Der skal mange individuelle aktører til for at påvirke prisen',
  'Merry Christmas!': 'Glædelig jul!',
  'Merry christmas!': 'Glædelig jul!',
  Message: 'Besked',
  Minimum: 'Minimum',
  'Minimum amount is {SAVING_AGREEMENT_MIN_AMOUNT} {0}': [
    'Minimumsbeløbet er ',
    ['SAVING_AGREEMENT_MIN_AMOUNT'],
    ' ',
    ['0'],
  ],
  'Minimum amount is {minAmount} NOK': ['Minimumsbeløb er ', ['minAmount'], ' DKK'],
  'Minimum deposit is': 'Minimum indbetaling er',
  'Minimum withdrawal is': 'Minimum udbetaling er',
  'Minimum withdrawal is {0} {currency} ≈ {1} {2}': [
    'Minimumsudbetaling er ',
    ['0'],
    ' ',
    ['currency'],
    ' ≈ ',
    ['1'],
    ' ',
    ['2'],
  ],
  'Minimum {VALUE_MIN_LENGTH} characters': ['Mindst ', ['VALUE_MIN_LENGTH'], ' tegn'],
  'Mismatching price index': 'Mismatchende prisindeks',
  'Missing input': 'Manglende input',
  'Missing purchase price': 'Manglende købspris',
  MobilePay: 'MobilePay',
  'MobilePay fee': 'MobilePay-gebyr',
  'Moderately high level of knowledge': 'I ringe grad',
  'Moderately low level of knowledge': 'Til en vis grad',
  'Money laundering': 'Hvidvaskning',
  Month: 'Måned',
  'More and better market information': 'Mere og bedre information om markedet',
  'More coins': 'Flere forskellige krypto',
  'More information will give you a better user experience. This includes shorter processing time at our customer service.':
    'Mere information vil give dig en bedre brugeroplevelse. Dette giver kortere behandlingstid hos vores kundeservice.',
  'More options': 'Flere muligheder',
  'More rewards': 'Flere fordeler',
  'More rewards are coming': 'Flere belønninger kommer',
  'More services (e.g., shorting or swap function)':
    'Flere tjenester (f.eks. shorting eller swap-funktion)',
  'Most common platforms': 'Almindelige platforme',
  'Most meme coins are distributed across a few wallets. Some stakeholders can cause major fluctuations in the price themselves':
    'De fleste af meme-coins er fordelt på nogle få wallets. Visse aktører kan alene forårsage store udsving i prisen',
  'Most picked': 'Mest valgte',
  'Most popular on Firi': 'Mest populære hos Firi',
  'Most popular on Firi now': 'Mest populære på Firi nu',
  'Most popular on Firi right now': 'Mest populære hos Firi lige nu',
  'Move crypto to your Firi-wallets': 'Flyt krypto til dine Firi-wallets',
  'Movement in the crypto market': 'Bevægelser på kryptomarkedet',
  'Must be 6 digits': 'Skal være 6 cifre',
  'Must be a valid email address': 'Skal være en gyldig e-mail-adresse',
  'Must be approved': 'Skal godkendes',
  'Must contain 6 digits': 'Skal indeholde 6 cifre',
  'Must contain {PASSWORD_MIN_LENGTH_VARIANT_B} characters': [
    'Skal indeholde ',
    ['PASSWORD_MIN_LENGTH_VARIANT_B'],
    ' tegn',
  ],
  'Must only contain digits': 'Må kun indeholde tal',
  'My accounts': 'Mine konti',
  'My balance': 'Min saldo',
  'My bank accounts': 'Mine bankkonti',
  'My crypto': 'Min krypto',
  'My destination tag': 'Mit destinations tag',
  'My documentation': 'Min dokumentation',
  'My favorites': 'Mine favoritter',
  'My holdings': 'Mine midler',
  'My information': 'Mine oplysninger',
  'My profile': 'Min profil',
  'My referral code:': 'Min henvisningskode:',
  'My referrals': 'Mine invitationer',
  'My saved addresses': 'Mine gemte adresser',
  'My saved favorites': 'Mine gemte favoritter',
  'My savings agreement': 'Min opsparingsaftale',
  'My uploaded documents': 'Mine uploadede dokumenter',
  'My wallet address': 'Min wallet adresse',
  'My {currency} holding': ['Min ', ['currency'], ' beholdning'],
  'NOK-balance per 31.12.{year} has already been reported.': [
    'NOK-saldo pr. 31.12.',
    ['år'],
    ' er allerede indberettet.',
  ],
  'NOTE: we do not support instant transfer.': 'OBS: vi understøtter ikke straksoverførsel.',
  Name: 'Navn',
  'Name is required': 'Navn er påkrevd',
  'Name of bank': 'Bankens navn',
  'Name unknown': 'Navn ukendt',
  'National identification': 'National identifikation',
  'Need help or have any questions? Our excellent customer service team is here to help you. Contact us through the chat in the Firi app.':
    'Har du brug for hjælp eller har spørgsmål? Vores fremragende kundeservice-team står klar til at hjælpe dig. Kontakt os via chat i Firi-appen.',
  'Needs to verify using BankID': 'Skal bekræftes med BankID',
  'Neither nor': 'Hverken eller',
  'New at Firi': 'Ny hos Firi',
  'New crypto': 'Ny krypto',
  'New deposits': 'Nye indskud',
  'New favorite': 'Ny favorit',
  'New menu item - Explore': 'Nyt i menuen - Udforsk',
  'New password': 'Nyt kodeord',
  'New products and crypto at Firi': 'Nye produkter og krypto hos Firi',
  'New receiver': 'Ny modtager',
  'New reward! Teach others about crypto': 'Ny belønning! Lær andre om krypto',
  'New update': 'Ny opdatering',
  'New update available': 'Ny opdatering tilgængelig',
  'New update in {storeVersion}': ['Ny opdatering tilgængelig i ', ['storeVersion']],
  Newbie: 'Nybegynder',
  News: 'Nyheder',
  'News!': 'Nyhed!',
  'News: SOL-staking!': 'Nyhed: SOL-staking!',
  Newsletter: 'Nyhedsbreve mv.',
  Next: 'Næste',
  No: 'Nej',
  'No commission for new referrals': 'Ingen kommission for nye invitationer',
  'No data': 'Ingen data',
  'No fees': 'Intet gebyr',
  'No more than any other cryptocurrency': 'Ikke mere end andre kryptovalutaer',
  'No results found': 'Ingen resultater fundet',
  'No thanks': 'Nej tak',
  'No transactions to show yet': 'Ingen transaktioner at vise endnu',
  'No, I do not trade on other exchanges': 'Nej, jeg handler ikke på andre børser',
  'No, but it may take som time to process the withdraw. We will give you a time estimate when you choose to deactivate staking.':
    'Nej, men det kan tage tid at behandle anmodningen. Vi vil give dig en tidsmæssig vurdering, når du vælger at deaktivere staking.',
  'No, cancel': 'Nej, annuller',
  'No, not now': 'Nej, ikke nu',
  'No, thanks': 'Annuller',
  'No, the deposit is from someone else': 'Nej, indskuddet kommer fra en anden',
  Norway: 'Norge',
  Norwegian: 'Norsk',
  'Norwegian kroner': 'Danske kroner',
  'Norwegian-lang': 'Norsk',
  'Not a valid bank account number': 'Ikke et gyldigt bankkontonummer',
  'Not a valid email': 'Ikke en gyldig e-mail',
  'Not a valid referral code': 'Ikke en gyldig invitationskode',
  'Not all banks support Open Banking. You can instead add a bank account manually.':
    'Ikke alle banker understøtter Open Banking. Du kan i stedet tilføje kontonummeret manuelt.',
  'Not all banks support Open Banking. You can instead transfer money manually from your online bank.':
    'Ikke alle banker understøtter Open Banking. Du kan i stedet overføre penge manuelt fra din netbank.',
  'Not enough buy orders available': 'Ikke nok købsordrer tilgængelige',
  'Not enough sell orders available': 'Ikke nok salgsordrer tilgængelige',
  'Not now': 'Ikke nu',
  'Not supported files': 'Ikke understøttede filer',
  'Not sure which crypto to invest in? Here you can find crypto by category.':
    'Er du ikke sikker på, hvilken krypto du skal investere i? Her kan du finde krypto efter kategori.',
  'Not valid': 'Ikke gyldig',
  'Not valid code': 'Ikke gyldig kode',
  'Note that the price may change between the time you decide to buy and when you confirm the order. The exchange rate is therefore an estimate and may vary by up to 3 percent.':
    'Bemærk at prisen kan ændre sig fra du bestemmer dig for at købe og til du har bekræftet ordren. Vekslingskursen er altså et estimat, og kan variere med op til 3 procent.',
  'Note!': 'Bemærk!',
  'Note! If you make changes to the amount and interval, you must update it in your online bank.':
    'Bemærk! Hvis du ændrer beløb og interval, skal du opdatere det i din netbank.',
  'Nothing here yet!': 'Intet her endnu!',
  Notifications: 'Meddelelser',
  'Now just sit back and get ready for the biggest crypto happening!':
    'Nu er det bare at glæde sig!',
  'Now we are just waiting to receive your first bank deposit. View or change your savings agreement here':
    'Nu venter vi bare på at modtage dit første bankindskud. Se eller ændre din opsparingsaftale her',
  'Now we need relevant documentation from you': 'Nu mangler vi relevant dokumentation fra dig',
  'Now you can get up to {0}% annual yield when staking {baseCurrency}': [
    'Nu kan du få op til ',
    ['0'],
    '% i årligt afkast, når du staker ',
    ['baseCurrency'],
  ],
  'Now you only need som ETH to get started': 'Nu mangler du bare ETH for at starte',
  'Numbers only from 0 to 100': 'Kun tal fra 0 til 100',
  'OTC Trading': 'OTC-handel',
  Off: 'Af',
  'Oh no..': 'Åh nej..',
  "Oh, it looks like you're a bit lost!": 'Du er vist faret vild!',
  Okay: 'Okay',
  'Okay!': 'Okay!',
  'Okay, I got it!': 'Okay, jeg forstår det!',
  On: 'På',
  'On the new profile screen, you can now add a personal touch to your Firi user with a profile picture. We have also moved all settings here, along with Firi Advantage and Firi Affiliate.':
    'På den nye profilside kan du nu tilføje et personligt præg på din Firi-bruger med et profilbillede. Vi har også flyttet alle indstillingerne hertil, sammen med Firi Fordel og Firi Affiliate.',
  'Once it has been sent, it will usually takes around 10 minutes for the first confirmation on the blockchain and normally within an hour to complete all confirmations. However, it can take longer depending on the transaction volume at the time your amount are being sent.':
    'Når den er sendt, vil det normalt tage omkring 10 minutter for den første bekræftelse på blockchainen og normalt vil alle bekræftelser være fuldført inden for en time. Det kan dog tage længere tid afhængigt af transaktionsvolumen på det tidspunkt, dit beløb sendes.',
  'Once you have downloaded the 2FA app, you can come back here and move on to the next step.':
    'Når du har downloadet 2FA-appen, kan du vende tilbage hertil og gå videre til næste trin.',
  'One of the values that stands strong in Cardano is to be a sustainable blockchain that addresses the challenges of high power consumption that many blockchains have today.':
    'En af de værdier, der står stærkt i Cardano, er at være en bæredygtig blockchain, der adresserer de udfordringer med højt strømforbrug, som mange blockchains har i dag.',
  'One or more deposit failed to update. Please try again. If it keeps failing, please contact our support.':
    'En eller flere indskud kunne ikke opdateres. Prøv venligst igen. Hvis det bliver ved med at mislykkes, bedes du kontakte vores support.',
  'One time password must be 6 digits': 'Engangskodeord skal være på 6 cifre',
  'One-time password is required.': 'Engangsadgangskode er påkrævet.',
  'Ongoing activities': 'Igangværende aktiviteter',
  'Ongoing tax calculation': 'Løpende skatteberegning',
  'Ongoing transfer': 'Igangværende overførsel',
  'Ongoing transfers': 'Igangværende overførsler',
  'Only users over 18 with BankID can participate.':
    'Kun brugere over 18 år med NemID eller MitID kan deltage.',
  'Ooopsie, an unexpected error occurred!': 'Ooopsie, der opstod en uventet fejl!',
  'Open "Melding: Skattemelding for 2023 som dokumentasjon"':
    'Åben "Melding: Skattemelding for 2023 som dokumentation"',
  'Open Google Authenticator': 'Åbn Google Authenticator',
  'Open a new account': 'Åbn en ny konto',
  'Open a staking account and earn rewards on your crypto.':
    'Åbn en staking-konto og tjen belønning på din krypto.',
  'Open new account': 'Åbn ny konto',
  'Open savings account': 'Åbn opsparingskonto',
  'Open setting': 'Åbn indstillinger',
  'Open staking account': 'Åbn staking-konto',
  'Open you inbox in Altinn': 'Åbn din indbakke i Altinn',
  'Open {storeVersion}': ['Gå til ', ['storeVersion']],
  'Open “Message: Skattemelding for 2023 som dokumentasjon”':
    'Åbn “Besked: Skattemelding for 2023 som dokumentation“',
  Optional: 'Valgfri',
  'Or copy the configuration key and add it to your 2FA app':
    'Eller kopier konfigurationsnøglen og føj den til din 2FA-app',
  'Order book': 'Bestillingsbog',
  'Order is either filled or cancelled.':
    'Din ordre er netop blevet fyldt op eller annulleret. Prøv igen.',
  Orders: 'Ordrer',
  'Org nr:': 'CVR-nummer:',
  'Originally, Binance users used BNB to get lower trading fees. The crypto is know as Binance Coin, and has multiple use cases. Such as operating and securing the BNB Chain ecosystem and being used as a payment method both online and in physical stores.':
    'BNB blev oprindeligt benyttet af brugere på Binanceplatformen til at  få lavere handelsgebyrer. Kryptoen er kendt som Binance Coin og har flere anvendelsesområder. Blandt andet drift og sikring af BNB Chain-økosystemet og den anvendes også som betalingsmiddel i både online og fysiske butikker.',
  Other: 'Andet',
  'Other deposit methods': 'Andre indskudsmetoder',
  'Other exchanges (Binance, Coinbase, etc.)': 'Andre børser (Binance, Coinbase osv.)',
  'Other income from virtual assets / crypto currency':
    'Andre indtægter fra virtuelle aktiver / kryptovaluta',
  'Other marketing': 'Anden markedsføring',
  'Other payment methods': 'Andre betalingsmetoder',
  'Other types of illegal activity': 'Andre former for ulovlig aktivitet',
  'Others have selected': 'Andre har valgt',
  'Our referral program will be part of the Firi rewards program, and in the new benefits program it will not be possible to earn commission on the trading fees of new friends you refer. Of course, you keep the commission on the trading fees of those you have already referred and will continue to earn from this.':
    "Vores invitationsprogram er blevet en del af Firi's fordelsprogram og i det nye fordels program vil det ikke være muligt at tjene kommission på handelsgebyrer for nye venner du inviterer. Selvfølgelig beholder du provisionen på handelsgebyrerne for dem, du allerede har inviteret og vil fortsat tjene på dette.",
  "Our system is temporarily down and we are working to get it back up as quickly as possible. Your funds are completely safe with us. Thanks for your patience, we'll keep you updated!":
    'Vores system er midlertidigt nede, og vi arbejder på at få det op igen så hurtigt som muligt. Dine midler er helt sikre hos os. Tak for din tålmodighed, vi vil holde dig opdateret!',
  Overview: 'Oversigt',
  'Ownership confirmation needed': 'Bekræftelse af ejerskab påkrævet',
  'PDF - Annual statement': 'PDF - Årsopgørelse',
  'PDF - Tax report': 'PDF - Skatterapport',
  'Paid advertising to promote the referral link is not allowed.':
    'Betalt reklame for at promovere hvervelinket er ikke tilladt.',
  'Paid out': 'Udbetalt',
  Pair: 'Par',
  Paragraph: 'Afsnit',
  'Passive rewards': 'Tjen belønning',
  Password: 'Adgangskode',
  'Password is changed': 'Adgangskoden er ændret',
  'Passwords do not match': 'Kodeordene er ikke ens',
  'Past 3 months': 'Sidste 3 måneder',
  'Past month': 'Sidste måned',
  'Past week': 'Sidste uge',
  'Past year': 'Sidste år',
  'Paste from clipboard': 'Indsæt fra udklipsholder',
  'Pay special attention to:': 'Læg særligt mærke til:',
  'Payment method': 'Betalingsmetode',
  Payouts: 'Udbetalinger',
  Pending: 'Afventer',
  'Pending payouts': 'Afventende udbetalinger',
  'Perfect!': 'Perfekt!',
  Permissions: 'Tilladelser',
  'Personal experience': 'Personlig erfaring',
  'Personal touch with profile picture': 'Personligt præg med profilbillede',
  'Phone number': 'Telefonnummer',
  'Pink Ribbon 2021': 'Pink Ribbon 2021',
  Platform: 'Platform',
  'Please install': 'Installer venligst',
  'Please look over and confirm that the provided information is correct':
    'Læs venligst og bekræft, at de angivne oplysninger er korrekte',
  'Please note that it can take 1 working day until you can send the funds out of your account with us.':
    'Bemærk, at der kan gå 1 hverdag, før du kan sende pengene ud af din konto hos os.',
  'Please note that we do not allow transfers to or from addresses that are linked to':
    'Bemærk venligst, at vi ikke tillader overførsler til eller fra adresser, der er knyttet til',
  'Please tick the box if you want to continue.':
    'Sæt kryds i boksen, hvis du ønsker at fortsætte.',
  'Please try again': 'Venligst prøv igjen.',
  'Please upload relevant documentation. The documents are safely and securly stored at Firi.':
    'Venligst last op relevant dokumentation. Dokumenterne opbevares trygt og sikkert hos Firi.',
  Podcast: 'Podcast',
  "Polkadot is the name of Polkadot's protocol. When we talk about Polkadot's cryptocurrency, or token, it is referred to as DOT.":
    "Polkadot er navnet på Polkadot's protokol. Når vi taler om Polkadots kryptovaluta, eller token, omtales det som DOT.",
  'Polkadot network': 'Polkadot-netværket',
  'Polkadot wants to unite large and unique networks, such as Bitcoin and Ethereum, so that they can collaborate across blockchains.':
    'Polkadot ønsker at forene store og unikke netværk, som fx Bitcoin og Ethereum, så de kan samarbejde på tværs af blockchains.',
  'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Matic is the associated cryptocurrency that is used for transactions and staking, among other things.':
    'Polygon er et økosystem af forskellige løsninger for at gøre Ethereum mere skalerbart. Matic er den tilknyttede kryptovaluta, der blandt andet bruges til transaktioner og staking.',
  'Polygon is an ecosystem of various solutions to make Ethereum more scalable. Polygon is the associated cryptocurrency that is used for transactions and staking, among other things.':
    'Polygon er et økosystem af forskellige løsninger for at gøre Ethereum mere skalerbart. Polygon er den tilknyttede kryptovaluta, der blandt andet bruges til transaktioner og staking.',
  'Polygon network': 'Polygon-netværket',
  Popular: 'Populære',
  Press: 'Presse',
  'Press-release': 'Pressemeddelelse',
  'Preview Buy': 'Forhåndsvis køb',
  'Preview Sell': 'Forhåndsvis salg',
  'Previously uploaded': 'Tidligere uploadet',
  Price: 'Pris',
  'Price ({0})': ['Pris (', ['0'], ')'],
  'Price details of your purchases': 'Prisdetaljer på dine køb',
  'Price development last week': 'Prisudvikling i sidste uge',
  Privacy: 'Privatliv',
  'Private wallet': 'Privat wallet',
  Prize: 'Præmie',
  Processing: 'Igang',
  Profile: 'Profil',
  Profit: 'Profit',
  'Promote only your own link and be honest – no guarantees for profit.':
    'Promovér kun dit eget link, og vær ærlig – ingen garantier for fortjeneste.',
  'Proof-of-stake': 'Proof-of-stake',
  'Proof-of-work': 'Proof-of-work',
  'Purchase amount': 'Købsbeløb',
  'Purchase of {0} not available': ['Køb af ', ['0'], ' utilgængelig'],
  'Purchase of {selectedCurrency} not available': [
    'Purchase of ',
    ['selectedCurrency'],
    ' not available',
  ],
  'Purchase price': 'Købspris',
  'Pyramid games': 'Pyramide spil',
  Rate: 'Kurs',
  'Rating on App Store': 'Rating på App Store',
  'Rating on Google Play': 'Rating på Google Play',
  Read: 'Læs',
  'Read about fees': 'Læs om gebyrer',
  'Read about insurance of your assets': 'Læs om forsikring af dine værdier',
  'Read about trades on your savings account': 'Læs om handler på din opsparingskonto',
  'Read more': 'Læs mere',
  'Read more about': 'Læs mere om',
  'Read more about crypto tax': 'Læs mere om kryptoskat',
  'Read more about crypto tax here': 'Læs mere om kryptoskat her',
  'Read more about deductible fees': 'Læs mere om fradragsføre gebyrer',
  'Read more about how we work to prevent scams and investment fraud':
    'Læs mere om, hvordan vi arbejder for at forebygge svindel og investeringsbedrageri',
  'Read more about insurance': 'Læs mere om forsikring',
  'Read more about processing times here': 'Læs mere om sagsbehandlingstider her',
  'Read more about rewards here.': 'Læs mere om belønninger her.',
  'Read more about the tax report here': 'Læs mere om skatteopgørelsen her',
  'Read more about what personal information we process here.':
    'Læs mere om, hvilke personoplysninger vi behandler her.',
  'Read more articles': 'Læs flere artikler',
  'Read more here': 'Læs mere her',
  'Read more news': 'Læs flere nyheder',
  'Read terms for the calendar': 'Læs vilkår for kalenderen',
  'Ready for reporting': 'Klar til rapportering',
  'Ready to get started?': 'Er du klar til at begynde?',
  'Recall that it can take 1-2 business days before the withdrawn from your bank reaches your savings account here at Firi.':
    'Husk, at der kan gå 1-2 hverdage, før hævningen fra din bank når din opsparingskonto her hos Firi.',
  Receive: 'Modtag',
  'Receive bonus when registering with Firi | Norwegian exchange for cryptocurrency trading':
    'Modtag bonus ved registrering hos Firi | Norsk børs til handel med kryptovaluta',
  'Receive crypto': 'Modtag krypto',
  'Receive notifications to add purchase prices on new crypto deposits':
    'Modtag advarsler for at tilføje købspriser på nye kryptoindskud',
  'Receive {0}': ['Modtag ', ['0']],
  Received: 'Modtaget',
  'Received amount': 'Modtaget beløb',
  'Received amount:': 'Modtaget beløb:',
  'Received rewards': 'Modtaget belønning',
  Receiver: 'Modtager',
  "Receiver's address": 'Modtagers adresse',
  "Receiver's {0}-address": ['Modtagers ', ['0'], '-adresse'],
  "Receiver's {currency}-address": ['Modtagers ', ['currency'], '-adresse'],
  'Recent articles': 'Seneste artikler',
  Recipient: 'Modtager',
  'Recipient information': 'Modtagerinformation',
  "Recipient's information": 'Modtagerens opplysninger',
  'Recurring deposits': 'Tilbagevendende overførsler',
  'Recurring transfers': 'Tilbagevendende overførsler',
  'Red Cross': 'Røde Kors',
  Redeem: 'Indløs',
  'Redeem gift card': 'Indløs gavekort',
  'Redeem your gift card in the Firi app or at Firi.no':
    'Indløs dit gavekort i Firi-appen eller på Firi.no',
  'Refer a friend': 'Inviter en ven',
  'Refer a friend and get {0} {1} i {payoutCurrencyName}!': [
    'Inviter en ven og få ',
    ['0'],
    ' ',
    ['1'],
    ' i ',
    ['payoutCurrencyName'],
    '!',
  ],
  'Refer at least {0} friends that trade crypto with Firi before 31. march.': [
    'Inviter mindst ',
    ['0'],
    ' venner, der handler krypto med Firi inden den 31. marts.',
  ],
  'Refer friends': 'Inviter venner',
  'Referral bonus is paid out in bitcoin (BTC) at the end of each month. How much bitcoin you get depends on the exchange rate on the day you referred your friend.':
    'Invitationsbonus udbetales i bitcoin (BTC) i slutningen af hver måned. Hvor meget bitcoin du får, afhænger af kursen den dag du inviteret din ven.',
  'Referral bonus is paid out in {0} at the end of each month.': [
    'Invitationsbonus udbetales i ',
    ['0'],
    ' i slutningen af hver måned.',
  ],
  'Referral code': 'Invitationskode',
  'Referral code (optional)': 'Invitationskode (valgfrit)',
  'Referral code is not valid': 'Invitationskode er ikke gyldig',
  Referrals: 'Inviterede',
  'Referrals pending payment': 'Invitationer, afventer betaling',
  'Referrals with this code': 'Invitationer med denne koden',
  Referred: 'Inviteret',
  'Referred {0}/{1}': ['Inviteret ', ['0'], '/', ['1']],
  Refund: 'Tilbagebetale',
  'Reg number': 'Reg nummer',
  'Reg. nr. and account number': 'Reg. nr. og kontonummer',
  'Regards {0}': ['Med venlig hilsen ', ['0']],
  'Regards {from}': ['Hilsen ', ['fra']],
  Register: 'Tilmeld dig',
  'Register account with Firi': 'Registrer konto hos Firi',
  'Register for free': 'Tilmeld dig gratis',
  'Register here': 'Tilmeld dig her',
  'Register now': 'Tilmeld dig nu',
  'Register the transfer in your bank': 'Registrer overførslen i din bank',
  'Register with': 'Tilmeld dig med',
  'Register with Vipps': 'Tilmeld dig med Vipps',
  'Register with Vipps to get started and get {referralAmount} kroner to buy your first cryptocurrency with Firi and start your investments.':
    [
      'Registrer dig med MitID for at komme i gang og få ',
      ['referralAmount'],
      ' kroner til at købe din første kryptovaluta med Firi og starte dine investeringer.',
    ],
  'Registered with Vipps?': 'Registreret hos Vipps?',
  'Registration is complete!': 'Registreringen er fuldført!',
  'Related articles': 'Relaterede artikler',
  'Related pages': 'Relaterede sider',
  'Relevant articles': 'Relevante artikler',
  'Relevant documentation can for instance be:': 'Relevant dokumentation kan for eksempel være:',
  Remaining: 'Tilbage',
  'Remember that the bank is closed on the weekend, but with {labels} and {scheme} you can receive the deposit within seconds.':
    [
      'Husk at banken er lukket i weekenden, men med ',
      ['labels'],
      ' og ',
      ['scheme'],
      ' kan du modtage indbetalingen indenfor få sekunder.',
    ],
  'Remember that the minimum amount is {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0} per selected cryptocurrency.':
    [
      'Husk, at minimumsbeløbet er ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
      ' pr. valgt kryptovaluta.',
    ],
  'Remember that you are responsible for filling in the figures in the tax return. This does not happen automatically.':
    'Husk, at du selv er ansvarlig for at udfylde tallene i selvangivelsen. Dette sker ikke automatisk.',
  'Remember to update your payment agreement in the bank':
    'Husk at opdatere din betalingsaftale i banken',
  'Remember!': 'Husk!',
  'Remember, the recruitment must be honest and fair – we monitor the program to ensure it is not abused.':
    'Husk, hvervningen skal ske ærligt og redeligt – vi kontrollerer, at programmet ikke misbruges.',
  Remove: 'Fjern',
  'Remove as favorite': 'Fjern som favorit',
  'Remove bank account': 'Fjern bankkonto',
  'Remove photo': 'Fjern billede',
  'Repeat code': 'Gentag kode',
  'Repeat password': 'Gentag adgangskode',
  'Request reset link': 'Anmod om nulstillingslink',
  'Request {0}': ['Anmod om ', ['0']],
  Requested: 'Anmodet',
  Required: 'Påkrævet',
  'Requires subscription at': 'Kræver subscription hos',
  'Resend code': 'Gensend kode',
  'Reset password': 'Nulstille kodeord',
  Return: 'Afkast',
  Reward: 'Belønning',
  'Reward claimed or not found': 'Belønning indhentet eller ikke fundet',
  'Reward from staking': 'Belønning fra staking',
  'Reward on this code': 'Belønning på denne koden',
  'Reward: {0} for {1} {2}': ['Belønning: ', ['0'], ' for ', ['1'], ' ', ['2']],
  Rewards: 'Belønninger',
  'Rewards (crypto)': 'Belønning (krypto)',
  'Rewards ({0})': ['Belønning (', ['0'], ')'],
  'Rewards from staking are taxable income.': 'Belønning fra staking er skattepligtigt.',
  'Rewards received': 'Belønning modtaget',
  'Right around the corner': 'Lige om hjørnet',
  'Right now there is a high demand to buy {currency}. We recommend you try again later.': [
    'Lige nu er der stor efterspørgsel efter at købe ',
    ['currency'],
    '. Vi anbefaler, at du prøver igen senere.',
  ],
  'Right now there is a high demand to sell {currency}. We recommend you try again later.': [
    'Lige nu er der stor efterspørgsel efter at sælge ',
    ['currency'],
    '. Vi anbefaler, at du prøver igen senere.',
  ],
  'Ripple network': 'Ripple-netværket',
  'Run tax calculation': 'Kør skatteberegning',
  'SAND functions as the currency within The Sandbox universe. Users can use SAND to buy property, play games, purchase equipment, or customize their avatars.':
    'SAND fungerer som valuta inden for The Sandbox-universet. Brugere kan bruge SAND til at købe ejendom, spille spil, købe udstyr eller tilpasse deres avatarer.',
  'SOL-staking is here!': 'SOL(en) er fremme - stake Solana i dag!',
  'Safe and regulated storage of your assets.': 'Sikker og reguleret opbevaring af dine midler',
  'Safe and simple': 'Trygt og enkelt',
  'Safe staking with Firi🔐': 'Sikker staking hos Firi 🔐',
  'Sale completed': 'Salget gennemført',
  'Sale of illegal products or services': 'Salg af ulovlige produkter eller tjenester',
  'Sale of {0} not available': ['Salg af ', ['0'], ' utilgængelig'],
  'Sale of {selectedCurrency} not unavailable': ['Sale of ', ['0'], ' not unavailable'],
  'Sandbox (SAND) is a cryptocurrency used within the crypto game The Sandbox, a virtual world that allows users to create, own, and monetize as well as explore the world':
    'Sandbox (SAND) er en kryptovaluta, der bruges i det krypto-spil The Sandbox, en virtuel verden, der giver brugerne mulighed for at skabe, eje og monetisere samt udforske verden',
  'Santa claus': 'Julemanden',
  Save: 'Gem',
  'Save a recipient as a favorite with name and address so you can easily send crypto.':
    'Gem en modtager som en favorit med navn og adresse, så du nemt kan sende krypto.',
  'Save changes': 'Gem ændringer',
  'Save permissions': 'Gem tilladelser',
  Savings: 'Opsparing',
  'Savings & Investments': 'Opsparing & Investeringer',
  'Savings account': 'Opsparingskonto',
  'Savings agreement': 'Opsparingsaftale',
  Scams: 'Svindel',
  'Scan QR-code to download our app': 'Scan QR-koden for at downloade vores app',
  'Scan QR-code to download our app and get your bonus':
    'Scan QR-koden for at downloade vores app og få din bonus',
  'Scan the QR code': 'Scan koden',
  'Scan the QR code in Google Authenticator': 'Scan QR-koden i Google Authenticator',
  'Scan the QR code to download the app and identify yourself with:':
    'Scan QR-koden for at downloade appen og log ind med:',
  'Scan the QR-code or copy the link below to the external wallet. It can take about 1 hour before you see the cryptocurrency on your account.':
    'Scan QR-koden eller kopier nedenstående link til den eksterne pung. Det kan tage omkring 1 time, før du ser kryptovalutaen på din konto.',
  'Scan the QR-code or copy the wallet address below. It can take about 1 hour before you see the cryptocurrency in your account.':
    'Scan QR-koden eller kopier nedenstående wallet-adresse. Det kan tage omkring 1 time, før du ser kryptovalutaen på din konto.',
  'Scan the QR-code to download the app': 'Scan QR-koden for at downloade appen',
  'Se example': 'Se eksempel',
  Search: 'Søg',
  'Search after destination': 'Søg efter destination',
  'Search for cryptocurrency': 'Søg efter kryptovaluta',
  'Search for your bank': 'Søg efter din bank',
  'Secret Key': 'Hemmelig nøgle',
  'Securities management': 'Værdipapirforvaltning',
  Security: 'Sikkerhed',
  See: 'Se',
  'See all': 'Se alt',
  'See all articles': 'Se alle artikler',
  'See all transactions': 'Se alle transaktioner',
  'See full list': 'Se hele listen',
  'See how to do it in the next step.': 'Se hvordan du gør det i næste trin.',
  'See more': 'Se mere',
  'See or edit your crypto saving': 'Se og ændre Kryptosparing',
  'See whats new': 'Se hvad der er nytt',
  'Select "Approve".': 'Vælg "Godkend".',
  'Select "Change your tax assessment notice/tax return".':
    'Vælg "Ret årsopgørelsen/oplysningsskemaet".',
  'Select "I want to enter aggregated tax information for many virtual currency/cryptocurrency"':
    'Vælg "Jeg vil indtaste aggregerede skatteoplysninger for mange virtuelle valutaer/kryptovaluta"',
  'Select "Next" at the bottom of the page.': 'Vælg "Næste" nederst på siden.',
  'Select CSV type to export': 'Vælg CSV-type for at eksportere',
  'Select a year to see your tax calculation. The figures that are ready can be entered into your tax return.':
    'Vælg et år for at se din skatteberegning. Tallene, der er klar til indtastning i din skatteopgørelse, kan indtastes.',
  'Select amount': 'Vælg beløb',
  'Select bank account': 'Vælg kontonummer',
  'Select country': 'Vælg land',
  'Select crypto': 'Vælg krypto',
  'Select currency': 'Vælg valuta',
  'Select deposit method': 'Vælg indskudsmetode',
  'Select language': 'Vælg sprog',
  'Select payment method': 'Vælg betalingsmetode',
  'Select theme': 'Vælg tema',
  'Select time period': 'Vælg tidsperiode',
  Sell: 'Sælg',
  'Sell all': 'Sælg alt',
  'Sell completed': 'Salget gennemført',
  'Sell crypto': 'Sælg krypto',
  'Sell everything': 'Sælg alt',
  'Sell {0}': ['Sælg ', ['0']],
  Send: 'Send',
  'Send all': 'Send alt',
  'Send answer': 'Send svaret',
  'Send crypto': 'Send krypto',
  'Send invitation': 'Send invitation',
  'Send {0}': ['Send ', ['0']],
  'Send {currency}': ['Send ', ['currency']],
  Sender: 'Afsender',
  Sent: 'Sendt',
  'Sent to Firi': 'Sendt til Firi',
  'Set up or update the savings agreement in your bank':
    'Opret eller ændre opsparingsaftalen i din bank',
  'Set up savings agreement': 'Opret en opsparingsaftale',
  Settings: 'Indstillinger',
  'Shape the future of Firi': 'Hjælp os med at blive bedre!',
  'Share gift card': 'Giv et gavekort',
  'Share your referral code or link from the Firi Affiliate section of the app. To receive rewards, the new user must register and verify their account.':
    'Del din hvervekode eller link fra Firi Fordel-sektionen i appen. For at modtage belønningen skal den nye bruger registrere og verificere sin konto.',
  'Share your thoughts here...': 'Del dine tanker her...',
  'Short summary': 'Kort resumé',
  'Should we send you a notification when the money is in your Firi account?':
    'Skal vi give dig en notifikation, når pengene er på din Firi-konto?',
  'Should we send you a push notification when the cryptocurrency has been sent?':
    'Skal vi sende dig en notifikation, når kryptovalutaen er sendt?',
  'Should we send you a push notification when you receive crypto?':
    'Skal vi sende dig en notifikation, når du modtager krypto?',
  'Show address': 'Vis adresse',
  'Show all': 'Vis alle',
  'Show all years': 'Vis alle år',
  'Show details': 'Vis detaljer',
  'Show less': 'Vis mindre',
  'Show more': 'Vis mere',
  'Show section': 'Vis afsnit',
  'Show tax calculation': 'Vis skatteberegning',
  'Show transactions for {year}': ['Vis transaktioner for ', ['år']],
  'Sign In': 'Log ind',
  'Sign in': 'Log ind',
  'Sign in here': 'Log ind her',
  'Sign in with': 'Log ind med',
  'Sign in with email': 'Log ind med e-mail',
  'Sign out': 'Log ud',
  'Sign up': 'Tilmeld',
  'Sign up easily and safely with crypto.': 'Tilmeld dig nemt og sikkert med krypto.',
  'Sign up here': 'Tilmeld dig her',
  'Sign with BankID': 'Skriv under med MitID',
  'Sign with {0} to confirm your identity in a quick and secure way.': [
    'Signer med ',
    ['0'],
    ' for at bekræfte din identitet på en hurtig og sikker måde.',
  ],
  'Since you became a Firi customer this year, you should report your tax figures next year.':
    'Siden du blev Firi-kunde i år, skal du først rapportere skattetallene dine neste år.',
  'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank.':
    'Da du for nylig har foretaget en indbetaling, er vi stadig i gang med at modtage de seneste oplysninger fra din bank.',
  'Since you have recently made a deposit, we are still in the process of receiving the latest information from your bank. We usually receive this information one working day after the deposit':
    'Da du for nylig har foretaget en indbetaling, er vi stadig i gang med at modtage de seneste oplysninger fra din bank. Vi modtager normalt disse oplysninger én hverdag efter indbetalingen',
  Skip: 'Spring over',
  'So nice! You have received a gift in crypto': 'Så rar! Du har modtaget en gave i krypto',
  'Social media (Reddit, Twitter, etc.)': 'Sociale medier (Reddit, Twitter osv.)',
  'Social security number': 'CPR nr',
  "Solana is a decentralized and programmable blockchain and a cryptocurrency. Solana's blockchain is designed specifically to offer scalable, decentralized apps and smart contracts.":
    'Solana er en decentraliseret og programmerbar blockchain og en kryptovaluta. Solanas blockchain er designet specifikt til at tilbyde skalerbare, decentraliserede apps og smarte kontrakter.',
  "Solana itself claims that they are the world's fastest blockchain, and with up to 65,000 transactions per second, you should not ignore the fact that they are absolutely right.":
    'Solana hævder selv, at de er verdens hurtigste blockchain, og med op til 65.000 transaktioner i sekundet skal du ikke se bort fra, at de har helt ret.',
  'Solana network': 'Solana-netværket',
  "Solana's cryptocurrency is called Solana, but is also known under the ticker SOL. Solana is one of the world's largest cryptocurrencies based on market value.":
    'Solanas kryptovaluta hedder Solana, men er også kendt under tickeren SOL. Solana er en af ​​verdens største kryptovalutaer baseret på markedsværdi.hain med op til 65.000 transaktioner i sekundet. SOL er på top 10-listen over de mest værdifulde kryptovalutaer.',
  Sold: 'Solgt',
  'Some money to spare? Make an extra deposit': 'Nogle penge til overs? Indskyd flere penge',
  'Something is wrong with your account. Please contact us and give error code: 3013 for further assistant: support@firi.com':
    'Der er noget galt med din konto. Venligst kontakt os og oplys fejlkode: 3013 for yderligere assistance: support@firi.com',
  'Something is wrong. Please contact support': 'Noget er galt. Kontakt venligst support',
  'Something went wrong': 'Noget gik galt',
  'Something went wrong activating staking': 'Noget gik galt under aktivering af staking',
  'Something went wrong resending code': 'Noget gik galt med at sende koden igen',
  'Something went wrong trying to activate staking. Try again or contact support if the issue persists':
    'Noget gik galt under aktivering af staking. Prøv igen, eller kontakt support, hvis problemet fortsætter',
  'Something went wrong when creating gift card. Please retry or contact support for help.':
    'Noget gik galt, da du oprettede et gavekort. Prøv venligst igen, eller kontakt support for at få hjælp.',
  'Something went wrong. Please try again or contact our support.':
    'Noget gik galt. Prøv venligst igen eller kontakt vores support.',
  'Something went wrong. Please try again or contact support.':
    'Noget gik galt. Prøv venligst igen eller kontakt support.',
  'Something went wrong. Unable to place your order. Try again?':
    'Noget gik galt. Ikke muligt at udføre din ordre. Prøv igen?',
  'Something went wrong. We were not able to cancel your withdraw':
    'Noget gik galt. Vi var ikke i stand til at annullere din udbetaling',
  'Sorry!': 'Beklager!',
  'Sort your crypto': 'Sorter din krypto',
  'Specify the desired amount for your transfer.': 'Angiv det ønskede beløb for din overførsel.',
  Spread: 'Spread',
  'Spring cleaning 🧹': 'Forårsrengøring 🧹',
  Stablecoins: 'Stablecoins',
  Stake: 'Stake',
  'Stake SOL now': 'Stake SOL nu',
  'Stake Solana (SOL) easily and earn passive rewards - we fix the rest!':
    'Ventetiden er forbi – endelig kan du stake dine Solana (SOL) hos Firi!',
  'Stake crypto I own': 'Stake de kryptovalutaer, jeg allerede har',
  'Stake crypto now': 'Stake krypto nu',
  'Stake more': 'Stake mere',
  'Stake more crypto': 'Stake mere krypto',
  Staked: 'Staked',
  'Staked now': 'Staked nu',
  Staking: 'Staking',
  'Staking account': 'Staking-konto',
  'Staking is when you store your cryptocurrency to receive a reward in the form of more crypto.':
    'Staking betyder, at du opbevarer din krypto og modtager belønning i form af mere krypto.',
  'Start a savings agreement': 'Start en opsparingsaftale',
  'Start here': 'Start her',
  'Start now': 'Start nu',
  'Start or change savings from your bank': 'Start eller skift opsparing fra din bank',
  'Start over': 'Start forfra',
  'Start saving': 'Start opsparing',
  'Start savings from your bank': 'Sådan opretter du en opsparingsaftale via din bank',
  'Start staking': 'Begynd at stake',
  'Start staking Solana (SOL) and earn rewards right in the app.':
    'Begynd at stake Solana (SOL) og tjen belønninger direkte i appen.',
  'Start with crypto': 'Start med krypto',
  'Start with crypto today!': 'Start med krypto i dag!',
  'Statistics and analysis': 'Statistik og analyse',
  'Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on the exchange rate':
    'Statistik viser, at langsigtede investeringer giver bedre afkast end at købe og sælge kryptovaluta baseret på valutakursen',
  Status: 'Status',
  'Stellar network': 'Stellar-netværket',
  'Store your configuration key in a safe place. You will need it if you lose your phone or data gets lost':
    'Opbevar din konfigurationsnøgle et sikkert sted. Du får brug for det, hvis du mister din telefon eller data går tabt',
  'Strongest development': 'Stærkeste udvikling',
  Submit: 'Indsend',
  Summary: 'Resumé',
  'Supported filetypes': 'Understøttede filtyper',
  Sweden: 'Sverige',
  Swedish: 'Svensk',
  'Sweet!': 'Skønt!',
  System: 'System',
  'TV campaign 2020': 'TV-kampagne 2020',
  'TV campaign 2021': 'TV-kampagne 2021',
  'Take a look around the app': 'Se dig omkring i appen',
  'Take me back': 'Tag mig tilbage',
  'Take me to': 'Tag mig til',
  'Take me to advanced trading': 'Tag mig til avanceret handel',
  'Take photo': 'Tag et billede',
  'Take the survey': 'Delta nå',
  Tax: 'Skat',
  'Tax calculation': 'Skatteberegning',
  'Tax for 2024 is ready!': 'Skat for 2024 er klar!',
  'Tax for {year}': ['Skat for ', ['year']],
  'Tax help': 'Skattehjælp',
  'Taxable gain': 'Skattepligtig gevinst',
  Taxes: 'Skatter',
  'Teach others about crypto!': 'Lær andre om krypto!',
  'Temporary downtime': 'Midlertidig nedetid',
  Terms: 'Vilkår',
  'Terms of service': 'Servicevilkår',
  'Terrorist financing': 'Terrorfinansiering',
  'Tether USDt (USDT) is a stablecoin tied to the value of the US Dollar (USD), and should therefore always be worth approximately 1 USD. Tether is one of the most widely used stablecoins in the market and was created to provide cryptocurrency users a way to maintain a stable value while still enjoying the benefits that blockchain technology offers, such as fast transfers and global accessibility.':
    'Tether (USDT) er en stablecoin, der er bundet til værdien af den amerikanske dollar (USD) og bør derfor altid være cirka 1 USD værd. Tether er en af de mest anvendte stablecoins på markedet og blev oprettet for at give brugere af krypto en måde at opretholde en stabil værdi samtidig med at kunne gøre brug af de fordele, som blockchain-teknologi giver, såsom hurtige overførsler og global tilgængelighed.',
  'Thank you for completing the facial recognition! We are now reviewing the information, and you will soon receive an email from us when your account is ready for use.':
    'Tak for at du gennemførte ansigtsgenkendelsen! Vi gennemgår nu informationen, og du vil snart modtage en e-mail fra os, når din konto er klar til brug.',
  'Thank you for filling out the form! We are now checking your answers. You will soon receive an email from us so that you can start using our app.':
    'Tak fordi du udfyldte skemaet. Vi går nu igennem dine svar. Snart vil du modtage en e-mail fra os, så du kan begynde at bruge vores app.',
  'Thank you very much!': 'Mange tak!',
  'Thank you!': 'Tak!',
  'Thank you! We are checking your information. Please wait a moment.':
    'Tak! Vi tjekker dine oplysninger. Vent venligst et øjeblik.',
  'Thank you! We will get back to you swiftly.':
    'Mange tak! Vi vender tilbage til dig hurtigst muligt',
  'Thanks for the feedback!': 'Tak for din feedback!',
  'Thanks! Now we just have a few quick questions left':
    'Tak! Nu har vi bare et par hurtige spørgsmål tilbage',
  'That is OK': 'Det er ok',
  "That's fine!": 'Det lyder fint!',
  "That's it! Your Crypto Savings will continue as usual.":
    'Det var så det! Din Krypto Opsparing vil fortsætte som normalt.',
  'The account has insufficient funds': 'Kontoen har utilstrækkelige midler',
  'The amount you give away is deducted directly from your cryptocurrency from your main account with Firi and locked until the gift card is redeemed by the recipient.':
    'Beløbet du giver væk trækkes direkte fra din kryptovaluta fra din hovedkonto hos Firi og låses indtil gavekortet er indløst af modtageren.',
  'The assets will be transferred to your main account.': 'Værdiene overføres til din hovedkonto.',
  "The buy was automatically stopped. This happens, for example, when there are large price fluctuations. We don't want you to pay more than necessary for the crypto you want to buy.":
    'Købet blev automatisk stoppet. Det sker for eksempel, når der er store prisudsving. Vi ønsker ikke, at du skal betale mere end nødvendigt for den krypto, du ønsker at købe.',
  'The buy was not completed, but the money ({0}) has been transferred to your Firi account.': [
    'Købet blev ikke gennemført, men pengene (',
    ['0'],
    ') er blevet overført til din Firi-konto.',
  ],
  'The calculations are continuously updated based on the transactions and changes made to your account. This means that this is not the final tax calculation.':
    'Beregningerne opdateres løbende baseret på de transaktioner og ændringer, der er foretaget på din konto. Det betyder, at dette ikke er den endelige skatteberegning.',
  'The campaign last from {0} until {1}': ['Kampagnen løber fra den ', ['0'], ' til den ', ['1']],
  'The change only applies to new deposits from 2025 – you can still edit the purchase price for previous years.':
    'Ændringen gælder kun for nye indskud fra 2025 – du kan stadig redigere købesummen for tidligere år.',
  'The christmas calendar starts 1st December - keep an eye out!':
    'Julekalenderen starter den 1. december - hold øje!',
  'The crypto you send out of Firi is not included in the tax calculation.':
    'Krypto, du sender ud af Firi, er ikke inkluderet i skatteberegningen ',
  'The cryptocurrency XLM, also known as Stellar Lumens, is a digital currency that operates on the Stellar network. The Stellar network is a decentralized platform designed for fast and affordable transfer of value across the globe. XLM functions as a means of payment on the Stellar network and is used to facilitate transactions across different currencies and national borders.':
    'Kryptovalutaen XLM, også kendt som Stellar Lumens, er en digital valuta, der opererer på Stellar-netværket. Stellar-netværket er en decentraliseret platform designet til hurtige og håndterbare overførsler af værdi på tværs af hele verden. XLM fungerer som betalingsmiddel på Stellar-netværket og bruges til at lette transaktioner på tværs af forskellige valutaer og landegrænser.',
  'The cryptocurrency is usually sent immediately.': 'Kryptovalutaen sendes normalt med det samme.',
  'The depositor is a company/organization': 'Indskyderen er en virksomhed/organisation',
  'The development shows how your staking account investment develops over time.':
    'Udviklingen viser, hvordan din investering på din staking-konto udvikler sig over tid.',
  'The different percentages you choose for the different cryptocurrencies must add up to 100':
    'De forskellige procentsatser, du vælger for de forskellige kryptovalutaer, skal give 100 tilsammen',
  'The distribution exceeds 100%': 'Fordelingen overstiger 100 %',
  'The documentation you upload will of course be processed as confidential.':
    'Den dokumentation du uploader vil naturligvis blive behandlet fortroligt.',
  'The easiest explanation of staking is that you store, and sometimes lock, your cryptocurrency in order to receive a reward in the form of more crypto.':
    'Den nemmeste forklaring på staking er at du gemmer og nogle gange låser dine kryptovalutaer for at modtage belønning i form af mere krypto.',
  'The email registered to your Vipps has been found in our systems and cannot be reused. Please login to Firi and integrate Vipps through settings.':
    'E-mailen, der er registreret til din Vipps, er fundet i vores systemer og kan ikke genbruges. Log venligst ind på Firi og integrer Vipps gennem indstillinger.',
  'The fees are deducted from the amount of crypto you are selling.':
    'Gebyrerne trækkes fra det krypto beløb, du sælger.',
  'The fees are deducted from the amount you buy crypto for.':
    'Gebyrerne trækkes fra det beløb, du køber krypto for.',
  'The funds will be transferred to your staking account.':
    'Midlerne vil blive overført til din staking-konto.',
  'The information from this survey will only be used to obtain a better picture of our customers needs and wishes regarding services and information, and will not be used in connection with marketing.':
    'Oplysningerne fra denne undersøgelse vil kun blive brugt til at få et bedre billede af vores kunders behov og ønsker vedrørende ydelser og information, og vil ikke blive brugt i forbindelse med markedsføring.',
  'The information we collect about you is treated confidentially. Read more about what information we store and why in our':
    'De oplysninger vi indsamler om dig, behandles fortroligt. Læs mere om, hvilke oplysninger vi gemmer og hvorfor i vores',
  'The largest Nordic crypto exchange': 'Nordens største kryptobørs',
  'The main purpose of XRP is to be a kind of broker between different currencies - both cryptocurrency and fiat. XRP is built on the blockchain technology XRP Ledger.':
    'Hovedformålet med XRP er at være en slags mægler mellem forskellige valutaer – både kryptovaluta og fiat. XRP er bygget på blockchain-teknologien XRP Ledger.',
  "The majority of all cryptocurrencies and decentralized applications in the world are built on Ethereum's blockchain.":
    'Størstedelen af alle kryptovalutaer og decentraliserede applikationer i verden er bygget på Ethereums blockchain.',
  'The minimum amount for withdrawal is {0}. In order to withdraw money, you must sell crypto.': [
    'Minimumsbeløbet for udbetaling er ',
    ['0'],
    '. For at hæve penge skal du sælge krypto.',
  ],
  'The minimum amount to stake {currency} is {0} {currency}. You can buy {currency} immediately': [
    'Minimumsbeløbet for at stake ',
    ['currency'],
    ' er ',
    ['0'],
    ' ',
    ['currency'],
    '. Du kan købe ',
    ['currency'],
    ' med det samme',
  ],
  'The minimum amount you can save in a cryptocurrency is {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0}':
    [
      'Det mindste beløb, du kan spare i en kryptovaluta, er ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
    ],
  'The money is being transferred to us': 'Pengene bliver overført til os',
  'The money is on its way from your bank to our bank. This usually takes 1-2 business days, but it can also be transferred in minutes.':
    'Pengene er på vej fra din bank til vores bank. Dette tager normalt 1-2 hverdage, men det kan også overføres på få minutter.',
  'The money may have been deducted from your account.': 'Pengene kan være trukket fra din konto.',
  'The most common destinations': 'De mest almindelige destinationer',
  'The numbers are adjusted continuously based on your account activity.':
    'Tallene justeres løbende ud fra din kontoaktivitet.',
  'The numbers are adjusted continuously based on your transactions.':
    'Talene justeres kontinuerligt baseret på dine transaktioner.',
  'The one-time password has already been used.': 'Engangs-adgangskoden er allerede blevet brugt.',
  'The price differentiate more than approx. <0>{absolutePercent}%</0> compared to the international price index. The reason is high demand and few people want to sell {currency} right now. That is why we have a blockage to avoid you buying for an expensive price.':
    [
      'Prisen adskiller sig med mere end ca. <0>',
      ['absolutePercent'],
      ' %</0> sammenlignet med det internationale prisindeks. Årsagen er stor efterspørgsel, og få mennesker ønsker at sælge ',
      ['currency'],
      ' lige nu. Derfor har vi en blokering for at undgå, at du køber for dyrt.',
    ],
  'The price differentiate more than approx. <0>{absolutePercent}%</0> compared to the international price index. The reason is low demand and few people want to buy {currency} right now. That is why we have a blockage to avoid you selling too cheap.':
    [
      'Prisen adskiller mere end ca. <0>',
      ['absolutePercent'],
      ' %</0> sammenlignet med det internationale prisindeks. Årsagen er lav efterspørgsel, og at få mennesker ønsker at købe ',
      ['currency'],
      ' lige nu. Derfor har vi en blokering for at undgå, at du sælger for billigt.',
    ],
  'The price is locked in during the trade.': 'Prisen fastlåses når handlen gennemføres.',
  'The price is too high': 'Prisen er for høj',
  'The price is too low': 'Prisen er for lav',
  'The price is way above the index':
    'Prisen du handler for er et godt stykke over det globale prisindeks',
  'The processing time is dependent on the amount you are unstaking and how many others that are wanting to deactivate staking.':
    'Behandlingstiden afhænger af, hvor stort dit beløb er, og hvor mange andre, der ønsker at deaktivere indsatsen.',
  'The products of their Ripple Labs are used for payment settlements, asset exchanges and transfer systems - including by several large banks.':
    'Produkterne fra deres Ripple Labs bruges til betalingsafviklinger, valuta veksling og overførselssystemer – og ligeledes af flere store banker.',
  'The purpose of this reward is simply to share knowledge about cryptocurrency and Firi. You get a regular referral bonus in addition 🤯':
    'Formålet med denne belønning er ganske enkelt, at dele viden om cryptocurrency og om Firi. Du får også den sædvanlige belønning for at invitere venner🤯',
  'The purpose of this reward is to share knowledge about crypto and invite others to discover a new way of investing.':
    'Formålet med denne belønning er at dele viden om krypto og at invitere andre til at opdage en ny måde at investere på.',
  'The recipient is a business': 'Modtageren er en virksomhed',
  'The reward is what you have received from the blockchain as a thank you for staking. Note that the value changes with the crypto prices.':
    'Belønningen er det, du har modtaget fra blockchainen, som tak for at stake. Bemærk, at værdien ændrer sig i takt med kryptopriserne.',
  'The tax calculation for {0} is ready!': ['Skatteberegningen for ', ['0'], ' er klar!'],
  'The tax calculation for {mostRecentTaxYear} is ready!': [
    'Skatteberegningen for ',
    ['mostRecentTaxYear'],
    ' er klar!',
  ],
  "The tax calculation will be available when it's time to calculate the tax for 2022.":
    'Skatteberegningen bliver tilgængelig når du skal regne ud skatten for 2022.',
  'The total price is too low': 'Den samlede pris er for lav',
  'The trading fee will be deducted from the amount.':
    'Handelsgebyr pålægges og trækkes fra dette beløb.',
  'The transaction is usually sent immediately.': 'Transaktionen sendes normalt med det samme.',
  'The transaction was not completed.': 'Transaktionen gik ikke igennem.',
  'The value of meme coins comes from viral spread on the internet, hype culture, and humor. Initially, they do not aim to innovate, develop, or improve current financial systems':
    'Værdien af meme-coins kommer fra viral spredning på internettet, hypekultur og humor. De forsøger i første omgang ikke at innovere, udvikle eller forbedre nutidens finansielle systemer.',
  'The value of meme coins follows market cycles': 'Værdien af meme-coins følger markedscyklusser',
  'The value of meme coins is driven by viral posts on the internet':
    'Værdien af meme-coins er drevet af virale indlæg på internettet',
  'The winner will be contacted by our support!. See you tomorrow!':
    'Vinderen af dagens trækning kontaktes af vores support. Vi ses i morgen!',
  'The withdraw is processing': 'Udbetalingen behandles',
  'The withdrawal fee is': 'Udbetalingsgebyret er',
  'The withdrawal limit is renewed 24 hours from the withdrawal was made.':
    'Udbetalingsgrænsen fornyes 24 timer efter udbetalingen er foretaget.',
  'The word "crypto" comes from the concept of cryptography, which is central to blockchain technology.':
    'Ordet "krypto" stammer fra konceptet kryptografi, der er centralt i blockchain-teknologien.',
  Theme: 'Tema',
  'There are different ways of calculating profit and loss. We use the most common method, which excludes deposits and withdrawals from the calculation. In short, we divide the calculation period into intervals (minutes, days etc.), calculate the rate of return for each intervals, and chain these together to find the total rate of return for the entire calculation period.':
    'Der er forskellige måder at beregne gevinst og tab på. Vi bruger den mest almindelige metode, som udelukker ind- og udbetalinger fra beregningen. Kort fortalt opdeler vi beregningsperioden i intervaller (minutter, dage osv.), beregner afkastningsgraden for hvert intervaller og kæder disse sammen for at finde den samlede afkastningsgrad for hele beregningsperioden.',
  'There are many people buying and selling cryptocurrencies right now, which is leading to a constant change in price. To be certain that you order is correct, we ask you to place the order again.':
    'Der er mange mennesker, der køber og sælger kryptovaluta lige nu, hvilket medfører konstante ændringer i prisen. For at være sikker på, at din ordre er korrekt, beder vi dig afgive ordren igen.',
  'There are no orders in this market': 'Der er ingen ordrer i dette marked',
  'There are two reasons to buy and hold the cryptocurrency MANA. One is as a means for transactions within the game. The other is if one wants to invest in MANA because they believe Decentraland will be a major success or because they want to speculate in the crypto-gaming niche.':
    'Der er to grunde til at købe og beholde kryptovalutaen MANA. Den ene er som et middel til transaktioner inden i spillet. Den anden er, hvis man ønsker at investere i MANA, fordi man tror, at Decentraland vil blive en stor succes, eller fordi man ønsker at spekulere i krypto-gaming nichen.',
  'There are two reasons to buy and hold the cryptocurrency SAND. One is as a means for transactions within the game. The other is if one wants to invest in SAND because they believe The Sandbox will be a major success or because they want to speculate in the crypto-gaming niche.':
    'Der er to grunde til at købe og beholde kryptovalutaen SAND. Den ene er som et middel til transaktioner inden i spillet. Den anden er, hvis man ønsker at investere i SAND, fordi man tror, at The Sandbox vil blive en stor succes, eller fordi man ønsker at spekulere i krypto-gaming nichen.',
  'There is a new update!': 'Der er en ny opdatering!',
  'There is an age limit of 18 to create an account with Firi. We warmly welcome you back when you turn 18!':
    'Der er en aldersgrænse på 18 for at oprette en konto hos Firi. Vi byder dig hjertelig velkommen tilbage, når du fylder 18!',
  "There is one thing that many Firi customers have asked for in the app, and that's an easy and intuitive way to see their rate of return; profits and losses on their crypto investment.":
    'Der er én ting, som mange Firi-kunder har bedt om i appen, og det er en nem og intuitiv måde at se deres afkast på; overskud og tab på deres kryptoinvestering.',
  'These are crypto based on memes and internet culture':
    'Disse er krypto baseret på memes og internetkultur',
  'These are crypto that are considered more environmentally friendly than other cryptos':
    'Disse er krypto, som anses for at være mere miljøvenlige end andre kryptovalutaer',
  'These are crypto that are used in decentralized finance':
    'Disse er krypto, som bruges i decentraliseret finans',
  'These are crypto that use proof-of-work consensus mechanism and therefore can be mined':
    'Disse er krypto, som bruger proof-of-work konsensusmekanisme og derfor kan mines',
  'These are crypto that you can stake and earn rewards on with Firi':
    'Dette er de kryptovalutaer, som du kan stake og tjene belønninger på hos Firi',
  'These are cryptos that follow the value of a regular currency, and therefore have a more stable price':
    'Disse er krypto, der følger værdien af almindelige valutaer, og derfor har en mere stabil pris.',
  'These are the crypto most people choose to start with on Firi':
    'Disse er krypto, som flest vælger at starte med hos Firi',
  'These cookies collect information about how you use our websites and what that is interesting to you. In this way, we can provide you with relevant content and personalized marketing.':
    'Disse cookies indsamler information om, hvordan du bruger vores hjemmesider, og hvad der er interessant for dig. På den måde kan vi give dig relevant indhold og personlig markedsføring.',
  'These cookies collect statistics about how our websites are used. This gives us valuable insight that allows us to improve and your customer experience.':
    'Disse cookies indsamler statistik om, hvordan vores hjemmesider bruges. Dette giver os værdifuld indsigt, der giver os mulighed for at forbedre os selv og din kundeoplevelse.',
  'This account has Vipps integration.': 'Denne konto har Vipps-integration.',
  'This account number is used by a bank not supported by Firi.':
    'Dette kontonummer bruges af en bank, der ikke understøttes af Firi.',
  'This content is for information purposes only and should not be taken as financial advice.':
    'Dette indhold er kun til informationsformål og skal ikke opfattes som finansiel rådgivning.',
  'This deposit is a payment from mining, staking or other income':
    'Dette indskud er en betaling fra mining, staking eller anden indkomst',
  'This helps us protect your account, detect unusual activity and comply with regulations.':
    'Dette hjælper os med at beskytte din konto, opdage usædvanlig aktivitet og overholde regler.',
  'This is a market order.': 'Dette er en markedsordre.',
  'This is a trade executed directly with Firi.': 'Dette er en handel gennemført direkte med Firi.',
  "This is because you have more in gain than loss. We've figured this out for you.":
    'Dette skyldes, at du har mere i gevinst end tab. Vi har fundet ud af det for dig.',
  'This is how the development of the portfolio is displayed': 'Sådan vises porteføljens udvikling',
  'This is often compared to putting money in the bank to receive interest, but there are still big, fundamental differences.':
    'Dette sammenlignes ofte med at sætte penge ind i banken for at få renter, men alligevel er der store, fundamentale forskelle.',
  'This is the information you have provided to Firi. Verify that these settings match with the recurring transfer you have set up in you bank.':
    'Dette er de oplysninger, du har givet til Firi. Kontroller, at disse indstillinger stemmer overens med den tilbagevendende overførsel, du har sat op i din bank.',
  'This is your total crypto fortune at Firi as of 31.12.{year}': [
    'Dette er din samlede kryptoformue hos Firi pr. 31.12.',
    ['year'],
  ],
  'This is your total crypto income at Firi as of 31.12.{year}': [
    'Dette er din samlede kryptoindkomst hos Firi pr. 31.12.',
    ['year'],
  ],
  'This is your total crypto loss at Firi as of 31.12.{year}': [
    'Dette er dit samlede kryptotab hos Firi pr. 31.12.',
    ['year'],
  ],
  'This is your total crypto profit at Firi as of 31.12.{year}': [
    'Dette er din samlede kryptofortjeneste hos Firi pr. 31.12.',
    ['year'],
  ],
  'This is your total crypto<0/> at Firi per': 'Dette er din samlede krypto<0/> hos Firi pr',
  "This is your {0} balance. Expected annual yield is {1}% and hence <0/> at today's {2} exchange rate.":
    [
      'Dette er din ',
      ['0'],
      '-beholdning. Forventet årligt afkast er ',
      ['1'],
      '% og dermed <0/> ved dagens ',
      ['2'],
      '-kurs.',
    ],
  'This month': 'Denne måned',
  'This security measure is necessary.': 'Denne sikkerhedsforanstaltning er nødvendig.',
  'This version of the Firi-app is no longer available. You can download the newest version of the app in {store} now':
    [
      'Denne version af Firi-appen er ikke længere tilgængelig. Du kan downloade den nyeste version af appen i ',
      ['store'],
      ' nu',
    ],
  'This vipps account is already registered to Firi.':
    'Denne vipps-konto er allerede registreret til Firi.',
  'Thuc and the rest of the Firi team': 'Thuc og resten af Firi-holdet',
  'Tips for the best Firi-experience': 'Tips til den bedste Firi-oplevelse',
  To: 'Til',
  'To (year)': 'Til (år)',
  'To access the meme coins category and the opportunity to invest in {currency}, you first need to know what this entails.':
    [
      'For at få adgang til meme-coins-kategorien og mulighed for at investere i ',
      ['currency'],
      ', skal du først vide, hvad det indebærer.',
    ],
  'To account': 'Til konto',
  'To activate 2FA, you need to have an app that generates 2FA codes. We recommend Google Authenticator, but all 2FA apps work with Firi.':
    'For at aktivere 2FA skal du have en app, der genererer 2FA-koder. Vi anbefaler Google Authenticator, men alle 2FA-apps fungerer med Firi.',
  'To bank account': 'Til bankkonto',
  'To become a customer with us, all information must be correct and verifiable. Therefore, it is not possible to have a secret name or use pseudonyms. We require full legal names to ensure compliance with anti-money laundering regulations and protect against fraud.\\n\\nThis practice creates a safe and secure experience for all our customers. Thank you for your understanding, and we look forward to welcoming you.':
    'For at blive kunde hos os, skal alle oplysninger være korrekte og verificerbare. Derfor kan man ikke have et hemmeligt navn eller bruge pseudonymer. Vi kræver fulde, juridiske navne for at sikre overholdelse af hvidvasklovgivningen og beskytte mod svindel.\\n\\nDenne praksis skaber en tryg og sikker oplevelse for alle vores kunder. Tak for din forståelse, og vi ser frem til at byde dig velkommen.',
  'To comply with international regulations, we need to collect certain information when you send or receive cryptocurrency. This rule, called the "Travel Rule", ensures that both the sender\'s and recipient\'s information is secure and traceable. It is designed to protect against fraud and enhance transaction security between platforms.':
    'For at overholde internationale regler skal vi indsamle visse oplysninger, når du sender eller modtager kryptovaluta. Denne regel, kaldet "Travel Rule", sikrer, at både afsenderens og modtagerens information er sikker og sporbar. Den er designet til at beskytte mod svindel og forbedre transaktionssikkerheden mellem platforme.',
  'To comply with legal requirements and protect your account, we need your CPR number again. Although you have already provided it via MitID, we kindly ask you to enter it here.':
    'For at opfylde lovkrav og beskytte din konto skal vi bruge dit CPR-nummer igen. Selvom du allerede har angivet det via MitID, beder vi dig venligst indtaste det her.',
  'To comply with regulatory requirements, we need more details about the recipient before your withdrawal can be completed.':
    'For at opfylde regulatoriske krav har vi brug for flere detaljer om modtageren, før din udbetaling kan afsluttes.',
  'To comply with the Travel Rule, we need some information about the sender. If all deposits come from the same sender, you can verify them at the same time.':
    'For at overholde regelverket (Travel Rule) trenger vi litt info om avsenderen. Hvis alle innskudd kommer fra samme avsender, kan du bekrefte dem samtidig.',
  "To comply with the new EU regulation (MiCA), all Danish customers will be transferred to Firi's Danish subsidiary, Firi ApS. To regain full access to your account, you must accept this change.":
    'For at overholde ny EU regulering (MICA) flyttes alle danske kunder til Firis danske datterselskab Firi ApS. For at få fuld adgang til din konto igen skal du derfor acceptere denne ændring.',
  'To delete your account, contact support.': 'For at slette din konto, kontakt support.',
  'To disable your authenticator, please enter the two factor token from your app.':
    'For at deaktivere din autentificering, skal du indtaste to-faktor-koden fra din app.',
  'To ensure that you understand the risk involved when investing in meme coins you will have to answer a few questions.':
    'For at sikre os, at du forstår risiciene ved at investere i meme-coins, skal du besvare et par spørgsmål.',
  'To ensure your security, we require that you activate and use two-factor authentication (2FA) when you register and log in with your username and password.':
    'For at sikre din Firi konto kræver vi, at du aktiverer og bruger to-faktor autentificering (2FA), når du registrerer dig og logger ind med dit brugernavn og adgangskode.',
  'To include destination tag when sending {0}': [
    'At inkludere destinations tag ved afsendelse af ',
    ['0'],
  ],
  'To main account': 'Til hovedkonto',
  'To make a deposit to your savings account you first need to have a savings agreement, so that we know how to invest your funds':
    'For at foretage en indbetaling til din opsparingskonto, skal du først oprette en opsparingsaftale, så vi ved, hvordan vi skal investere dine midler.',
  'To overview': 'Til overblik',
  'To proceed creating an account, the following requirements must be fullfilled:':
    'For at fortsætte med at oprette en konto, skal følgende krav være opfyldt:',
  'To receive push notifications from Firi, you need to have enabled notifications in your device settings.':
    'For at modtage notifikationer fra Firi skal du aktivere notifikationer i indstillingerne på din enhed.',
  'To receive the reward of {0} {1} in {2}, you need to refer at least <0>{3} friends</0> to Firi and they must <1>make their first investment</1> with us before January 1st.':
    [
      'For at modtage belønningen på ',
      ['0'],
      ' ',
      ['1'],
      ' i ',
      ['2'],
      ', skal du henvise mindst <0>',
      ['3'],
      ' venner</0> til Firi, og de skal <1>gennemføre deres første investering</1> hos os inden den 1. januar.',
    ],
  'To receive the reward, you need to refer at least {0} friends to Firi and they must feel comfortable enough with crypto and Firi to make their first trade.':
    [
      'For at modtage belønningen skal du henvise mindst ',
      ['0'],
      ' venner til Firi, og de skal føle sig trygge nok med krypto og Firi til at foretage deres første handel.',
    ],
  'To savings account': 'Til opsparingskonto',
  'To sell crypto from your savings account you must first make an internal transfer to your main account.':
    'For at sælge krypto fra din opsparingskonto skal du først foretage en intern overførsel til din hovedkonto.',
  'To set the exchange rate, we use a global price index. We add a markup of 1-3 percent based on the market situation, volatility, and the amount of crypto traded on Firi.':
    'For at fastsætte vekslingskursen benytter vi et globalt prisindeks. Der lægges 1-3 procent ovenpå prisen, baseret på markedssituationen, volatiliteten samt hvor meget krypto der handles hos Firi.',
  'To sign up with MitId': 'For at tilmelde dig MitId',
  'To sign up with Vipps': 'For at tilmelde dig Vipps',
  'To staking account': 'Til staking-konto',
  'To what extent do you agree with the following statement: "I felt I had knowledge about crypto before I started using Firi".':
    'I hvor høj grad er du enig i følgende udsagn: "Jeg følte, at jeg havde viden om krypto, før jeg begyndte at bruge Firi".',
  'To {storeVersion}': ['Til ', ['storeVersion']],
  Today: 'I dag',
  'Today, {0}': ['I dag, ', ['0']],
  'Toggle rate or amount': 'Skift sats eller beløb',
  'Too good to be true? No. There is a good reason why you receive this reward.':
    'For godt til at være sandt? Nej. Der er en god grund til, at du modtager denne belønning.',
  'Top 10': 'Top 10',
  'Top 10 crypto on Firi': 'Top 10 kryptovalutaer hos Firi',
  'Top 10 cryptos with the highest trading volume the last 24 hours':
    'Top 10 kryptovalutaer med højeste handelsvolumen de seneste 24 timer',
  'Top up Firi-account': 'Indsæt på din Firi-konto',
  'Top up your account': 'Indsæt på din konto',
  Total: 'Total',
  'Total amount': 'Total beløb',
  'Total balance': 'Samlet saldo',
  'Total paid out': 'Udbetalt i alt',
  'Total pending': 'Afventer, i alt',
  'Total price': 'Total pris',
  'Total referrals': 'Samlet antal invitationer',
  'Total reward for staked crypto': 'Samlet belønning for staked krypto',
  'Trade for more than {0} {currency}': ['Handle for mere end ', ['0'], ' ', ['currency']],
  'Trade for more than {0} {currency} in all markets': [
    'Handle for mere end ',
    ['0'],
    ' ',
    ['currency'],
    ' på alle markeder',
  ],
  'Trade for over {0} {currency}': ['Handle for over ', ['0'], ' ', ['currency']],
  'Trade for over {0} {currency} in three markets': [
    'Handle for over ',
    ['0'],
    ' ',
    ['currency'],
    ' på tre markeder',
  ],
  'Trade history': 'Handelshistorie',
  Trades: 'Handler',
  'Trades on your savings account': 'Handler på din opsparingskonto',
  'Trading fee is 0.7 percent.': 'Handelsgebyret er 0,7 procent.',
  'Trading fee is 0.7%.': 'Handelsgebyret er 0,7%.',
  'Trading fee is {0} percent.': ['Handelsgebyret er ', ['0'], ' procent.'],
  'Trading fees are required to cover our costs.':
    'Handelsgebyrer er påkrævet for at dække vores omkostninger.',
  'Trading fees for buying and selling crypto are already included in profit and loss and do not need to be reported separately.':
    'Handelsgebyrer for køb og salg af krypto er allerede inkluderet i overskud og tab og behøver ikke at indrapporteres separat.',
  'Trady history from another platform': 'Trady historie fra en anden platform',
  'Transaction ID': 'Transaktions ID',
  'Transaction fee (optional)': 'Transaktionsgebyr (valgfrit)',
  Transactions: 'Transaktioner',
  'Transactions cannot be reversed': 'Transaktioner kan ikke tilbageføres',
  Transfer: 'Overførsel',
  'Transfer completed': 'Overførslen gennemført',
  'Transfer crypto between main account and savings account':
    'Overføre krypto mellem hovedkonto og opsparingskonto',
  'Transfer crypto here': 'Modtag krypto her',
  'Transfer via online banking': 'Overførsel via netbank',
  'Transferred and activated!': 'Overført og aktiveret!',
  Transfers: 'Overførsler',
  'Transfers between Firi and external crypto wallets usually takes a couple of minutes. In some cases it can take a business day before the cryptocurrency arrives':
    'Overførsler mellem Firi og eksterne krypto-wallets tager normalt et par minutter. I nogle tilfælde kan det tage en hverdag, før kryptovalutaen ankommer',
  'Transfers to and from savings account': 'Overførsler til og fra opsparingskonto',
  Trends: 'Trender',
  'Try again': 'Prøv igen',
  'Try again or contact support if the issue persists.':
    'Prøv igen, eller kontakt support, hvis problemet fortsætter.',
  'Try now': 'Prøv nu',
  'Turn on notifications': 'Slå notifikationer til',
  'Turn on notifications if you want us to give you a ping about the latest news in the crypto world, campaigns, when we launch new cryptos in the app and other exciting news.':
    'Slå notifikationer til, hvis du vil have os til at give dig besked om de seneste nyheder i kryptoverdenen, kampagner, når vi lancerer nye kryptoer i appen og andre spændende nyheder.',
  'Two factor authentication': 'To-faktor autentificering',
  'Two step verification': 'Totrinsbekræftelse',
  'Two-factor authentication (2FA) is an additional layer of security in addition to passwords when you log in to various services, including Firi.':
    'To-faktor autentificering (2FA) er et ekstra lag af sikkerhed ud over adgangskoder, når du logger på forskellige tjenester, herunder Firi.',
  'Two-factor authentication disabled': 'To-faktor-godkendelse deaktiveret',
  'Two-factor authentication enabled': 'To-faktor-godkendelse aktiveret',
  'Two-factor authentication is already set for this user':
    'To-faktor-godkendelse er allerede indstillet for denne bruger',
  'Two-factor authentication is an important security measure. Using this makes it much harder for people to access your account by guessing your password. Creating passwords that are sufficiently unique, long and strong can be a challenge, so an extra layer of security is absolutely necessary for most people to avoid cybercrime.':
    'To-faktor autentificering er en vigtig sikkerhedsforanstaltning. Brug af dette gør det meget sværere for folk at få adgang til din konto ved at gætte din adgangskode. At skabe adgangskoder, der er tilstrækkeligt unikke, lange og stærke kan være en udfordring, så et ekstra lag af sikkerhed er absolut nødvendigt for de fleste for at undgå cyberkriminalitet.',
  Type: 'Type',
  'Type here...': 'Skriv her...',
  'Type in the code from your authentication app': 'Indtast koden fra din 2FA app',
  'Type in your personal code to open the Firi app. It contains six digits.':
    'Indtast din personlige kode for at åbne Firi-appen. Den indeholder seks cifre.',
  Types: 'Typer',
  'UNI is the associated cryptocurrency of Uniswap. UNI serves as a "governance token" that enables users to participate in decisions regarding the future development of the Uniswap exchange.':
    'UNI er den tilknyttede kryptovaluta til Uniswap. UNI fungerer som en "styrings-token", der giver brugerne mulighed for at deltage i beslutninger om den fremtidige udvikling af Uniswap-børsen.',
  'US dollars': 'US dollars',
  'USD Coin (USDC) is a centralized cryptocurrency linked to the US dollar. That is, it aims to maintain a stable 1:1 value against the USD, and is thus a so-called "stablecoin".':
    'USD Coin (USDC) er en centraliseret kryptovaluta knyttet til den amerikanske dollar. Det vil sige, at den har til formål at opretholde en stabil 1:1 værdi over for USD, og er dermed en såkaldt "stablecoin".',
  'USDC is an ERC-20 token and is thus built on the Ethereum blockchain. USD Coin was launched in September 2018 as a collaboration between Circle and Coinbase. Like other stablecoins, the USDC can be defined as a tool for tokenizing US dollars. By moving US dollars to the blockchain, you can easily send them anywhere in the world in minutes and in addition it contributes to stability in a volatile crypto market.':
    'USDC er et ERC-20 token og er således bygget på Ethereum blockchain. USD Coin blev lanceret i september 2018 som et samarbejde mellem Circle og Coinbase. Ligesom andre stablecoins kan USDC defineres som et værktøj til tokenisering af amerikanske dollars. Ved at flytte amerikanske dollars til blockchainen kan du nemt sende dem overalt i verden på få minutter og derudover bidrager det til stabilitet i et volatilt kryptomarked.',
  'Unable to turn on notifications': 'Kunne ikke aktivere notifikationer',
  'Unable to verify your password reset claim. Are you sure you arrived here by following an email link?':
    'Kan ikke bekræfte dit krav om nulstilling af adgangskode. Er du sikker på, at du ankom her ved at følge et e-mail-link?',
  'Understand crypto': 'Forstå krypto',
  'Unfortunately, this process is only supported on a desktop or laptop browser. Please access our web platform from a computer to complete the verification.':
    'Desværre understøttes denne proces kun i en browser på en stationær eller bærbar computer. Log venligst ind på vores webplatform fra en computer for at fuldføre bekræftelsen.',
  "Uniswap is the world's largest and most well-known decentralized cryptocurrency exchange (also known as DEX). Uniswap operates on blockchains such as Ethereum, Polygon, BNB and more, and allows users from anywhere in the world to trade cryptocurrencies without any intermediary.":
    'Uniswap er verdens største og mest kendte decentraliserede kryptobørs (også kaldet DEX). Uniswap opererer på blockchains som Ethereum, Polygon, BNB med flere og giver brugere overalt i verden mulighed for at handle med kryptovaluta uden nogen form for mellemmand.',
  Unknown: 'Ukendt',
  'Unknown error': 'Ukendt fejl',
  'Unknown purchase price': 'Ukendt købspris',
  'Unlock Firi': 'Lås Firi op',
  Unstake: 'Unstake',
  Unstaked: 'Unstaked',
  Unstaking: 'Unstaking',
  'Unstaking completed!': 'Unstaking færdig!',
  "Until New Year's you get an extra reward for referring at least {0} friends to Firi.": [
    'Indtil nytårsaften får du ekstra belønning for at invitere mindst ',
    ['0'],
    ' venner til Firi.',
  ],
  Update: 'Opdatering',
  'Update account information': 'Opdater dine kontooplysninger',
  'Update distribution': 'Oppdater fordeling',
  'Update the app in Google Play Store to download your tax documents':
    'Opdater appen i Google Play Store for at downloade dine skattedokumenter',
  'Update the app in the App Store to download your tax documents':
    'Opdater appen i App Store for at downloade dine skattedokumenter',
  'Update your transfer/payment with the account information below:':
    'Opdater din overførsel/betaling med kontooplysningerne nedenfor:',
  'Updated terms': 'Opdaterede vilkår',
  'Upload documentation': 'Upload dokumentation',
  'Upload file': 'Upload fil',
  'Upload profile picture': 'Upload profilbillede',
  'Upload relevant documentation here': 'Upload relevant dokumentation her',
  'Upload your tax report to Firi': 'Upload din skatterapport til Firi',
  'Uploaded document are stored under': 'Uploadet dokument gemmes under',
  'Uploaded documentation': 'Uploadet dokumentation',
  'Upon registration, you will receive a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.':
    'Ved registrering modtager du en velkomstbonus. Hos os kan du købe og sælge bitcoin og andre kryptovalutaer, sikkert og nemt. Vi er registreret hos Finanstilsynet, har login med MitID og global bank til ind- og udbetalinger.',
  'Upon registration, you will receive bitcoin as a welcome bonus. With us you can buy and sell bitcoin and other cryptocurrencies, safely and easily. We are registered with Finanstilsynet, have Vipps login, use BankID and Norwegian bank for deposits and withdrawals.':
    'Ved registrering modtager du bitcoin som en velkomstbonus. Hos os kan du købe og sælge bitcoin og andre kryptovalutaer, sikkert og nemt. Vi er registreret hos Finanstilsynet, har login med MitID og global bank til ind- og udbetalinger.',
  'Use a two-factor authentication app such as': 'Brug en to-faktor autentificeringsapp som f.eks',
  'Use of cookies': 'Brug af cookies',
  'Use of wrong network or address kan lead to permanently loss of funds':
    'Brug af forkert netværk eller adresse kan føre til permanent tab af midler',
  'Use the search field to find the card for "Other financial products and virtual currency/cryptocurrency"':
    'Brug søgefeltet til at finde kortet til "Andre finansielle produkter og virtuel valuta/kryptovaluta"',
  Used: 'Brugt',
  'User cancelled the session.': 'Brugeren aflyste sessionen.',
  'User provided is already registered in Firi.':
    'Den angivne bruger er allerede registreret i Firi.',
  'User transfer': 'Brugeroverførsel',
  'Valuable insight': 'Værdifuld indsigt',
  Value: 'Værdi',
  'Value (crypto)': 'Værdi (krypto)',
  'Value ({0})': ['Værdi (', ['0'], ')'],
  'Value in {0}': ['Værdi i ', ['0']],
  'Value of 1 {0} at the time of the purchase': ['Værdi af 1 ', ['0'], ' på købstidspunktet'],
  'Value you are sending': 'Værdi du sender',
  Verification: 'Verifikation',
  'Verification failed. Are you sure you typed the correct code for Firi?':
    'Bekræftelse mislykkedes. Er du sikker på, at du har indtastet den rigtige kode til Firi?',
  Verify: 'Verificere',
  'Verify all': 'Bekræft alle',
  'Verify your account using BankID': 'Bekræft din konto med BankID',
  'Verify your email': 'Bekræft din e-mail',
  Version: 'Version',
  'Version:': 'Version:',
  'Very low level of knowledge': 'I meget lille omfang',
  Vipps: 'Vipps',
  'Vipps fee': 'Vipps-gebyr',
  'Vipps integration': 'Vipps integration',
  'Vipps signup not available': 'Vipps-tilmelding er ikke tilgængelig',
  "Vipps, and it's done!": 'Vipps, og det er gjort!',
  Volume: 'Volumen',
  'Volume (24h)': 'Volum (24h)',
  Voucher: 'Voucher',
  'Wallet signing': 'Wallet-signering',
  'Wallet transfers': 'Wallet-overførsler',
  'Want the newest version?': 'Det tager kun et kort øjeblik!',
  "Want to delete your account? No hassle! Talk to our support team and we'll take care of the rest.":
    'Vil du slette din konto? Ingen stress! Snakk med vores supportteamet, og vi tager vare på resten.',
  'Was your first crypto trade conducted on Firi?': 'Blev din første kryptohandel udført hos Firi?',
  We: 'Vi',
  'We also ask you to familiarize yourself well with our <0>terms of use</0> before you create an savings account with Firi.':
    'Vi beder dig også om at sætte dig godt ind i vores <0>brugsbetingelser</0>, før du opretter en opsparingskonto hos Firi.',
  'We also ask you to familiarize yourself well with our<0>terms of use</0> before you create an account with Firi.':
    'Vi beder dig også om at sætte dig godt ind i vores<0>brugsbetingelser</0>, før du opretter en konto hos Firi.',
  'We always store your personal information and your documentation securely. You can read more in our <0>terms of service</0> and <1>privacy policy</1>.':
    'Dine personlige oplysninger og din dokumentation er altid sikkert opbevaret hos os. Du kan læse mere om dette i vores <0>brugervilkår</0> og <1>privatlivspolitik</1>. ',
  'We appreciate you taking the time - everything is now updated!':
    'Vi sætter pris på, at du tog dig tid - alt er nu opdateret!',
  'We are always striving to improve and really appreciate you taking the time. Wishing you a lovely summer!':
    'Vi stræber altid efter at forbedre os og sætter stor pris på, at du tager dig tid. Ønsker dig en dejlig sommer!',
  'We are currently experiencing increased activity, which may result in longer processing times for withdrawals. Thank you for your understanding':
    'Vi oplever i øjeblikket øget aktivitet, hvilket kan resultere i længere behandlingstider for udbetalinger. Tak for din forståelse',
  'We are discontinuing gift cards': 'Vi afskaffer gavekort',
  'We are experiencing an increase in scams and investment fraud. Are you in contact with foreign people who are supposed to help you with investments? These often offer high profits and trick or exploit Norwegian people into investing in fake platforms or cryptocurrencies. If this is the case, we recommend that you end all contact with them and contact us via chat.':
    'Vi oplever en stigning i svindel og investeringsbedrageri. Har du kontakt til udenlandske folk, som vil hjælpe dig med investeringer? Disse tilbyder ofte høj profit og udnytter danskere til at investere i falske platforme eller kryptovaluta. Så anbefaler vi, at du afslutter al kontakt med dem og tager direkte kontakt til os via chat.',
  'We are here to help you weekdays between 09:00 and 19:00':
    'Vi er her for at hjælpe dig på hverdage mellem 09:00 og 19:00',
  'We are introducing Stellar (XLM)!': 'Vi introducerer Stellar (XLM)!',
  'We are introducing the meme coin {0} ({1})!': [
    'Vi introducerer memecoinene - ',
    ['0'],
    ' (',
    ['1'],
    ')!',
  ],
  'We are introducing {0} ({1})!': ['Vi introducerer ', ['0'], ' (', ['1'], ')!'],
  'We are missing data from Vipps. Try again or contact support.':
    'Vi mangler data fra Vipps. Prøv igen, eller kontakt support.',
  'We are now deactivating staking on the amount.':
    'Nu deaktiverer vi stakingen for det valgte beløb',
  'We are required by law to inform you if there is a data breach or any other situations where your personal information is at risk. We will do this as quickly as possible.':
    'Vi er ved lov forpligtet til at informere dig, hvis der er et databrud eller andre situationer, hvor dine personlige oplysninger er i fare. Det vil vi gøre så hurtigt som muligt.',
  'We are required by the authorities to gather updated information about you as a customer on a continual basis. As part of our follow-up we kindly ask you to answer some questions in detail and upload relevant information.':
    'Vi er pålagt af myndighederne at indhente information om dig som kunde og vi skal gennemføre løbende opfølgning på kundeforholdet. Som en del af vores opfølgning, beder vi dig svare uddybende på nogle spørgsmål og om at uploade relevant information.',
  'We are sorry, something went wrong.': 'Vi beklager, noget gik galt.',
  'We are waiting for a deposit': 'Vi venter på indskud',
  'We are working on implementing a simple and user-friendly process for unstaking. Thank you for your understanding!':
    'Vi arbejder på at få på plads en simpel og brugervenlig proces for unstaking. Tak for forståelsen!',
  'We are working on improving our tax calculator to ensure correct tax calculations for future tax years. Thank you for your patience.':
    'Vi arbejder på at forbedre vores skattekalkulator for at sikre korrekte skatteberegninger for fremtidige skatteår. Tak for din tålmodighed.',
  'We ask that you familiarize yourself with all of our <0>terms of use</0> before you start staking crypto with Firi.':
    'Vi opfordrer dig til grundigt at læse og sætte dig ind i <0>vilkårne</0> for at stake hos Firi.',
  'We ask you to familiarize yourself well with our <0>terms of use</0> before you create an account with Firi':
    'Vi beder dig om at sætte dig godt ind i vores <0>brugsbetingelser</0>, før du opretter en konto hos Firi',
  'We calculate profit and losses on your crypto trades on Firis platform, and all you need to do is enter the numbers in the tax return yourself.':
    'Vi beregner gevinster og tab på dine kryptohandler på Firis platform, og det eneste du behøver at gøre er at skrive tallene på din selvangivelse.',
  "We can't find the page you are looking for.": 'Vi kan ikke finde den side, du leder efter.',
  "We can't process your order": 'Vi kan ikke behandle din ordre',
  'We charge a flat fee of {0} {currency} for each {1} withdrawal and transfer. The fee is added to the amount sent.':
    [
      'Vi opkræver et fast gebyr på ',
      ['0'],
      ' ',
      ['currency'],
      ' for hver ',
      ['1'],
      '-udbetaling og -overførsel. Gebyret tillægges det afsendte beløb.',
    ],
  'We collect statistics on how our app is used. This gives us valuable insight that allows us to improve ourselves and your customer experience.':
    'Vi indsamler statistik over, hvordan vores app bruges. Dette giver os værdifuld indsigt, der giver os mulighed for at forbedre os selv og din kundeoplevelse.',
  'We could not find a tax report': 'Vi kunne ikke finde en skatterapport',
  'We could not find any accounts': 'Vi kunne ikke finde nogen konti',
  'We could not find the article': 'Vi kunne ikke finde artiklen',
  'We could not find the news update': 'Vi kunne ikke finde nyhedsopdateringen',
  'We currently do not have tax calculations for your country':
    'Vi har i øjeblikket ikke skatteberegninger for dit land',
  'We give you {referralAmount} {0} to trade for when you answer "Yes, please!" to the welcome\nbonus. Good luck with your investments!':
    [
      'Vi giver dig ',
      ['referralAmount'],
      ' ',
      ['0'],
      ' til å handle for, når du svarer "Ja tak!" til velkomstbonussen. Held og lykke med dine investeringer!',
    ],
  "We have already reported your Norwegian kroner balance on Firi as of 31.12.{year}. The amount will automatically appear in your tax return, so you don't need to report it yourself.":
    [
      'Vi har allerede rapportert saldoen du hadde i norske kroner på Firi-kontoen din per 31.12.',
      ['year'],
      '. Beløpet vil vises automatisk i skattemeldingen din, så du trenger ikke å rapportere det selv.',
    ],
  'We have an 18-year age limit': 'Vi har en aldersgrænse på 18 år',
  'We have done a thorough spring cleaning of the entire app, with several exciting updates and the launch of two new coins!':
    'Vi har gennemført en omfattende rensning af hele appen, med flere eksiterte opdateringer og lansering af to nye krypto-valutaer!',
  'We have given you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.':
    'Vi har givet dig <0/>, så du kan opleve, hvor sikkert og nemt det er at investere i krypto med Firi.',
  'We have made it safe and simple for all of our users to make long term investments in cryptocurrency.':
    'Vi har gjort det sikkert og enkelt for alle vores brugere at foretage langsigtede investeringer i kryptovaluta.',
  'We have made some changes in the tax calculation! You must therefore update to get the latest version':
    'Vi har lavet nogle ændringer i skatteberegningen! Du skal derfor opdatere for at få den nyeste version',
  'We have now sent you an email with information on how to proceed to create a new password. It may take up to 10 minutes before you receive the email. Check spam folder as well.':
    'Vi har nu sendt dig en e-mail med information om, hvordan du kommer videre for at oprette en ny adgangskode. Det kan tage op til 10 minutter, før du modtager e-mailen. Tjek også spam-mappen.',
  'We have obtained your information from Vipps. If you want to update your profile, contact our support.':
    'Vi har indhentet dine oplysninger fra Vipps. Hvis du vil opdatere din profil, skal du kontakte vores support.',
  'We have security measures in place to prevent your personal information from being shared, altered, lost or misused. We only give employees access to your personal information if required. Employees with such access will treat the information confidentially.':
    'Vi har sikkerhedsforanstaltninger på plads for at forhindre, at dine personlige oplysninger bliver delt, ændret, mistet eller misbrugt. Vi giver kun medarbejdere adgang til dine personlige oplysninger, hvis det er nødvendigt. Medarbejdere med sådan adgang vil behandle oplysningerne fortroligt.',
  'We have sent you an email with a confirmation code':
    'Vi har sendt dig en e-mail med en bekræftelseskode',
  'We have to confirm your ownersip of the destination address. Please connect your wallet':
    'Vi skal bekræfte dit ejerskab af destinationsadressen. Tilslut venligst din wallet.',
  'We just need to ask you a few quick questions – a legal requirement to ensure a safe experience for everyone. It will only take a moment!':
    'Vi skal bare lige stille dig nogle hurtige spørgsmål – det er et lovkrav for at sikre en tryg oplevelse for alle. Det tager kun et øjeblik!',
  'We let you know when there are big movements crypto world.':
    'Vi giver dig besked, når der er store bevægelser på kryptomarkedet.',
  'We need a few more details': 'Vi har brug for nogle flere detaljer',
  'We need a little signature': 'Vi har brug for din signatur',
  'We need information from you': 'Vi har brug for information fra dig',
  'We need to collect and use information for our app to work in a secure and accurate way.':
    'Vi skal indsamle og bruge oplysninger, for at vores app kan fungere på en sikker og præcis måde.',
  'We need to confirm your information, {0}!': ['Vi skal bekræfte dine oplysninger, ', ['0'], '!'],
  'We need to periodically verify that your information is up to date.':
    'Med jævne mellemrum skal vi bekræfte, at dine oplysninger er opdaterede.',
  'We need your email address so we can contact you. Make sure it is correct. We will send a confirmation a little later.':
    'Vi har brug for din e-mailadresse, så vi kan kontakte dig. Sørg for at den er korrekt. Vi sender en bekræftelse lidt senere.',
  'We notify you when you have a reward waiting, have invited a friend or received a bonus payment.':
    'Vi giver dig besked, når du har en belønning i vente, har inviteret en ven eller modtaget en bonusudbetaling.',
  'We offer a local, safe and easy staking service!':
    'Vi tilbyder en lokal, sikker og enkel staking-service!',
  'We offer several different ways to buy cryptocurrency. This is what Firi customers prefer:':
    'Vi tilbyder flere forskellige måder at købe kryptovaluta på. Dette foretrækker Firi-kunderne:',
  'We only allow withdrawals to personal bank accounts in your own name. A fee of <0/> will be deducted from the amount to cover our costs.':
    'Vi tillader kun hævninger til bankkonti, der står i dit navn. Der fratrækkes et gebyr på <0/> til dækning af vores omkostninger.',
  'We recommend that you learn the basics of cryptocurrency to form your own opinion. You can find more useful articles here in the app.':
    'Vi anbefaler, at du lærer det grundlæggende om kryptovaluta, så du kan danne dig din egen mening. Du kan finde flere nyttige artikler her i appen.',
  'We recommend you to try again later or add a custom order in "advanced trading"':
    'Vi anbefaler dig at prøve igen senere eller tilføje en tilpasset ordre i "avanceret handel"',
  'We respect your privacy, so of course you can choose not to allow certain people types of cookies. Remember that if you choose to turn these off, it may affect your experience here with us.':
    'Vi respekterer dit privatliv, så du kan selvfølgelig vælge ikke at tillade visse typer cookies. Husk, at hvis du vælger at slå disse fra, kan det påvirke din oplevelse her hos os.',
  'We respect your privacy, so you can of course choose whether you want us to collect information that is not necessary for the app to work. Remember that if you choose not to consent to this, it can affect your experience here with us.':
    'Vi respekterer dit privatliv, så du kan selvfølgelig vælge, om du vil have, at vi indsamler oplysninger, som ikke er nødvendige for, at appen fungerer. Husk, at hvis du vælger ikke at give samtykke til dette, kan det påvirke din oplevelse her hos os.',
  'We respect your privacy. Feel free to read our Privacy policy about what data we must collect and use for our app to work in a secure and correct way.':
    'Vi respekterer dit privatliv. Læs gerne vores privatlivspolitik om, hvilke data vi skal indsamle og bruge for at vores app fungerer på en sikker og korrekt måde.',
  'We send you a notification when you have received a deposit, made a withdrawal, bought or sold crypto.':
    'Vi sender dig en notifikation, når du har modtaget en indbetaling, foretaget en udbetaling og når du har købt eller solgt krypto.',
  'We store your personal information for as long as is necessary for the purpose for which the personal information was collected.':
    'Vi opbevarer dine personoplysninger, så længe det er nødvendigt for det formål, som personoplysningerne er indsamlet til.',
  'We take care of the technology for you.': 'Vi hos Firi tager os af det komplicerede',
  'We understand this may cause inconvenience and appreciate your patience as we ensure compliance and security for all transactions':
    'Vi forstår, at dette kan forårsage ulemper, og vi værdsætter jeres tålmodighed, mens vi sikrer overholdelse og sikkerhed for alle transaktioner.',
  'We usually receive this information one working day after we have credited you. Therefore, you cannot withdraw these funds from us until we have received the needed information':
    'Vi modtager disse oplysninger en arbejdsdag efter at vi har krediteret dig. Du kan derfor ikke hæve disse midler fra os, før vi har modtaget de nødvendige oplysninger.',
  'We usually receive this information one working day after we have credited you. You can therefore not make a withdrawal of these funds from us until we have received the necessary information.':
    'Vi modtager disse oplysninger en arbejdsdag efter, at vi har krediteret dig. Du kan derfor ikke foretage en hævning af disse midler fra os, før vi har modtaget de nødvendige oplysninger.',
  'We want to be better at Firi! Can you help us by answering a few questions?':
    'Hos Firi vil vi gerne forbedre os! Vil du hjælpe os ved at svare på et par spørgsmål?',
  'We want to give you <0/> so that you can get to know Firi and invest in your first cryptocurrency.':
    'Vi vil gerne give dig <0/>, så du kan lære Firi at kende og investere i din første kryptovaluta.',
  'We want to give you <0/> so you can experience how safe and easy it is to invest in crypto with Firi.':
    'Vi vil gerne give dig <0/>, så du kan opleve, hvor sikkert og nemt det er at investere i krypto hos Firi.',
  'We welcome the category meme coins and {currency} to Firi': [
    'Vi byder kategorien meme-coins og ',
    ['currency'],
    ' velkommen til Firi.',
  ],
  'We were not able to find a user linked to you Vipps account. Register to start using Firi now!':
    'Vi var ikke i stand til at finde en bruger knyttet til din Vipps-konto. Tilmeld dig for at begynde at bruge Firi nu!',
  'We were not able to process your payment. Try again or choose another payment method.':
    'Vi kunne ikke foretage betalingen. Prøv venligst igen eller vælg en anden betalingsmetode.',
  'We were not able to remove bank account': 'Vi var ikke i stand til at fjerne bankkontoen',
  'We were not able to upload you document': 'Vi kunne ikke uploade dit dokument',
  'We were unable to complete the payment. Please try again or choose another payment method.':
    'Vi var ikke i stand til at gennemføre betalingen. Prøv venligst igen, eller vælg en anden betalingsmetode.',
  'We were unable to complete the payment. Please try again or contact support if the issue persists.':
    'Din betaling gik ikke gennem. Prøv igen. Kontakt support hvis problemet fortsætter.',
  'We were unable to send a password reset email':
    'Vi var ikke i stand til at sende en e-mail til nulstilling af adgangskode',
  'We will not disclose your personal information to third parties unless there is a legal basis for such disclosure or it is necessary for us to fulfill our agreement with you.':
    'Vi videregiver ikke dine personlige oplysninger til tredjeparter, medmindre der er et juridisk grundlag for en sådan videregivelse, fx hvis det er nødvendigt for at kunne opfylde vores aftale med dig.',
  'We will now trade cryptocurrency on your behalf and you will receive an email when the funds are in your account.':
    'Vi vil nu handle kryptovaluta på dine vegne, og du vil modtage en e-mail, når pengene er på din konto.',
  'We will send you a notification when a new calendar hatch is opened.':
    'Vi sender dig en besked, når en ny låge i kalenderen er åben.',
  'We will send you a notification when we have promotions or want to tell you something special.':
    'Vi sender dig en besked, når vi har kampagner eller vil fortælle dig noget særligt.',
  'We will send you a notification when you should update the app because we have launched new products or cryptocurrencies.':
    'Vi sender dig en besked, når du skal opdatere appen, fordi vi har lanceret nye produkter eller kryptovalutaer.',
  'We wish you a Merry Christmas and a Happy New Year':
    'Vi ønsker dig en glædelig jul og et godt nytår',
  "We'll let you know when there's news.": 'Vi giver dig besked, når der er nyheder.',
  "We'll let you know when you can check what Firi Santa has hidden behind the first hatch.":
    'Vi giver besked, når du kan tjekke, hvad Firi-nissen har gemt bag den første låge.',
  "We're opening the doors to two renowned crypto-gaming coins, namely Decentraland (MANA) and The Sandbox (SAND). You can find these under the new category Gaming/Web3.":
    'Vi åbner dørene for to anerkendte krypto-spil coins, nemlig Decentraland (MANA) og The Sandbox (SAND). Disse finder du under den nye kategorien Gaming/Web3',
  "We're unable to verify the recipient address due to regulatory requirements under the Travel Rule. To complete this transfer, you need to confirm ownership of the destination address.":
    'Vi kan ikke bekræfte modtageradressen på grund af regulatoriske krav under Travel Rule. For at fuldføre denne transaktion skal du bekræfte ejerskab af modtageradressen.',
  "We've created an overview of how deposits to your savings account are converted to crypto and how we find the best price.":
    'Vi har lavet en oversigt over, hvordan indskud på din opsparingskonto konverteres til krypto, og hvordan vi finder den bedste pris.',
  'Weakest development': 'Den svageste udvikling',
  'Welcome back {0}!': ['Velkommen tilbage ', ['0'], '!'],
  'Welcome bonus': 'Velkomstbonus',
  'Welcome to Firi': 'Velkommen til Firi',
  'Welcome to Firi Affiliate!': 'Velkommen til Firi Fordel!',
  'Welcome to Firi!': 'Velkommen til Firi!',
  'Welcome to Firi. Get to know crypto and invest safely and easily.':
    'Velkommen til Firi. Lær krypto at kende og invester sikkert og nemt.',
  'What amount do you want to save': 'Hvilket beløb vil du spare',
  'What are meme coins?': 'Hvad er meme-coins?',
  'What can we do better?': 'Hvad kan vi gøre bedre?',
  'What do this overview show me?': 'Hvad viser denne oversigt mig?',
  'What do you think about crypto news?': 'Hvad synes du om kryptonyheder?',
  'What does this mean for you?': 'Hvad betyder dette for dig?',
  'What is Firi?': 'Hvad er Firi?',
  'What is Travel Rule?': 'Hvad er Travel Rule?',
  'What is crypto, really?': 'Hvad er krypto egentlig?',
  'What is relevant documentation?': 'Hvad er relevant dokumentation?',
  'What is staking and who does it work?': 'Hvad er staking, og hvordan fungerer det?',
  'What is the primary driver behind the price of meme coins?':
    'Hvad er den primære drivkraft bag prisen på meme-coins?',
  'What is the reason you also use Firi?': 'Hvad er baggrunden for at du også bruger Firi?',
  'What is your motivation for investing in crypto?':
    'Hvad er din motivation for at investere i krypto?',
  'What should your saving account be called?': 'Hvad skal din opsparingskonto hedde?',
  'What stops you from investing more in crypto?':
    'Hvad forhindrer dig i at investere mere i krypto?',
  'What was the purchase price on this cryptocurrency?':
    'Hvad var købsprisen på denne kryptovaluta?',
  'What would you like to read more about?': 'Hvad vil du gerne læse mere om?',
  "What's hiding behind today's hatch? 👀": 'Hvad ligger der bag dagens låge? 👀',
  When: 'Tidspunkt',
  'When all your information is up to date you will get the best user experience Firi has to offer.':
    'Når alle dine oplysninger er opdateret, får du den bedste brugeroplevelse, Firi har at tilbyde.',
  'When can I expect my cryptocurrency to arrive':
    'Hvornår kan jeg forvente, at min kryptovaluta ankommer',
  'When does my cryptocurrency get sent': 'Hvornår bliver min kryptovaluta sendt',
  'When does the cryptocurrency arrive': 'Hvornår ankommer kryptovalutaen',
  'When will I receive the reward?': 'Hvornår modtager jeg belønningen?',
  'When will you receive the reward?': 'Hvornår modtager du belønningen?',
  'When you buy crypto with money you have in your account with Firi, you pay a fee of 0.7%.':
    'Når du køber krypto med penge du har på din konto hos Firi, betaler du et gebyr på 0,7%.',
  'When you buy cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you buy from the cheapest order.':
    'Når du køber kryptovaluta, vil du udfylde en eller flere ordrer afgivet af andre brugere. Ordrer har forskellige volumener, og vi vil altid sørge for, at du køber fra den billigste ordre.',
  'When you sell cryptocurrency, you will fill one or several orders placed by other users. Orders have different volumes and we will always ensure that you sell from the cheapest order.':
    'Når du sælger kryptovaluta, vil du udfylde en eller flere ordrer afgivet af andre brugere. Ordrer har forskellige volumener, og vi vil altid sikre, at du sælger fra den billigste ordre.',
  'When you send crypto out of Firi, we can no longer track the transactions. The tax calculation will therefore be incomplete, and you must keep track of the purchase price yourself.':
    'Når vi sender krypto ud af Firi, kan vi ikke længere spore transaktionerne. Skatteberegningen vil derfor være ufuldstændig, og du er derfor nødt til at holde styr på din egen opgørelse.',
  'When you stake, you help secure and run the blockchain that your crypto belongs to. As a thank you, you receive a reward.':
    'Når du staker, hjælper du med at sikre og drive blockchainen, som din krypto tilhører. Som tak for din indsats modtager du en belønning.',
  'When you use our website, create a user profile or are in contact with Firi, we will process personal information about you.':
    'Når du bruger vores hjemmeside, opretter en brugerprofil eller er i kontakt med Firi, behandler vi dine personoplysninger.',
  'Whenever I want': 'Når jeg vil',
  'Where are you sending it?': 'Hvor sender du til?',
  'Where can you find information and updates about crypto today?':
    'Hvor finder du information og opdateringer om krypto i dag?',
  'Where do I find my tax return?': 'Hvor finder jeg min selvangivelse?',
  'Where do i find my tax report?': 'Hvor finder jeg min skatteopgørelse?',
  'Where do you live?': 'Hvor bor du?',
  'Where does the deposit come from?': 'Hvor kommer indskuddet fra?',
  'Which account do you want to stake from?': 'Hvilken konto vil du stake fra?',
  'Which account would you like to transfer the money to?':
    'Hvilken konto vil du overføre pengene til?',
  'Which bank account do you want the money paid out to? We only allow withdrawals to Norwegian bank accounts. Remember that the account must be in your name.':
    'Hvilken bankkonto vil du have pengene udbetalt til? Vi tillader kun hævninger til danske bankkonti. Husk at kontoen skal stå i dit navn.',
  'Which country do you live in?': 'Hvilket land bor du i?',
  'Which crypto can I get reward for?': 'Hvilken kryptovaluta kan jeg få belønning for?',
  'Which currencies do you want to save in': 'Hvilke valutaer vil du spare op i',
  'Which currencies do you want to save in? Choose one or more.':
    'Hvilke valutaer vil du spare op i? Vælg en eller flere.',
  'Which currency do you want to transfer?': 'Hvilken valuta vil du overføre?',
  'Who holds most of the meme coins?': 'Hvem holder størstedelen af meme-coins?',
  'Who owns the crypto that is being deposited?': 'Hvem ejer den kryptovaluta, der indbetales?',
  'Who would you like to give a gift card to? Enter the name or nickname of the lucky recipient.':
    'Hvem vil du gerne give et gavekort til? Indtast navnet eller kaldenavnet på den heldige modtager.',
  Whoops: 'Whoops',
  'Why am I rewarded? 🤔': 'Hvorfor får jeg belønning? 🤔',
  "Why can't I deactivate staking now?": 'Hvorfor kan jeg ikke deaktivere staking allerede nu?',
  'Why do i need to download a 2FA-app?': 'Hvorfor skal jeg downloade en 2FA-app?',
  'Why do you trade on other platforms/exchanges?': 'Hvorfor handler du på andre platforme/børser?',
  'Why does this happen?': 'Hvorfor sker dette?',
  'Why is this required?': 'Hvorfor er dette nødvendigt?',
  'Why should I invest in cryptocurrency?': 'Hvorfor bør jeg investere i kryptovaluta?',
  'Will my crypto be locked?': 'Vil min krypto blive låst?',
  "With Firi's tax calculation, you can quickly and easily calculate your crypto tax. Absolutely free!":
    'Med Firis skatteberegning kan du hurtigt og nemt beregne din kryptoskat. Helt gratis!',
  "With Vipps we will automatically fill your profile information, and it does not cost anything. Sign with BankID and you're set!":
    'Med Vipps udfylder vi automatisk dine profiloplysninger, og det koster ikke noget. Skriv under med BankID og du er klar!',
  'With email': 'Med e-mail',
  Withdraw: 'Udbetaling',
  'Withdraw all': 'Tag alt ud',
  'Withdraw completed!': 'Anmodning sendt!',
  'Withdraw consent': 'Træk samtykke tilbage',
  'Withdraw fee': 'Udbetalingsgebyr',
  'Withdraw funds': 'Hæv penge',
  'Withdraw limit': 'Udbetalingsgrænse',
  'Withdraw limits': 'Udbetalingsgrænser',
  'Withdraw money': 'Hæve penge',
  'Withdraw of {currency} is unavailable': ['Udbetaling af ', ['currency'], ' utilgængelig'],
  'Withdraw on its way!': 'Udbetaling på vej!',
  'Withdraw request completed': 'Anmodning om tilbagetrækning er afsluttet',
  'Withdraw to bank': 'Udbetaling til bank',
  'Withdraw {0}': ['Udbetal ', ['0']],
  'Withdrawal of crypto can result in incomplete tax calculations.':
    'Udbetaling af krypto kan give ufuldstændig skatteberegning.',
  Withdrawals: 'Udbetalinger',
  "Withdrawals of {0} usually proceed from our account within 1-3 working days. Then it's up to your bank.":
    [
      'Udbetalinger på ',
      ['0'],
      ' foregår normalt fra vores konto inden for 1-3 hverdage. Så er det op til din bank.',
    ],
  "Wondering why we're asking?": 'Undrer du dig over, hvorfor vi spørger?',
  'Would you look at that!': 'Vil du se på det!',
  Write: 'Skrive',
  'Write here': 'Skriv her',
  'Write or paste in the confirmation code that was sent to you at {0}.': [
    'Skriv eller indsæt bekræftelseskoden, der blev sendt til dig på ',
    ['0'],
    '.',
  ],
  'Write or paste in the confirmation code that was sent to you.':
    'Skriv eller indsæt bekræftelseskoden, der blev sendt til dig.',
  "Write the name of the person or company that owns the crypto. If the funds come from a crypto exchange, write the name of the account holder – not the exchange's name.":
    'Indtast navnet på den person eller virksomhed, der ejer kryptovalutaen. Hvis de er fra en kryptobørs, skal du indtaste navnet på den person, der ejer kontoen på børsen - ikke navnet på selve børsen.',
  'Wrong answer. Try again': 'Fejl svar. Prøv venligst igen',
  'Wrong one-time password has been entered too many times. Try again in 30 seconds or more.':
    'Forkert engangsadgangskode er blevet indtastet for mange gange. Prøv igen om 30 sekunder eller mere.',
  'Wrong username or password': 'Forkert brugernavn eller adgangskode',
  "XRP is the original cryptocurrency for products developed by Ripple Labs. XRP is one of the world's largest cryptocurrencies based on market capitalization.":
    'XRP er den originale kryptovaluta for produkter udviklet af Ripple Labs. XRP er en af verdens største kryptovalutaer baseret på markedsværdi.',
  Year: 'År',
  Yes: 'Ja',
  'Yes, I want to be notified!': 'Ja, jeg vil have besked!',
  'Yes, I want to continue': 'Ja, jeg vil fortsætte',
  'Yes, and trade about the same on Firi as on other platforms':
    'Ja, og handler for ca. det samme på Firi som på andre platforme',
  'Yes, and trade more on others': 'Ja, og handler mere hos andre',
  'Yes, but trade less on others': 'Ja, men køber mindre hos andre',
  'Yes, it is me': 'Ja, det er mig',
  'Yes, turn on notification': 'Ja, slå notifikationer til',
  'Yes, verify all': 'Ja, bekræft alle',
  'Yes, with meme coins you can lose 80-90% in a very short time':
    'Ja, med meme-coins kan du tabe 80-90% på meget kort tid',
  'Yes, you can be sure that your digital assets are protected by the highest level of security on the market when using Firi.':
    'Ja, du kan være sikker på, at dine digitale ejendele er beskyttet af det højeste sikkerhedsniveau på markedet, når du bruger Firi.',
  Yesterday: 'I går',
  'You absolutely do not need to be a crypto expert to stake. We at Firi take care of all the complicated things, so you can sit back and enjoy the fruits of your investment.':
    'Du behøver ikke at være ekspert – vi hos Firi tager os af det tekniske, så du kan læne dig tilbage og lade dine investeringer arbejde for dig.',
  'You already have a savings account': 'Du har allerede en opsparingskonto',
  'You already have a staking account': 'Du har allerede en staking-konto',
  "You answered today's question correctly and are entered into the draw for a Firi fleece and beanie 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve":
    'Du svarede rigtigt på dagens spørgsmål og er med i lodtrækningen om en Firi-fleece og en hue 🎉. Derudover har du nu fået et lod til hovedlodtrækningen på juleaften.',
  "You answered today's question correctly and are entered into the draw for {0} {1} in {2} 🎉. In addition, you will receive a lottery ticket for the grand prize on Christmas Eve":
    [
      'Du har svaret rigtigt på dagens spørgsmål og er med i lodtrækningen om ',
      ['0'],
      ' ',
      ['1'],
      ' i ',
      ['2'],
      ' 🎉. Derudover modtager du et lod til hovedpræmien juleaftensdag',
    ],
  'You are a politically exposed person (PEP) if you have been entrusted with a prominent public function. We kindly ask you to respond "Yes" if you have a close family or work relationship to a PEP.':
    'Du er en politisk udsat person, hvis du er blevet betroet en fremtrædende offentlig funktion. Vi beder dig venligst svare "Ja", hvis du har et nært familie- eller arbejdsforhold til en PEP.',
  'You are invited by {0}, and we welcome you to Firi!': [
    'Du er blevet inviteret af ',
    ['0'],
    ', og vi ønsker dig velkommen til Firi!',
  ],
  'You are responsible for reporting the correct amount in your tax return.':
    ' Du er selv ansvarlig for at rapportere det korrekte beløb i din selvangivelsen.',
  'You are saving': 'Du sparer',
  'You are sending': 'Du sender',
  'You buy {currency} and confirm that you want to stake this.': [
    'Du køber ',
    ['currency'],
    ' og bekræfter, at du vil stake dette.',
  ],
  'You can <0>only</0> transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'Du kan <0>kun</0> overføre fra en konto, der er registreret i dit firmanavn. Modtager vi en betaling fra et andet firmanavn end dit eget, vil der opstå store forsinkelser, og vi skal ofte tilbagebetale betalingen. Du kan ikke overføre fra fælleskonti, kreditkort eller Nordnet.',
  'You can also continue on web with Vipps': 'Du kan også fortsætte på nettet med Vipps',
  'You can become a customer and buy crypto in the Firi app. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:':
    'Du kan blive kunde og købe krypto i Firi-appen. Den nemmeste måde at downloade Firi-appen på er ved at scanne denne QR-kode med kameraet på din mobil:',
  'You can buy {currency} instantly with Vipps or card.': [
    'Du kan købe ',
    ['0'],
    ' med det samme med kort eller ApplePay.',
  ],
  'You can buy {currency} instantly with card.': [
    'Du kan købe ',
    ['0'],
    ' med det samme med kort.',
  ],
  'You can change this distribution in your savings agreement at any time.':
    'Du kan til enhver tid ændre denne fordeling i din opsparingsaftale.',
  'You can choose if you want to buy new {currency} or stake from you holdings.': [
    'Du vælger selv, om du vil købe ny ',
    ['currency'],
    ' eller stake fra din beholdning.',
  ],
  'You can choose to let us understand how you use our app and what interests you. This will give you relevant content via email and notifications in the app.':
    'Du kan vælge at lade os forstå, hvordan du bruger vores app, og hvad der interesserer dig. Dette vil give dig relevant indhold via e-mail og notifikationer i appen.',
  'You can deduct fees from trading crypto. Trading fee deductions are already included in Gain or Loss.':
    'Du kan trække gebyrer fra handel med krypto fra. Fradrag for handelsgebyrer er allerede inkluderet i Profit eller Tab.',
  'You can deduct withdrawal and transaction fees for a total of <0>{0} {1}</0> in your tax return.':
    [
      'Du kan trække udbetaling og transaktionsgebyrer for en samlet værdi på <0>',
      ['0'],
      ' ',
      ['1'],
      '</0> i din skattemelding.',
    ],
  'You can earn more on your {0}': ['Du kan tjene mere på din ', ['0']],
  'You can easily change the name later': 'Du kan nemt ændre navnet senere',
  'You can find this code in your 2FA-app. The code should always be 6 digits. Copy the code from your app and paste it here.':
    'Du kan finde denne kode i din 2FA-app. Koden skal altid være på 6 cifre. Kopiér koden fra din app og indsæt den her.',
  'You can find this in your tax return under "Bank, lån og forsikring".':
    'Du kan finde dette i din skattemelding under "Bank, lån og forsikring".',
  'You can integrate Vipps to your Firi account to allow you to login with Vipps. After integrating with Vipps you can use both authentication methods. This feature is optional for existing Firi accounts.':
    'Du kan integrere Vipps til din Firi-konto, så du kan logge ind med Vipps. Efter integration med Vipps kan du bruge begge godkendelsesmetoder. Denne funktion er valgfri for eksisterende Firi-konti.',
  'You can now create a savings agreement with automatic purchase of cryptocurrency.':
    'Du kan nu oprette en opsparingsaftale med automatisk køb af kryptovaluta.',
  'You can only have one savings account in Firi.': 'Du kan kun have én opsparingskonto hos Firi',
  'You can only have one staking account in Firi.': 'Du kan kun have én staking-konto hos Firi',
  'You can only make internal transfers to and from you savings account in Firi. If you want to transfer cryptocurrencies to or from another crypto wallet outside of Firi, you must first transfer to you main account and then to you savings account, or vice versa. It is free to make internal transfers.':
    'Du kan kun foretage interne overførsler til og fra din opsparingskonto i Firi. Hvis du ønsker at overføre kryptovalutaer til eller fra en anden krypto-wallet uden for Firi, skal det ske fra din hovedkonto.  Hvis din krypto står på din opsparingskonto skal du derfor først overføre til din hovedkonto inden du kan overføre til en wallet uden for Firi. Det samme gælder den anden vej. Det er gratis at foretage interne overførsler.',
  'You can only transfer from an account registered in your company name. If we receive a payment from a company name other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'Du kan kun overføre fra en konto, der er registreret i dit firmanavn. Modtager vi en betaling fra et andet firmanavn end dit eget, vil der opstå store forsinkelser, og vi skal ofte tilbagebetale betalingen. Du kan ikke overføre fra fælleskonti, kreditkort eller Nordnet.',
  'You can only transfer from an account registered in your name and address. If we receive a payment from a name or address other than your own, there will be major delays and we will often have to return the payment. You can not transfer from joint accounts, credit cards or Nordnet.':
    'Du kan kun overføre fra en konto, der er registreret i dit navn og din adresse. Modtager vi en betaling fra et andet navn eller en anden adresse end dit eget, vil der opstå store forsinkelser, og vi skal ofte tilbagebetale betalingen. Du kan ikke overføre fra fælleskonti, kreditkort eller Nordnet.',
  'You can open {accountTrans} in the Firi app, but unfortunately not on the web yet': [
    'Du kan åbne ',
    ['accountTrans'],
    ' i Firi-appen, men desværre ikke på nettet endnu',
  ],
  'You can open {accountTrans} in the Firi app, but unfortunately not on the web yet. The easiest way to download the Firi app is by scanning this QR code with the camera on your mobile:':
    [
      'Du kan åbne ',
      ['accountTrans'],
      ' i Firi-appen, men desværre ikke på nettet endnu. Den nemmeste måde at downloade Firi-appen på er ved at scanne denne QR-kode med kameraet på din mobil:',
    ],
  'You can stake crypto - locally, safely and easily!':
    'Nu kan du stake krypto – lokalt, trygt og enkelt!',
  'You can start earning rewards today! Both the funds that are staked and the reward you receive are safely stored on your staking account.':
    'Du kan starte med at optjene belønning allerede i dag! Både midlerne, der stakes, og belønningen du modtager, opbevares sikkert på din staking-konto.',
  'You can use the Polygon blockchain on many of the leading decentralized exchanges, as well as marketplaces for NFTs. Polygon is the most popular scaling solution for Ethereum measured by the number of daily users.':
    "Du kan bruge Polygon blockchain på mange af de førende decentrale børser, samt markedspladser for NFT'er. Polygon er den mest populære skalering løsning for Ethereum målt på antallet af daglige brugere.",
  'You cancelled a order': 'Du har annulleret en ordre',
  'You changed your password': 'Du har ændret din adgangskode',
  'You contribute to the network – and get rewarded.':
    'Du bidrager til netværket – og får belønning tilbage.',
  'You created a voucher': 'Du har oprettet en kupon',
  'You created this user account': 'Du har oprettet denne brugerkonto',
  'You deposited from a bank account': 'Du har indbetalt fra en bankkonto',
  "You didn't answer today's question correctly, try again tomorrow!":
    'Du svarede ikke rigtigt på dagens spørgsmål, prøv igen i morgen!',
  'You do not have a savings agreement yet, but it is easy to get started!':
    'Du har endnu ikke en opsparingsaftale, men det er nemt at komme i gang!',
  'You do not have any crypto deposits from {year}': ['Du har ingen kryptoindskud fra ', ['year']],
  'You do not have any payouts yet': 'Du har ingen udbetalinger endnu',
  'You do not have any transactions yet': 'Du har ingen transaktioner endnu',
  'You do not have crypto here yet, but it is easy to get started.':
    'Du har ikke krypto her endnu, men det er nemt at komme i gang',
  "You don't have any <0/> for {year}": ['Du har ingen <0/> for ', ['year']],
  "You don't have any tickets yet, but there's still time to collect! Each ticket gives you an extra chance to win the grand prize on Christmas Eve. The more tickets, the greater the chance.":
    'Du har ingen lod endnu, men der er stadig tid til at hente! Hver lod giver deg en ekstra chance for at vinde hovedpræmien juleaften. Jo flere lod, jo større er chancen.',
  'You get a regular referral bonus in addition to this reward for everyone you refer 🔥':
    'Du får en almindelig henvisningsbonus udover denne belønning for alle, du henviser 🔥',
  'You give': 'Du giver',
  'You give your consent for us to store your BankID-information.':
    'Du giver dit samtykke til, at vi opbevarer dine BankID-oplysninger.',
  'You have a number of legal rights in regards to your ownership of personal information we have stored about you and our use of this information.':
    'Du har en række juridiske rettigheder i forhold til dit ejerskab af personlige oplysninger, vi har gemt om dig, og vores brug af disse oplysninger.',
  'You have already opened todays hatch!': 'Du har allerede åbnet dagens låge!',
  'You have already registered a user with Vipps. You can log in, or go back for more options.':
    'Du har allerede registreret en bruger hos MitID. Du kan logge ind eller gå tilbage for flere muligheder.',
  'You have answered {0}': ['Du har svaret ', ['0']],
  'You have assets that can be staked. Do you want rewards?':
    'Du har midler, som kan stakes. Vil du have belønning?',
  'You have changed the purchase price of one or more transactions. Previous tax calculations are therefore no longer correct. Click here to recalculate the tax. This may take a few minutes.':
    'Du har ændret købsprisen for en eller flere transaktioner. Tidligere skatteberegninger er derfor ikke længere korrekte. Klik her for at genberegne skatten. Dette kan tage et par minutter.',
  'You have decided to save {amount} {fiatMarketName} {0}. Enter this in the fixed transfer/payment.':
    [
      'Du har valgt at spare ',
      ['amount'],
      ' ',
      ['fiatMarketName'],
      ' ',
      ['0'],
      '. Indtast dette i den faste overførsel/betaling.',
    ],
  'You have enabled two-factor authentication': 'Du har aktiveret to-faktor-godkendelse',
  'You have more ongoing transfers': 'Du har flere løbende overførsler',
  'You have no open orders': 'Du har ingen åbne ordrer',
  'You have no pending payouts': 'Du har ingen afventende udbetalinger',
  'You have no previous payments': 'Du har ingen tidligere betalinger',
  'You have no referrals': 'Du har ingen invitationer',
  'You have no referrals being processed': 'Du har ingen invitationer under behandling',
  'You have not added any accounts for withdrawals to bank':
    'Du har ikke tilføjet nogen konti for udbetalinger til banken',
  'You have not referred anyone to Firi yet': 'Du har endnu ikke inviteret nogen til Firi',
  'You have now activated 2FA.': 'Du har nu aktiveret 2FA.',
  'You have now created a savings agreement, and the last thing left is to log in to your online bank to activate it.':
    'Du har nu oprettet en opsparingsaftale, og det sidste er at logge ind på din netbank for at aktivere den.',
  'You have reached the withdrawal limit': 'Du har nået udbetalingsgrænsen',
  'You have received': 'Du har modtaget',
  'You have received <0/>!': 'Du har modtaget <0/>!',
  'You have received rewards from staking! This is what you have earned the last week.':
    'Du har modtaget belønning fra staking! Dette er beløbet du har optjent den sidste uge.',
  'You have registered with Firi and verified your identity using BankID':
    'Du har tilmeldt dig Firi og bekræftet din identitet ved hjælp af MitID',
  'You have the following cryptocurrencies in your distribution that are not available anymore: {0}. Please update the distribution.':
    [
      'Du har følgende kryptovalutaer i din fordeling som ikke er tilgængelige lenger: ',
      ['0'],
      '. Vennligst oppdater fordelingen.',
    ],
  'You have {0} crypto deposits that have not been purchased from Firi. Click here to change the purchase prices.':
    [
      'Du har ',
      ['0'],
      ' kryptoindskud, som ikke er købt hos Firi. Klik her for at ændre købspriserne.',
    ],
  'You have {0} recent crypto deposits that are missing purchase price (acquisition value). We recommend that you add the purchase price so that your tax calculation to be correct.':
    [
      'Du har ',
      ['0'],
      ' nylige kryptoindskud, der mangler købsprisen (anskaffelsesværdi). Vi anbefaler, at du tilføjer købsprisen, for at din afgiftsberegning bliver korrekt.',
    ],
  'You have {remainingPercent}% to distribute': [
    'Du har ',
    ['remainingPercent'],
    ' % at distribuere',
  ],
  'You might see an error indicating the distribution for some cryptocurrencies is too low. This happens when one distribution is less than {SAVING_AGREEMENT_DETAIL_MIN_AMOUNT} {0}':
    [
      'Du kan muligvis se en fejl, der indikerer, at distributionen for nogle kryptovalutaer er for lav. Dette sker, når én distribution er mindre end ',
      ['SAVING_AGREEMENT_DETAIL_MIN_AMOUNT'],
      ' ',
      ['0'],
    ],
  'You must be over 18 years old': 'Du skal være over 18 år',
  'You must calculate your crypto holdings, gains and losses yourself.':
    'Du skal selv beregne din kryptoformue, gevinst og tab.',
  'You must confirm that the information is correct before continuing.':
    'Du skal bekræfte, at oplysningerne er korrekte, før du fortsætter.',
  'You must enter these numbers yourself in your tax return. This does not happen automatically.':
    'Du skal selv indsætte disse tal i din skattemelding. Dette sker ikke automatisk.',
  'You must have a valid BankID': 'Du skal have et gyldigt MitID',
  'You must have a valid Norwegian national identity number':
    'Du skal have et gyldigt dansk personnummer',
  'You must verify your email to continue to use Firi.':
    'Du skal bekræfte din e-mail for at fortsætte med at bruge Firi.',
  'You need a 2FA app to generate codes that, in addition to your password, are used to unlock your account.':
    'Du skal bruge en 2FA-app til at generere koder, der ud over din adgangskode bruges til at låse din konto op.',
  'You need more {0}': ['Du har brug for mere ', ['0']],
  'You need to activate staking to earn up to {0}% expected annual yield on your crypto.': [
    'For at opnå forventet årligt afkast på op til ',
    ['0'],
    '% på din krypto, skal du aktivere staking.',
  ],
  'You need to enable notifications in device setting to receive notifications from Firi':
    'For at modtage notifikationer fra Firi skal du aktivere notifikationer i indstillingerne på din enhed.',
  'You need to give access to the camera in device setting to scan QR code. Reload the app after enabling permissions.':
    'Du skal give adgang til kameraet i indstillingerne for at scanne QR-koden. Efter at have givet tilladelse, genstart appen ved at lukke og genåbne den.',
  'You need to give access to the camera in device setting to take a profile picture':
    'Du skal give adgang til kameraet i indstillingerne for at kunne tage et profilbillede',
  'You need to update your account number and message for your recurring transfer in online banking':
    'Du skal opdatere kontonummer og besked for din faste overførsel i netbanken.',
  'You now get {0} {1} in {2} for each friend you invite to Firi and becomes a customer with us.': [
    'Nu får du ',
    ['0'],
    ' ',
    ['1'],
    ' i ',
    ['2'],
    ', for hver ven du inviterer til Firi, der bliver kunde hos os!',
  ],
  'You now have a staking account.': 'Du har nu en staking-konto',
  'You password is changed! You will be logged out in a few seconds.':
    'Din adgangskode er ændret! Du bliver logget ud om få sekunder.',
  'You privacy information are always safely stored with us.':
    'Dine privatlivsoplysninger opbevares altid sikkert hos os.',
  'You received': 'Du modtog',
  'You received a currency': 'Du har modtaget en valuta',
  'You sent a currency': 'Du sendte en valuta',
  'You session is invalid. Please log out and try again.':
    'Din session er ugyldig. Log venligst ud og prøv igen.',
  'You supplied your name and address.': 'Du har oplyst dit navn og adresse.',
  'You will find the code on your gift card itself. In case of problems, contact our support at support@firi.com or in the chat.':
    'Du finder koden på selve dit gavekort. I tilfælde af problemer, kontakt vores support på support.dk@firi.com eller i chatten.',
  'You will find the cryptocurrency on your main account.':
    'Du finder kryptovalutaen på din hovedkonto.',
  'You will hear from us!': 'Du hører fra os!',
  'You will now be sent to your bank': 'Du vil nu blive sendt til din bank',
  'You will now be sent to your payment provider': 'Du vil nu blive sendt til din betalingsudbyder',
  'You will receive the referral bonus once the person you referred has registered using your code and verified their account.':
    'Du vil modtage belønning for invitationen, når den person, du inviterede, har registreret sig med din kode og bekræftet sin konto.',
  "You're a bit too early...": 'Her var du lidt tidligt på den...',
  "You're almost ready to start, {0}!": ['Nu er du næsten i gang, ', ['0'], '!'],
  "You're almost there!": 'Du er næsten i mål!',
  "You're almost there! To meet the authorities requirements, we also need to verify your identity through facial recognition. Thank you for helping us secure your account!":
    'Du er næsten i mål! For at opfylde myndighedernes krav skal vi også verificere din identitet med ansigtsgenkendelse. Tak for at du hjælper os med at sikre din konto!',
  "You're staking": 'Du staker',
  "You're up to date!": 'Du er opdateret!',
  'Your account is linked with Vipps': 'Din konto er knyttet til Vipps',
  'Your answers are now registered': 'Dine svar er nu registreret',
  'Your balance': 'Din balance',
  'Your bank account': 'Din bankkonto',
  'Your bank does not allow higher amounts then 15000 NOK per transfer':
    'Din bank tillader ikke højere beløb end 15.000 NOK pr. overførsel',
  'Your choosen price is lower than the best available price in our market.':
    'Din valgte pris er lavere end den bedst tilgængelige pris på vores marked.',
  'Your chosen bank has a maximum amount of {0} {shortName} per deposit.': [
    'Din valgte bank har et maksimalt beløb på ',
    ['0'],
    ' ',
    ['shortName'],
    ' pr. indbetaling.',
  ],
  'Your configuration key': 'Din konfigurationsnøgle',
  'Your crypto inventory is empty or it is too low to make a withdrawal. You can either buy crypto, or make an internal transfer from your savings account.':
    'Din kryptobeholdning er enten tom eller for lav til at foretage en udbetaling. Du kan enten købe krypto eller foretage en intern overførsel fra din opsparingskonto.',
  'Your crypto inventory is empty or it is too low to make a withdrawal. You can solve this easily by buying crypto.':
    'Din kryptobeholdning er enten tom eller for lav til at foretage en udbetaling. Du kan nemt løse dette ved at købe krypto.',
  'Your email is verified!': 'Din e-mail er bekræftet!',
  "Your friends don't have to spend a single penny on this - they just invest their welcome bonus.":
    'Dine venner behøver ikke bruge en eneste krone på dette - de investerer bare deres velkomstbonus.',
  'Your gift card could not be created': 'Dit gavekort kunne ikke oprettes',
  "Your gift card have been created and you will soon receive an email where you can download your gift card. We are experiencing some technical issue so you can't see it right here":
    'Dit gavekort er oprettet, og du vil snart modtage en e-mail, hvor du kan downloade dit gavekort. Vi oplever nogle tekniske problemer, så du kan ikke se det lige her',
  'Your gift card!': 'Dit gavekort!',
  'Your information is handled securely at every step.':
    'Dine oplysninger håndteres sikkert i hvert trin.',
  'Your latest': 'Din seneste',
  'Your money will usually be withdrawn from your bank account immediately, but it takes some time before we receive all required information and can approve your transaction. You will get an e-mail when the money is credited.':
    'Dine penge vil normalt blive trukket fra din bankkonto med det samme, men det tager noget tid, før vi modtager alle nødvendige oplysninger og kan godkende din transaktion. Du får en e-mail, når pengene er krediteret.',
  'Your numbers are ready for reporting. Remember that you need to enter them yourself in your tax return.':
    'Dine numre er klar til rapportering. Husk at du selv skal indtaste dem i din selvangivelse.',
  'Your personal information is always stored securely with us.':
    'Dine personlige oplysninger opbevares altid sikkert hos os.',
  'Your profile is not visible to other users.': 'Din profil er ikke synlig for andre brugere.',
  'Your profile will be automatically completed when using Vipps. Sign in with bankID to get started, safe and simple!':
    'Din profil bliver automatisk udfyldt, når du bruger Vipps. Log ind med MitID for at komme i gang, sikkert og enkelt!',
  'Your referral code:': 'Din invitationskode',
  'Your referral codes': 'Dine invitationskoder',
  'Your reward of <0/> in {0} has been paid to your main account.': [
    'Din belønning på <0/> i ',
    ['0'],
    ' er blevet udbetalt til din hovedkonto.',
  ],
  'Your reward of {0} {name} in {1} has been paid to your main account.': [
    'Din belønning på ',
    ['0'],
    ' ',
    ['name'],
    ' i ',
    ['1'],
    ' er blevet udbetalt til din hovedkonto.',
  ],
  'Your safety is important': 'Din sikkerhed er vigtig',
  'Your session has either expired or is invalid': 'Din session er enten udløbet eller ugyldig',
  'Your session has expired, please login again.': 'Din session er udløbet, log venligst ind igen.',
  'Your text exceeds 20 characters': 'Din tekst overstiger 20 tegn',
  'Your wallet address is currently unavailable':
    'Din wallet adresse er ikke tilgængelig i øjeblikket',
  'Your withdrawal limit is <0/> per day. The amount count for a total value for every currency.':
    'Din udbetalingsgrænse er <0/> pr. dag. Beløbet tæller for en samlet værdi for hver valuta.',
  'Your withdrawal limit is {0} per day. The amount count for a total value for every currency.': [
    'Din udbetalingsgrænse er ',
    ['0'],
    ' pr. dag. Beløbet tæller for en samlet værdi for hver valuta.',
  ],
  'Your withdrawal limit is {0}{1} per day. The amount count for a total value for every currency.':
    [
      'Din udbetalingsgrænse er ',
      ['0'],
      ['1'],
      ' pr. dag. Beløbet tæller for en samlet værdi for hver valuta.',
    ],
  'Your withdrawal was not successful. It may have been a hiccup, so please try again.':
    'Det ønskede udtræk lykkedes ikke. Det kan have været et mindre problem, så prøv venligst igen.',
  'Zip code': 'Postnummer',
  about: 'om',
  'app-theme': 'app-theme',
  available: 'ledig',
  'change <0/>': 'ændring <0/>',
  collected: 'indsamlet',
  cryptocurrencies: 'kryptovalutaer',
  cryptocurrency: 'kryptovaluta',
  'danish kroner': 'danske kroner',
  'deposit-2': 'Indsæt',
  deposits: 'indskud',
  dollars: 'dollars',
  'enter the value': 'indtast værdien',
  'every day': 'hver dag',
  'every month': 'hver måned',
  'every other week': 'hver anden uge',
  'every two weeks': 'hver anden uge',
  'every week': 'hver uge',
  'every {0} days': ['hver ', ['0'], ' dag'],
  'expected yearly earn': 'forventet årligt afkast',
  for: 'til',
  friends: 'venner',
  'from {0} to {1}': ['fra ', ['0'], ' til ', ['1']],
  'gift card': 'gavekort',
  high: 'høj',
  immediately: 'øjeblikkelig',
  in: 'i',
  'in expected yield per year': 'i forventet afkast om året',
  'in fees': 'i gebyr',
  'in rewards': 'i belønning',
  'invite you to Firi!': 'inviterer dig til Firi!',
  kr: 'kr',
  kroner: 'kroner',
  left: 'tilbage',
  low: 'lav',
  'norwegian kroner': 'norske kroner',
  'not verified': 'ikke verificeret',
  'on the way': 'på vej',
  'on {0}': ['på ', ['0']],
  or: 'eller',
  'or copy code': 'eller kopier kode',
  'or remote': 'eller fjernbetjening',
  'people have donated': 'folk har doneret',
  'privacy policy': 'Fortrolighedspolitik',
  'read-past-tense': 'Læst',
  'safely and easily.': 'sikkert og let.',
  'savings account': 'opsparingskonto',
  'select files': 'vælg filer',
  'swedish kroner': 'svenska kronor',
  'terms of services': 'servicevilkår',
  'the App Store': 'App Store',
  today: 'i dag',
  unknown: 'ukendt',
  value: 'værdi',
  vol: 'vol',
  'withdraw-2': 'Træk ud',
  'yearly yield': 'årligt afkast',
  yesterday: 'i går',
  '{0, plural, one {News} other {News}}': [['0', 'plural', { one: 'Nyhed', other: 'Nyheder' }]],
  '{0, select, en {https://www.firi.com} no {https://www.firi.com/no} dk {https://www.firi.com/dk}}':
    [
      [
        '0',
        'select',
        {
          en: 'https://www.firi.com',
          no: 'https://www.firi.com/no',
          dk: 'https://www.firi.com/dk',
        },
      ],
    ],
  "{0, select, sad {Hmm, that didn't go as planned!} neutral {Thank you for the feedback!} happy {Woho, we love that you are happy!} other {We are curious! How has your experience been so far?}}":
    [
      [
        '0',
        'select',
        {
          sad: 'Hmm, det var jo ikke helt i top!',
          neutral: 'Tak for din feedback!',
          happy: 'Hurra, vi er glade for, at du er tilfreds!',
          other: 'Vi er nysgerrige! Hvordan har din oplevelse været indtil videre?',
        },
      ],
    ],
  '{0, select, sad {How can we improve? Please tell us more!} neutral {Is there something we can do to take your experience to the next level? We love to hear from you!} happy {Do you have any suggestions for how we can be better? Please share!} other {Your feedback is important to us!}}':
    [
      [
        '0',
        'select',
        {
          sad: 'Hvordan kan vi blive bedre? Del gerne din feedback!',
          neutral:
            'Er der noget, vi kan gøre for at tage din oplevelse til næste niveau? Vi sætter pris på gode tips!',
          happy: 'Har du nogle gode idéer til, hvordan vi kan blive endnu bedre? Fyr løs!',
          other: 'Din mening betyder meget for os!',
        },
      ],
    ],
  '{0}': [['0']],
  '{0} articles': [['0'], ' artikler'],
  '{0} in {1}': [['0'], ' i ', ['1']],
  '{0} is {depositFee} percent.': [['0'], ' er ', ['depositFee'], ' procent.'],
  '{0} {1}': [['0'], ' ', ['1']],
  '{0} {currency} has been transferred to your main account': [
    ['0'],
    ' ',
    ['currency'],
    ' er blevet overført til din hovedkonto',
  ],
  '{0}% expected annual yield': [['0'], '% forventet årligt afkast'],
  '{0}-value now': [['0'], '-værdi nu'],
  '{amountTickets} tickets!': [['amountTickets'], ' lod'],
  '{currency} holdings': [['currency'], ' beholdninger'],
  '{currency} is sent over <0>{0}</0>': [['currency'], ' overføres på <0>', ['0'], '</0>'],
  '{currency}-address': [['currency'], '-adresse'],
  '{currency}-value now': [['currency'], '-værdi nu'],
  '{days} days': [['days'], ' dager'],
  '{referralOwnerName} welcomes you to Firi!': [
    ['referralOwnerName'],
    ' byder dig velkommen til Firi!',
  ],
  '☑️ Choose the amount you want to stake and confirm.':
    '☑️ Vælg hvilket beløb, du ønsker at stake, og bekræft.',
  '☑️ Choose the amount you want to unstake, i.e., deactivate staking.':
    '☑️ Vælg det beløb, du ønsker at unstake, dvs. deaktivere staking på.',
  '⚙️ We give you an estimate of the processing time of the withdrawal. This is usually very quick.':
    '⚙️ Vi giver dig et estimat af behandlingstiden for udbetalingen. Det er normalt meget hurtigt.',
  '📈 Statistics shows that long term investments give better yield than buying and selling cryptocurrency based on price.':
    '📈 Statistik viser, at langsigtede investeringer giver bedre afkast end at købe og sælge kryptovaluta baseret på pris.',
  '📩 The funds you stake will be automatically transferred to your staking account.':
    '📩 Midlerne, du staker, overføres automatisk til din staking-konto.',
  '🕒 Invest a fixed amount at a fixed time with automatic deposits from your bank. This is also know as Dollar-Cost Averaging (DCA)':
    '🕒 Invester et fast beløb på et fast tidspunkt med automatiske indbetalinger fra din bank. Dette er også kendt som Dollar-Cost Averaging (DCA)',
  '🙌 Once your withdrawal is processed, you will receive it in your main account.':
    '🙌 Når din udbetaling er behandlet, vil du modtage den på din hovedkonto.',
  '🙌 You will receive the reward on your staking account every Friday going forward. You will get the first reward the week after starting.':
    '🙌 Du vil modtage belønning på din staking-konto hver fredag fremadrettet. Du modtager din første belønning ugen efter starten.',
  '🤝 We have made it simple to make long term investments in cryptocurrency.':
    '🤝 Vi har gjort det enkelt at foretage langsigtede investeringer i cryptocurrency.',
  '🧘 Less risk, less stress': '🧘 Mindre risiko, mindre stress',
};
