import * as React from 'react';
import { SVGProps } from 'react';
const SvgCheckCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4_8464)">
      <g clipPath="url(#clip1_4_8464)">
        <circle cx={12} cy={12} r={12} fill="currentColor" />
        <path
          d="M8.7314 16.6743L4.15997 12.1029L5.40569 10.9257L9.97711 15.4972L18.5943 6.85718L19.8057 8.00003L11.1543 16.6743C10.8282 16.9844 10.3927 17.1529 9.94283 17.1429C9.49383 17.148 9.06007 16.9803 8.7314 16.6743Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4_8464">
        <rect width={24} height={24} fill="white" />
      </clipPath>
      <clipPath id="clip1_4_8464">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckCircleFilled;
