import * as React from 'react';
import { SVGProps } from 'react';
const SvgSmileyNeutral = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2C10.6868 2 9.38644 2.25866 8.17318 2.7612C6.95992 3.26375 5.85753 4.00035 4.92895 4.92893C3.05358 6.8043 2.00002 9.34783 2.00002 12C2.00002 14.6522 3.05358 17.1957 4.92895 19.0711C5.85753 19.9997 6.95992 20.7362 8.17318 21.2388C9.38644 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7414 9.38642 21.2388 8.17316C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C9.87828 20 7.84345 19.1571 6.34316 17.6569C4.84287 16.1566 4.00002 14.1217 4.00002 12C4.00002 9.87827 4.84287 7.84344 6.34316 6.34315C7.84345 4.84285 9.87828 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1572 7.84344 20 9.87827 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM8.50001 11C8.10219 11 7.72066 10.842 7.43935 10.5607C7.15805 10.2794 7.00001 9.89782 7.00001 9.5C7.00001 9.10217 7.15805 8.72064 7.43935 8.43934C7.72066 8.15803 8.10219 8 8.50001 8C8.89784 8 9.27937 8.15803 9.56067 8.43934C9.84198 8.72064 10 9.10217 10 9.5C10 9.89782 9.84198 10.2794 9.56067 10.5607C9.27937 10.842 8.89784 11 8.50001 11ZM17 9.5C17 9.89782 16.842 10.2794 16.5607 10.5607C16.2794 10.842 15.8978 11 15.5 11C15.1022 11 14.7207 10.842 14.4394 10.5607C14.158 10.2794 14 9.89782 14 9.5C14 9.10217 14.158 8.72064 14.4394 8.43934C14.7207 8.15803 15.1022 8 15.5 8C15.8978 8 16.2794 8.15803 16.5607 8.43934C16.842 8.72064 17 9.10217 17 9.5ZM16 14V16H8.00001V14H16Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSmileyNeutral;
