import { gql, useMutation } from '@apollo/client';

import { TravelRuleDepositDataMutation, TravelRuleDepositDataMutationVariables } from '../gqlTypes';

const TRAVEL_RULE_DEPOSIT_DATA_MUTATION = gql`
  mutation TravelRuleDepositData($input: CryptoDepositAttachTravelRuleDataInput!) {
    cryptoDepositAttachTravelRuleData(input: $input) {
      success
      message
      failedDepositIds
    }
  }
`;

export const useTravelRuleInsertDepositData = () => {
  return useMutation<TravelRuleDepositDataMutation, TravelRuleDepositDataMutationVariables>(
    TRAVEL_RULE_DEPOSIT_DATA_MUTATION,
  );
};
