import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { UserConsent, UserConsentQuery, UserConsentQueryVariables } from '../gqlTypes';

export const USER_CONSENT_INCORRECT_TAX_CALCULATION_QUERY = gql`
  query UserConsentIncorrectTaxCalculation($consentId: UserConsent!) {
    userConsent(consentId: $consentId) {
      accepted
      createdAt
    }
  }
`;

export const useUserConsentToIncorrectTaxCalculation = (
  options?: QueryHookOptions<UserConsentQuery, UserConsentQueryVariables>,
) => {
  const { data, loading } = useQuery<UserConsentQuery, UserConsentQueryVariables>(
    USER_CONSENT_INCORRECT_TAX_CALCULATION_QUERY,
    {
      ...options,
      variables: {
        consentId: UserConsent.IncorrectTaxOnWithdrawal,
      },
    },
  );

  return {
    loading,
    notAnsweredIncorrectTaxOnWithdrawal: data?.userConsent === null,
  };
};
