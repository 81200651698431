import * as React from 'react';
import { SVGProps } from 'react';
const SvgSmileySadFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2C10.6868 2 9.38644 2.25866 8.17318 2.7612C6.95992 3.26375 5.85753 4.00035 4.92895 4.92893C3.05358 6.8043 2.00002 9.34783 2.00002 12C2.00002 14.6522 3.05358 17.1957 4.92895 19.0711C5.85753 19.9997 6.95992 20.7362 8.17318 21.2388C9.38644 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7414 9.38642 21.2388 8.17316C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM7.00001 9.5C7.00001 8.7 7.70001 8 8.50001 8C9.30001 8 10 8.7 10 9.5C10 10.3 9.30001 11 8.50001 11C7.70001 11 7.00001 10.3 7.00001 9.5ZM14.77 17.23C14.32 16.5 13.25 16 12 16C10.75 16 9.68001 16.5 9.23001 17.23L7.81001 15.81C8.71001 14.72 10.25 14 12 14C13.75 14 15.29 14.72 16.19 15.81L14.77 17.23ZM15.5 11C14.7 11 14 10.3 14 9.5C14 8.7 14.7 8 15.5 8C16.3 8 17 8.7 17 9.5C17 10.3 16.3 11 15.5 11Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSmileySadFilled;
