export { default as BankidSignDark } from './BankidSignDark';
export { default as BankidSignLight } from './BankidSignLight';
export { default as MitidConfirmDark } from './MitidConfirmDark';
export { default as MitidConfirmLight } from './MitidConfirmLight';
export { default as MitidLoginDark } from './MitidLoginDark';
export { default as MitidLoginLight } from './MitidLoginLight';
export { default as VippsLoginDark } from './VippsLoginDark';
export { default as VippsLoginLight } from './VippsLoginLight';
export { default as VippsRegisterDark } from './VippsRegisterDark';
export { default as VippsRegisterLight } from './VippsRegisterLight';
export type IllustrationName =
  | 'bankid-sign-dark'
  | 'bankid-sign-light'
  | 'mitid-confirm-dark'
  | 'mitid-confirm-light'
  | 'mitid-login-dark'
  | 'mitid-login-light'
  | 'vipps-login-dark'
  | 'vipps-login-light'
  | 'vipps-register-dark'
  | 'vipps-register-light';
import {
  BankidSignDark,
  BankidSignLight,
  MitidConfirmDark,
  MitidConfirmLight,
  MitidLoginDark,
  MitidLoginLight,
  VippsLoginDark,
  VippsLoginLight,
  VippsRegisterDark,
  VippsRegisterLight,
} from './';

export type IllustrationNames = IllustrationName;
export const illustrationsMap: Record<IllustrationNames, any> = {
  ['bankid-sign-dark']: BankidSignDark,
  ['bankid-sign-light']: BankidSignLight,
  ['mitid-confirm-dark']: MitidConfirmDark,
  ['mitid-confirm-light']: MitidConfirmLight,
  ['mitid-login-dark']: MitidLoginDark,
  ['mitid-login-light']: MitidLoginLight,
  ['vipps-login-dark']: VippsLoginDark,
  ['vipps-login-light']: VippsLoginLight,
  ['vipps-register-dark']: VippsRegisterDark,
  ['vipps-register-light']: VippsRegisterLight,
};
