import { gql, useQuery } from '@apollo/client';

import { MissingTravelRuleDepositsQuery } from '../gqlTypes';

export const MISSING_TRAVEL_RULE_DEPOSITS_QUERY = gql`
  query MissingTravelRuleDeposits {
    deposit {
      listMissingTravelRuleDeposits {
        id
        createdAt
        addressTag
        address
        cryptoAmount {
          asset
          amount
          displayName
        }
      }
    }
  }
`;

export const useMissingTravelRuleDeposits = () => {
  return useQuery<MissingTravelRuleDepositsQuery>(MISSING_TRAVEL_RULE_DEPOSITS_QUERY);
};
