import { VaspStorePayload } from '../hooks/useSendCrypto';

export enum DestinationTypeOption {
  PRIVATE_WALLET,
  VASP,
  USER_PROVIDED_NAME,
}

export type DestinationOption = VaspStorePayload & {
  type: DestinationTypeOption;
  popularDestination: boolean;
};
