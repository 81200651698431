import * as React from 'react';
import { SVGProps } from 'react';
const SvgSmileyHappyFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2C10.6868 2 9.38642 2.25866 8.17316 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34783 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17316 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17316C20.7362 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM7 9.5C7 8.7 7.7 8 8.5 8C9.3 8 10 8.7 10 9.5C10 10.3 9.3 11 8.5 11C7.7 11 7 10.3 7 9.5ZM12 17.23C10.25 17.23 8.71 16.5 7.81 15.42L9.23 14C9.68 14.72 10.75 15.23 12 15.23C13.25 15.23 14.32 14.72 14.77 14L16.19 15.42C15.29 16.5 13.75 17.23 12 17.23ZM15.5 11C14.7 11 14 10.3 14 9.5C14 8.7 14.7 8 15.5 8C16.3 8 17 8.7 17 9.5C17 10.3 16.3 11 15.5 11Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSmileyHappyFilled;
