import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { MetadataQuery } from '../gqlTypes';

export const METADATA_QUERY = gql`
  query Metadata {
    metadata {
      hasTraded
      hasDeposited
      hasTransaction
      hasWithdrawOrDeposit
      hasSavingsAccount
      hasStakingAccount
      hasSavingsAgreement
      memeConsent
      fraudAcknowledge
      readArticles
      favorites
    }
  }
`;

export const useMetadata = (options?: QueryHookOptions<MetadataQuery>) => {
  return useQuery<MetadataQuery>(METADATA_QUERY, options);
};
