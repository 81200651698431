import * as React from 'react';
import { SVGProps } from 'react';
const SvgSort = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 6.5H4V5L20 5V6.5Z" fill="#12123B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16 10.5H4V9H16V10.5Z" fill="#12123B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 14.5H4V13H12V14.5Z" fill="#12123B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8 18.5H4V17H8V18.5Z" fill="#12123B" />
  </svg>
);
export default SvgSort;
