import create from 'zustand';

import {
  CryptoCurrency,
  NotabeneBeneficiaryCryptographicalProof,
  TravelRuleBeneficiaryError,
} from '../gql/gqlTypes';

export type VaspStorePayload = {
  did: string | null;
  displayName: string;
};

type AmountType = 'crypto' | 'fiat';
/**
 * destinationTag: Only relevant if currency === XRP
 */
export interface State {
  fiatAmount: string;
  setFiatAmount: (fiatAmount: string) => void;
  cryptoAmount: string;
  setCryptoAmount: (cryptoAmount: string) => void;
  amountType: AmountType;
  setAmountType: (amountType: AmountType) => void;
  currency: CryptoCurrency;
  setCurrency: (currency: CryptoCurrency) => void;
  address: string;
  setAddress: (address: string) => void;
  destinationTag: string;
  setDestinationTag: (destinationTag: string) => void;
  showAddressScanner: boolean;
  setShowAddressScanner: (showAddressScanner: boolean) => void;
  recipientFirstName: string;
  setRecipientFirstName: (name: string) => void;
  recipientLastName: string;
  setRecipientLastName: (name: string) => void;
  onBehalfOfMyself: boolean;
  setOnBehalfOfMyself: (onBehalfOfMyself: boolean) => void;
  isLegalEntity: boolean;
  setIsLegalEntity: (isLegalEntity: boolean) => void;

  recipientGeographicAddress: string;
  setRecipientGeographicAddress: (geographicAddress: string) => void;
  recipientCountryOfResidence: { name: string; code: string } | null;
  setRecipientCountryOfResidence: (country: { name: string; code: string } | null) => void;
  recipientNationalIdentification: string;
  setRecipientNationalIdentification: (recipientNationalIdentification: string) => void;
  ownershipProof: NotabeneBeneficiaryCryptographicalProof | null;
  setOwnershipProof: (proof: NotabeneBeneficiaryCryptographicalProof) => void;

  vasp: VaspStorePayload | null;
  setVasp: (vasp: VaspStorePayload | null) => void;

  travelRuleBeneficiaryErrors: TravelRuleBeneficiaryError[];
  setTravelRuleBeneficiaryErrors: (errors: TravelRuleBeneficiaryError[]) => void;

  reset: () => void;
}

export const initialState = {
  fiatAmount: '',
  cryptoAmount: '',
  amountType: 'crypto' as const,
  currency: CryptoCurrency.Btc,
  destinationTag: '',
  address: '',
  showAddressScanner: false,
  recipientFirstName: '',
  recipientLastName: '',
  vasp: null,
  travelRuleBeneficiaryErrors: [],
  recipientGeographicAddress: '',
  recipientCountryOfResidence: null,
  recipientNationalIdentification: '',
  onBehalfOfMyself: false,
  isLegalEntity: false,
  ownershipProof: null,
};

export const useSendCrypto = create<State>()((set) => ({
  ...initialState,
  setFiatAmount: (fiatAmount) => set({ fiatAmount }),
  setCryptoAmount: (cryptoAmount) => set({ cryptoAmount }),
  setAmountType: (amountType) => set({ amountType }),
  setCurrency: (currency) => set({ currency }),
  setDestinationTag: (destinationTag) => set({ destinationTag }),
  setAddress: (address) => set({ address }),
  setShowAddressScanner: (showAddressScanner) => set({ showAddressScanner }),
  setRecipientFirstName: (recipientFirstName) => set({ recipientFirstName }),
  setRecipientLastName: (recipientLastName) => set({ recipientLastName }),
  setVasp: (vasp) => set({ vasp }),
  setTravelRuleBeneficiaryErrors: (travelRuleBeneficiaryErrors) =>
    set({ travelRuleBeneficiaryErrors }),

  setRecipientCountryOfResidence: (item) => set({ recipientCountryOfResidence: item }),
  setRecipientGeographicAddress: (recipientGeographicAddress) =>
    set({ recipientGeographicAddress }),

  setRecipientNationalIdentification: (recipientNationalIdentification) =>
    set({ recipientNationalIdentification }),
  setOnBehalfOfMyself: (onBehalfOfMyself) => set({ onBehalfOfMyself }),
  setIsLegalEntity: (isLegalEntity) => set({ isLegalEntity }),
  setOwnershipProof: (ownershipProof) => set({ ownershipProof }),
  reset: () => set({ ...initialState }),
}));
