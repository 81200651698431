import { BanksIconName } from '@firi/icons/web';

export enum Banks {
  'DNB' = 'DNB',
  'Nordea' = 'Nordea',
  'Sbanken' = 'Sbanken',
  'Danske bank' = 'Danske bank',
  'SpareBank 1' = 'SpareBank 1',
  'SpareBank 1 - SR Banken' = 'SpareBank 1 - SR Banken',
  'SpareBank 1 - Nord-Norge' = 'SpareBank 1 - Nord-Norge',
  'Handelsbanken' = 'Handelsbanken',
  'Santander' = 'Santander',
}

export const bankIconMapper: Record<BanksIconName, Banks> = {
  dnb: Banks.DNB,
  nordea: Banks.Nordea,
  'nordea-dark': Banks.Nordea,
  sbanken: Banks.Sbanken,
  'sbanken-dark': Banks.Sbanken,
  danskebank: Banks['Danske bank'],
  sparebank1: Banks['SpareBank 1'],
  sparebank1dark: Banks['SpareBank 1'],
  sparebank1srbanken: Banks['SpareBank 1 - SR Banken'],
  'sparebank1srbanken-dark': Banks['SpareBank 1 - SR Banken'],
  sparebank1nordnorge: Banks['SpareBank 1 - Nord-Norge'],
  'sparebank1nordnorge-dark': Banks['SpareBank 1 - Nord-Norge'],
  handelsbanken: Banks.Handelsbanken,
  santander: Banks.Santander,
};

export const AVAILABLE_BANKS = Object.values(Banks);
