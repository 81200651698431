import * as React from 'react';
import { SVGProps } from 'react';
const SvgSmileySad = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20 12C20 9.87827 19.1572 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87828 4 7.84345 4.84285 6.34316 6.34315C4.84287 7.84344 4.00002 9.87827 4.00002 12C4.00002 14.1217 4.84287 16.1566 6.34316 17.6569C7.84345 19.1571 9.87828 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1572 16.1566 20 14.1217 20 12ZM22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C10.6868 22 9.38644 21.7413 8.17318 21.2388C6.95992 20.7362 5.85753 19.9997 4.92895 19.0711C3.05358 17.1957 2.00002 14.6522 2.00002 12C2.00002 9.34783 3.05358 6.8043 4.92895 4.92893C6.80431 3.05357 9.34785 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17316C21.7414 9.38642 22 10.6868 22 12ZM15.5 8C16.3 8 17 8.7 17 9.5C17 10.3 16.3 11 15.5 11C14.7 11 14 10.3 14 9.5C14 8.7 14.7 8 15.5 8ZM10 9.5C10 10.3 9.30001 11 8.50001 11C7.70001 11 7.00001 10.3 7.00001 9.5C7.00001 8.7 7.70001 8 8.50001 8C9.30001 8 10 8.7 10 9.5ZM12 14C13.75 14 15.29 14.72 16.19 15.81L14.77 17.23C14.32 16.5 13.25 16 12 16C10.75 16 9.68001 16.5 9.23001 17.23L7.81001 15.81C8.71001 14.72 10.25 14 12 14Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSmileySad;
